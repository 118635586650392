import axios from "axios"

import { apiUrl } from '@/config'
import * as types from '../mutation_types'
import RconServer from '@/models/RconServer'

const state = {
  servers: [],
  error: null
}

const getters = {
  servers: (state) => state.servers,
  error: (state) => state.error
}

const actions = {
  async sendCommand({ commit }, payload) {
    const { serverId, command } = payload
    commit(types.ADD_RCON_LINE, { serverId, type: 'command', commands: [command] })
    try {
      const { data } = await axios.get(
        apiUrl(`rcon/send/${serverId}?command=${command}`)
      )
      commit(types.ADD_RCON_LINE, {
        serverId,
        type: 'response',
        commands: JSON.parse(data.data.response).filter(l => l.length > 0)
      })
      commit(types.UNSET_ERROR)
    } catch (e) {
      commit(types.SET_ERROR, e)
    }
  },

  async getServersInfo({ commit }) {
    try {
      const { data } = await axios.get(
        apiUrl(`rcon/servers`)
      )
      commit(types.SET_SERVERS, data.data.servers.map(s => RconServer.create(s)))
      commit(types.UNSET_ERROR)
    } catch (e) {
      commit(types.SET_ERROR, e)
    }
  },

  async launchServer({ commit }, serverId) {
    try {
      await axios.get(
        apiUrl(`rcon/servers/${serverId}/launch`)
      )
      commit(types.UNSET_ERROR)
    } catch (e) {
      commit(types.SET_ERROR, e)
    }
  },

  statusUpdate({ commit }, data) {
    commit(types.UPDATE_SERVERS, data.servers.map(s => JSON.parse(s)))
  },

  consoleUpdate({ commit }, data) {
    const { serverId, lines } = data
    if (serverId === undefined) return

    if (lines.length) {
      commit(types.UPDATE_LOGS, data)
    } else {
      commit(types.CLEAR_LOGS, serverId)
    }
  }
 }

const mutations = {
  [types.ADD_RCON_LINE](state, payload) {
    const { serverId, type, commands } = payload
    if (serverId === undefined || type === undefined || commands === undefined) { console.log('Invalid payload', payload); return }
    const server = state.servers.find(s => s.id == serverId)
    if (!server) { console.log('Server not found', state.servers, payload); return }
    server.addRconLines(type, commands)
  },

  [types.UPDATE_LOGS](state, payload) {
    const { serverId, lines } = payload
    const server = state.servers.find(s => s.id == serverId)
    if (!server) { console.log('Server not found', state.servers, payload); return }
    server.addConsoleLines(lines)
  },

  [types.CLEAR_LOGS](state, serverId) {
    const server = state.servers.find(s => s.id == serverId)
    if (!server) { console.log('Server not found', state.servers, serverId); return }
    server.clearConsoleLines()
  },

  [types.SET_SERVERS](state, servers) {
    state.servers = servers
  },

  [types.UPDATE_SERVERS](state, data) {
    data.map(s => {
      const server = state.servers.find(server => server.id === s.id)
      if (server) {
        server.isOnline = s.status
      }
    })
  },

  [types.SET_ERROR](state, error) {
    state.error = error
  },

  [types.UNSET_ERROR](state) {
    state.error = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}