// import About from "@/pages/About"
import CurrentSeason from "@/pages/CurrentSeason"
import History from "@/pages/History"
import Login from "@/pages/Login"
import Main from "@/pages/Main"
import Games from "@/pages/Games"
import Map from "@/pages/Map"
import Modpack from "@/pages/Modpack"
import NotFound from "@/pages/NotFound"
import Profile from "@/pages/Profile"
import Players from "@/pages/Players"
import Rcon from "@/pages/Rcon"
import RegistrationForm from "@/pages/RegistrationForm"
import Rules from "@/pages/Rules"
import Stats from "@/pages/Stats"
import Subscription from "@/pages/Subscription"
import ErrorComp from "@/pages/Error"
import Merch from "@/pages/Merch2022"
import Media from "@/pages/Media"

export default [
  {
    path: '/',
    name: 'main',
    component: Main,
    meta: {
      title: 'Твій приватний Minecraft сервер',
      breadcrumb: {
        title: 'Головна'
      },
      metaTags: [
        {name: 'description', content: 'OMHMS home page'},
        {name: 'og:description', content: 'OMHMS home page'}
      ]
    }
  },
  {
    path: '/games',
    name: 'games',
    component: Games,
    meta: {
      title: 'Міні-ігри',
      breadcrumb: {
        title: 'Головна'
      },
      metaTags: [
        {name: 'description', content: 'OMHMS mini-games'},
        {name: 'og:description', content: 'OMHMS mini-games'}
      ]
    }
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   component: About,
  //   meta: {
  //     title: 'Про сервер',
  //     breadcrumb: {
  //       title: 'Про сервер',
  //       parent: 'main'
  //     },
  //     metaTags: [
  //       {name: 'description', content: 'OMHMS about page'},
  //       {name: 'og:description', content: 'OMHMS about page'}
  //     ]
  //   }
  // },
  {
    path: '/history',
    name: 'history',
    component: History,
    meta: {
      title: 'Історія',
      breadcrumb: {
        title: 'Історія',
        parent: 'main'
      },
      metaTags: [
        {name: 'description', content: 'OMHMS history page'},
        {name: 'og:description', content: 'OMHMS history page'}
      ]
    }
  },
  {
    path: '/players',
    name: 'players',
    component: Players,
    meta: {
      middleware: "auth",
      title: 'Гравці',
      breadcrumb: {
        title: 'Гравці',
        parent: 'main'
      },
      metaTags: [
        {name: 'description', content: 'Список гравців проекту'},
        {name: 'og:description', content: 'Список гравців проекту'}
      ]
    }
  },
  {
    path: '/rules',
    name: 'rules',
    component: Rules,
    meta: {
      title: 'Правила',
      breadcrumb: {
        title: 'Правила',
        parent: 'main'
      },
      metaTags: [
        {name: 'description', content: 'OMHMS rules page'},
        {name: 'og:description', content: 'OMHMS rules page'}
      ]
    }
  },
  {
    path: '/rcon',
    name: 'rcon',
    component: Rcon,
    meta: {
      title: 'rcon',
      middleware: "auth",
      metaTags: [
        {name: 'description', content: 'OMHMS rcon page'},
        {name: 'og:description', content: 'OMHMS rcon page'}
      ]
    }
  },
  {
    path: '/season',
    name: 'season',
    component: CurrentSeason,
    meta: {
      title: 'Поточний сезон',
      breadcrumb: {
        title: 'Поточний сезон',
        parent: 'main'
      },
      metaTags: [
        {name: 'description', content: 'OMHMS current season page'},
        {name: 'og:description', content: 'OMHMS current season page'}
      ]
    }
  },
  {
    path: '/media',
    name: 'Media',
    component: Media,
    meta: {
      title: 'OMHMS Медіа кіт',
      breadcrumb: {
        title: 'Медіа кіт',
        parent: 'main'
      },
      metaTags: [
        {name: 'description', content: 'OMHMS Media kit'},
        {name: 'og:description', content: 'OMHMS Media kit'}
      ]
    }
  },
  {
    path: '/merch',
    name: 'Merch',
    component: Merch,
    meta: {
      title: 'Сувенірна продукція',
      breadcrumb: {
        title: 'Сувенірна продукція',
        parent: 'season'
      },
      metaTags: [
        {name: 'description', content: 'OMHMS merch'},
        {name: 'og:description', content: 'OMHMS merch'}
      ]
    }
  },
  {
    path: '/modpack',
    name: 'Modpack',
    component: Modpack,
    meta: {
      title: 'Модпак від одмєна',
      breadcrumb: {
        title: 'Модпак від одмєна',
        parent: 'main'
      },
      metaTags: [
        {name: 'description', content: 'Модпак від одмєна'},
        {name: 'og:description', content: 'Модпак від одмєна'}
      ]
    }
  },
  {
    path: '/subscription',
    name: 'subscription',
    component: Subscription,
    meta: {
      title: 'Магазин',
      breadcrumb: {
        title: 'Магазин',
        parent: 'main'
      }
    }
  },
  {
    path: '/map/:type?',
    name: 'map',
    component: Map,
    meta: {
      title: 'Мапа',
      middleware: "auth",
      metaTags: [
        {name: 'description', content: 'OMHMS map page'},
        {name: 'og:description', content: 'OMHMS map page'}
      ]
    }
  },
  {
    path: '/stats',
    name: 'stats',
    component: Stats,
    meta: {
      title: 'Статистика',
      middleware: "auth",
      metaTags: [
        {name: 'description', content: 'OMHMS stats page'},
        {name: 'og:description', content: 'OMHMS stats page'}
      ]
    }
  },
  {
    path: '/profile/:username?',
    name: 'profile',
    component: Profile,
    meta: {
      title: 'Профіль',
      canLogout: true,
      middleware: "auth",
      metaTags: [
        {name: 'description', content: 'OMHMS profile page'},
        {name: 'og:description', content: 'OMHMS profile page'}
      ]
    }
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      title: 'Логін',
      metaTags: [
        {name: 'description', content: 'OMHMS login page'},
        {name: 'og:description', content: 'OMHMS login page'}
      ]
    }
  },
  {
    path: "/registration/:username?",
    name: "registration",
    component: RegistrationForm,
    meta: {
      title: 'Анкетування',
      canLogout: true,
      middleware: "auth",
      metaTags: [
        {name: 'description', content: 'OMHMS registration page'},
        {name: 'og:description', content: 'OMHMS registration page'}
      ]
    }
  },
  {
    path: "/error",
    name: "error",
    component: ErrorComp,
    meta: {
      title: 'Помилка',
      metaTags: [
        {name: 'description', content: 'OMHMS error page'},
        {name: 'og:description', content: 'OMHMS error page'}
      ]
    }
  },
  {
    path: '/:catchAll(.*)',
    name: 'notFound',
    component: NotFound,
    meta: {
      title: '404',
      metaTags: [
        {name: 'description', content: 'OMHMS not found page'},
        {name: 'og:description', content: 'OMHMS not found page'}
      ]
    }
  },
  {
     path: '/mymaptest',
     beforeEnter() {location.href = 'http://localhost:20000'}
  }
  ]