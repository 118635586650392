export const LOGOUT = "LOGOUT"
export const SAVE_TOKEN = "SAVE_TOKEN"
export const SET_ERROR = "SET_ERROR"
export const SET_LOGIN_ERROR = "SET_LOGIN_ERROR"
export const UNSET_ERROR = "UNSET_ERROR"
export const SET_STATUS = "SET_STATUS"
export const SET_USER = "SET_USER"

export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS"

export const SET_FORM = "SET_FORM"

export const SET_PROFILE = "SET_PROFILE"

export const UPDATE_LOGS = "UPDATE_LOGS"
export const CLEAR_LOGS = "CLEAR_LOGS"
export const SET_LOGS = "SET_LOGS"

export const ADD_RCON_LINE = "ADD_RCON_LINE"
export const SET_SERVERS = "SET_SERVERS"
export const UPDATE_SERVERS = "UPDATE_SERVERS"

export const SET_PAYMENTS = "SET_PAYMENTS"
export const DELETE_PAYMENT = "DELETE_PAYMENT"

export const SET_PLAYERS = "SET_PLAYERS"
