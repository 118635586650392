import { createApp } from 'vue'
import App from './App'
import BootstrapVue3 from 'bootstrap-vue-3'
import { createMetaManager, plugin as metaPlugin } from 'vue-meta'


import 'bootstrap/dist/css/bootstrap.min.css'
import wait from "@meforma/vue-wait-for";
import can from "@/properties/can"

import router from "@/router/index"
import store from "@/store/index"

import '@/plugins'

import "@/assets/scss/app.scss"

import VueMobileDetection from 'vue-mobile-detection'

const app = createApp(App)
	.use(router)
	.use(store)
	.use(wait)
	.use(BootstrapVue3)
	.use(VueMobileDetection)
	.use(createMetaManager())
	.use(metaPlugin)

app.config.devtools = true
app.config.globalProperties.$can = can

app.mount('#app')

