import store from "../store/index"

export default (to, from, next) => {
  if (!store.getters["auth/check"]) {
      // If not authenticated, add a path where to redirect after login.
    next({ name: "login", query: { redirect: to.path } })
    return
  }
  next()
}
