<template>
  <div class="calendar omhms-content-block" :class="[{'calendar_active' : active}]">
    <div class="calendar__months">
      <PaymentCalendarYear
        v-for="year in calendarYears"
        :key="year"
        :year="year"
        :active="active"
        :payments="payments"
        :selected-month-index="selectedMonthIndex[year]"
        @onMonthClick="onMonthClick"
      />
    </div>

    <template v-if="showLegend">
      <div v-if="active" class="calendar__links">
        <!-- <span class="">Виписка</span> -->
      </div>

      <div v-else class="calendar__links">
        <!-- <p class="calendar__links__title omhms-text omhms-text-caption"> Сплатити проходку, або просто задонатити:</p> -->
        <div class="calendar__links__links">
          <a class="omhms-text-link" href="https://omhms.com/subscription#subscriptions" target="_blank">
            <!-- Сплатити проходку -->
            <div class="custom-button"> <div class="button button_small"> Сплатити перепустку </div></div>
            <!-- <CustomButton isClear isSmall value="Сплатити проходку" @clicked="$router.push('profile')"/> -->
          </a>
          <a class="omhms-text-link" href="https://send.monobank.ua/jar/6YV9pwnrTN" target="_blank">
            <!-- Сплатити проходку -->
            <div class="custom-button"> <div class="button button_clear button_small"> Просто задонатити </div></div>
            <!-- <CustomButton isClear isSmall value="Сплатити проходку" @clicked="$router.push('profile')"/> -->
          </a>
        </div>
        <a v-if="$can('admin')" class="omhms-text-link" data-bs-toggle="modal" data-bs-target="#profileEditModal"><CustomButton isSmall value="Редагувати" /></a>
      </div>

      <div class="calendar__legend row">
        <div class="calendar__legend_block col-12">
          <span class="omhms-text legendText">Позначки:</span>
        </div>
        <div class="calendar__legend_block col-12">
          <div class="monthBlock empty"></div> <span class="omhms-text omhms-text-caption legendText">Оплат не було, перепустки немає</span>
        </div>
        <div class="calendar__legend_block col-12">
          <div class="monthBlock paid"></div> <span class="omhms-text omhms-text-caption legendText">Оплата є, перепустка є</span>
        </div>
        <div class="calendar__legend_block col-12">
          <div class="monthBlock paid zsu"></div> <span class="omhms-text omhms-text-caption legendText">Оплата є, перепустка є, ще і на ЗСУ задонатив! Ай молодець</span>
        </div>
        <div class="calendar__legend_block col-12">
          <div class="monthBlock notRequired"></div> <span class="omhms-text omhms-text-caption legendText">Перепустка є, оплата не потрібна</span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import PaymentCalendarYear from "@/components/paymentCalendar/PaymentCalendarYear"
import CustomButton from "@/components/CustomButton.vue"


export default {
  name: "PaymentCalendar",
  props: {
    active: {
      type: Boolean,
      default: false
    },
    years: {
      type: Array,
      required: true
    },
    payments: {
      type: Array,
      required: true
    },
    showLegend: {
      type: Boolean,
      default: true
    }
  },
  components: {
    PaymentCalendarYear,
    CustomButton
  },
  data() {
    return {
      selectedMonthIndex: {},
      calendarYears: [],
    }
  },
  created() {
    this.calendarYears = [...this.years]

    this.years.forEach(year => {
      this.selectedMonthIndex[year] = null
    })
  },
  methods: {
    onMonthClick(payload) {
      if (this.active) {
        Object.keys(this.selectedMonthIndex).forEach(year => {
          this.selectedMonthIndex[year] = (year == payload.year) ? payload.month : null
        })
        this.$emit('select', payload)
      }
    }
  }
}

</script>