<template>
  <div class="payment-preview">
    <PaymentPreviewEmpty
      v-if="showEmptyPaymentPreview"
      :date="date"
      @addPayment="toggleEditMode"
      @statusUpdate="onStatusUpdate"
    />
    <PaymentPreviewPaid
      v-else
      :selectedPayment="computedPayment"
      :active="editMode"
      @addPayment="toggleEditMode"
      @updatePayment="toggleEditMode"
      @savePayment="onSavePayment"
      @deletePayment="onDeletePayment"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { defineAsyncComponent } from "vue";

const PaymentPreviewEmpty = defineAsyncComponent(() =>
  import("@/components/paymentPreview/PaymentPreviewEmpty")
);

const PaymentPreviewPaid = defineAsyncComponent(() =>
  import("@/components/paymentPreview/PaymentPreviewPaid")
);

import Payment from "@/models/Payment"
import { formatDate } from "@/helpers/index"

export default {
  name: "PaymentPreview",
  components: {
    PaymentPreviewEmpty,
    PaymentPreviewPaid
  },
  props: {
    date: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      editMode: false
    }
  },
  computed: {
    ...mapGetters({
      paymentByDate: "payment/paymentByDate",
      profile: "profile/profile",
      currentUser: "auth/user"
    }),

    profileHasPerson() {
      return this.profile && this.profile.person
    },

    computedPayment() {
      return this.selectedPayment ? this.selectedPayment : this.createNewPayment('PAID')
    },

    showEmptyPaymentPreview() {
      if (this.editMode) { return false }
      return !this.selectedPayment || !this.selectedPayment.isPaid
    },

    selectedPayment() {
      return this.paymentByDate(this.date)
    },
  },
  watch: {
    date() {
      this.editMode = false
    }
  },
  methods: {
    ...mapActions({
      createPayment: "payment/create",
      updatePayment: "payment/update",
      deletePayment: "payment/delete",
      getPayments: "payment/get"
    }),

    toggleEditMode() {
      this.editMode = true
    },

    createNewPayment(status) {
      let paidDate = new Date()
      paidDate.setFullYear(this.date.year, this.date.month)

      return Payment.create({
        date: this.formatDate(new Date()),
        paidPeriod: this.formatDate(paidDate),
        status: status,
        personId: this.profileHasPerson?.id ?? "",
        creatorPersonId: this.currentUser?.personId ?? ""
      })
    },

    async onSavePayment(payment) {
      payment.status = (payment.amount === 0) ? "NONEED" : "PAID"
      await this.actionOnPayment({
        paymentWaitTitle: payment.id ? "update" : "create",
        paymentAction: payment.id ? "updatePayment" : "createPayment",
        payment
      })

      this.editMode = false
    },

    async onStatusUpdate(payload) {
      const { selectedIndex, status } = payload

      let payment, paymentAction, paymentWaitTitle

      if (this.selectedPayment) {
        payment = Payment.create(this.selectedPayment)
        payment.status = status

        if (selectedIndex === 0) {
          paymentAction = 'deletePayment'
          paymentWaitTitle = 'delete'
        } else {
          paymentAction = 'updatePayment'
          paymentWaitTitle = 'update'
        }
      } else {
        if (selectedIndex === 0) { return }

        payment = this.createNewPayment(status)

        paymentAction = 'createPayment'
        paymentWaitTitle = 'create'
      }

      if (paymentWaitTitle) {
        this.actionOnPayment({
          paymentWaitTitle,
          paymentAction,
          payment
        })
      }
    },

    async actionOnPayment(payload) {
      const { paymentWaitTitle, paymentAction, payment } = payload

      this.$wait.start(`payment.${paymentWaitTitle}`)
      await this[paymentAction](payment)
      if (this.profileHasPerson?.id) {
        this.getPayments(this.profileHasPerson.id)
      }
      this.$wait.end(`payment.${paymentWaitTitle}`)
    },

    async onDeletePayment() {
      await this.deletePayment(this.selectedPayment)
      this.getPayments(this.profileHasPerson.id)
    },

    formatDate
  }
}
</script>