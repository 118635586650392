<template>
  <div class="main-layout">
    <PageHeader :title="title"/>
    <!-- <div class="season-image"></div> -->
  </div>

  <div class="bg-end-noise">
    <div class="container omhms-content-container" id="first_anchor">
      <div class="row omhms-content-block ">
        <div class="col-lg-4 col-sm-12 offset-md-1">
          <div class="row">
            <div class="col-12">
              <div class="col-2 omhms-content-block-decorline"></div>
              <span class="omhms-text omhms-text-h2-bold">Перепустка</span>
              <div class="spacer-16"></div>
              <p class="omhms-text omhms-text-regular">Щоб потрапити на Виживання – необхідна перепустка. Нище можна переглянути рівні перепусток що ми маємо!</p>
              <a class="omhms-text-link" href="#subscriptions">
                <div class="custom-button"> <div class="button button_clear button_small"> До перепусток </div></div>
              </a>
              <div class="spacer-32"></div>
            </div>
            <div class="row">
              <div class="col-10 col-md-12 offset-md-1 offset-0">
                <div class="spacer-32"></div>
                <span class="omhms-text omhms-text-h2-bold">Донат</span>
                <div class="spacer-16"></div>
                <p class="omhms-text omhms-text-regular">На сервері міні-ігр (та і на основі теж) присутня косметика яку можна придбати за внутрішньоігрові Карбованці.</p>
                <a class="omhms-text-link" href="#donat">
                  <div class="custom-button"> <div class="button button_clear button_small"> Переглянути донат </div></div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 offset-lg-1 offset-md-0 col-md-10 col-sm-12 margin-top">
          <p class="omhms-text omhms-text-caption">На час війни із <sub>р</sub>осією частина кожної перепустки буде йти на донати у різні фонди, чи волонтерам які потребують фінансової допомоги.</p>
          <br>
          <div class="omhms-image" :data-pm='1.7'>
            <img
              id="img1"
              class="omhms-image-stroke"
              alt="Hello, there!"
              :src="require(`@/assets/images/subscription/ua-min.jpg`)"
            />
          </div>
        </div>
      </div>




    <div class="container omhms-content-container" id="donat">
      <div class="row omhms-content-block ">
        <div class="col-12">
          <div class="col-1 omhms-content-block-decorline"></div>
          <p class="omhms-text omhms-text-topcap">Ігрова валюта OMHMS</p>
          <span class="omhms-text omhms-text-h2-regular">Карбованці</span>
          <div class="spacer-24"></div>
          <p class="omhms-text omhms-text-caption">На OMHMS Міні-іграх можна заробити Карбованці просто граючи в міні ігри та весело проводячи час. Кожна міні-гра має свої бонуси. Але для тих, хто хоче підтримати сервер не тільки граючи, а ще й реальною монетою ми пропонуємо певні ігрові бонуси і набори:</p>
        </div>
        <div class="row omhms-content-block subscriptionCardsRow">
          
          <!-- START OF CARD -->
          <div class="col-xl-3 col-lg-4 col-md-6 col-10">
            <div class="subscriptionCardHolder"> 
              <div class="donationCard">
                <div class="donationCard-iconBox">
                  <div class="donationCard-icon">
                    <img src="../assets/images/donationIcons/coin.png"/>
                    <p class="donationCard__icon-count">100</p>
                  </div>
                </div>

                <div class="donationCard-title">Карбованці</div>
                <div class="donationCard-description">
                  <img
                    class="omhms-image-coin"
                    :src="require(`@/assets/images/donationIcons/coin.png`)"
                  />
                  100 карбованців
                </div>
                <div class="subscriptionCard-lineSeparator"></div>
                <p class="subscriptionCard-price">10<sup>.00</sup><span class="subscriptionCard-priceCaption">грн</span></p>
                <div class="donationCard-button">
                  <a class="omhms-text-link" :href="donationLink(10, 'Донат набір: 100 карбованців')" target="_blank">
                    <div class="custom-button"> <div class="button button_clear"> Задонатити! </div></div>
                  </a>
                </div>
              </div>
            </div>
          </div> 
          <!-- END OF CARD -->
          
          <!-- START OF CARD -->
          <div class="col-xl-3 col-lg-4 col-md-6 col-10">
            <div class="subscriptionCardHolder"> 
              <div class="donationCard">
                <div class="donationCard-iconBox">
                  <div class="donationCard-icon">
                    <img src="../assets/images/donationIcons/coin.png"/>
                    <p class="donationCard__icon-count">250</p>
                  </div>
                </div>

                <div class="donationCard-title">Карбованці</div>
                <div class="donationCard-description">
                  <img
                    class="omhms-image-coin"
                    :src="require(`@/assets/images/donationIcons/coin.png`)"
                  />
                  250 карбованців
                </div>
                <div class="subscriptionCard-lineSeparator"></div>
                <p class="subscriptionCard-price">20<sup>.00</sup><span class="subscriptionCard-priceCaption">грн</span></p>
                <div class="donationCard-button">
                  <a class="omhms-text-link" :href="donationLink(20, 'Донат набір: 250 карбованців')" target="_blank">
                    <div class="custom-button"> <div class="button button_clear"> Задонатити! </div></div>
                  </a>
                </div>
              </div>
            </div>
          </div> 
          <!-- END OF CARD -->
          
          <!-- START OF CARD -->
          <div class="col-xl-3 col-lg-4 col-md-6 col-10">
            <div class="subscriptionCardHolder"> 
              <div class="donationCard">
                <div class="donationCard-iconBox">
                  <div class="donationCard-icon">
                    <img src="../assets/images/donationIcons/coin.png"/>
                    <p class="donationCard__icon-count">666</p>
                  </div>
                </div>

                <div class="donationCard-title">Карбованці</div>
                <div class="donationCard-description">
                  <img
                    class="omhms-image-coin"
                    :src="require(`@/assets/images/donationIcons/coin.png`)"
                  />
                  666 карбованців
                </div>
                <div class="subscriptionCard-lineSeparator"></div>
                <p class="subscriptionCard-price">50<sup>.00</sup><span class="subscriptionCard-priceCaption">грн</span></p>
                <div class="donationCard-button">
                  <a class="omhms-text-link" :href="donationLink(50, 'Донат набір: 666 карбованців')" target="_blank">
                    <div class="custom-button"> <div class="button button_clear"> Задонатити! </div></div>
                  </a>
                </div>
              </div>
            </div>
          </div> 
          <!-- END OF CARD -->
          
          <!-- START OF CARD -->
          <div class="col-xl-3 col-lg-4 col-md-6 col-10">
            <div class="subscriptionCardHolder"> 
              <div class="donationCard">
                <div class="donationCard-iconBox">
                  <div class="donationCard-icon">
                    <img src="../assets/images/donationIcons/coin.png"/>
                    <p class="donationCard__icon-count">1488</p>
                  </div>
                </div>

                <div class="donationCard-title">Карбованці</div>
                <div class="donationCard-description">
                  <img
                    class="omhms-image-coin"
                    :src="require(`@/assets/images/donationIcons/coin.png`)"
                  />
                  1488 карбованців
                </div>
                <div class="subscriptionCard-lineSeparator"></div>
                <p class="subscriptionCard-price">100<sup>.00</sup><span class="subscriptionCard-priceCaption">грн</span></p>
                <div class="donationCard-button">
                  <a class="omhms-text-link" :href="donationLink(100, 'Донат набір: 1488 карбованців')" target="_blank">
                    <div class="custom-button"> <div class="button button_clear"> Задонатити! </div></div>
                  </a>
                </div>
              </div>
            </div>
          </div> 
          <!-- END OF CARD -->
          
        </div>
      </div>
    </div>

    <div class="container omhms-content-container" id="subscriptions">
      <div class="row">
        <div class="col-12">
          <div class="col-1 omhms-content-block-decorline"></div>
          <p class="omhms-text omhms-text-topcap">На виживання</p>
          <span class="omhms-text omhms-text-h2-regular">Перепустка OMHMS</span>
          <div class="spacer-24"></div>
          <p class="omhms-text omhms-text-caption">На нашому приватному сервері запроваджена система різнорівневих підписок, обирай ту, яка тобі довподоби!</p>
        </div>
      </div>
      <div class="row omhms-content-block subscriptionCardsRow">
        <div class="col-lg-4 col-sm-10 col-12">
          <div class="subscriptionCardHolder__first"> 
            <div class="subscriptionCard">


              <div class="subscriptionCard__titlepart">
                <div class="subscriptionCard-icon">
                  <img src="../assets/images/subscription/tier1.png"/>
                </div>
                <div>
                  <div class="subscriptionCard-title">Звичайна</div>
                  <div class="subscriptionCard-description">Звичайнісінька перепустка, якої цілком достатньо для гри.</div>
                </div>  
              </div>
              <div class="subscriptionCard-lineSeparator"></div>
              <div>
                <p class="subscriptionCard-price">{{ subscription("1", false) }}<sup>.00</sup><span class="subscriptionCard-priceCaption">грн/місяць</span></p>
                <p class="subscriptionCard-priceZSU">Або <span class="subscriptionCard-priceZSU__bold">{{ subscription("1", true) }} грн</span>, з яких <span class="subscriptionCard-priceZSU__bold">{{ subscription("1", true) - subscription("1", false) }} грн </span><br>піде на користь ЗСУ<span class="omhms-text-caption">, і {{ subscription("1", false) }} на сервер!</span></p>
              </div>
              <div class="subscriptionCard-lineSeparator"></div>
              <div class="subscriptionCard-list">
                <ul>
                  <li><p class="__paragraph">Проходка в проект.</p><p class="__caption">Ігрова роль <span class="omhms-text omhms-text-bold omhms-text-color-server_player omhms-text__player-player">Гравця</span></p></li>
                  <li><p class="__paragraph">Звичайна роль в Discord</p></li>
                  <li><p class="__paragraph">Ліміт "Новачків" <span class="__limit __colored">1/1</span></p><p class="__caption">Деталі про <a target="_blank" href="/season#feature-slaves">Новачків</a>.</p></li>
                  <li><p class="__paragraph">Базовий ліміт plot'ів: <span class="__limit __colored">4</span></p><p class="__caption">Деталі про <a target="_blank" href="/season#feature-plots">plot'и</a>.</p></li>
                  <li><p class="__paragraph">Стяги на веб-мапі: <span class="__limit __colored">4</span></p><p class="__caption">Деталі про <a target="_blank" href="/season#feature-flags">стяги</a>.</p></li>
<!--                   <li><p class="__paragraph">Щотижневі виплати від Мерії:
                    <span class="__limit __colored">
                      <img
                        class="omhms-image-coin __coins-img"
                        :src="require(`@/assets/images/donationIcons/coin.png`)"
                      />
                      <span class="__coins">8</span> 
                    </span>
                  </p></li> -->
                </ul>
              </div>
            </div>
            <div class="subscriptionCard-button">
              <template v-if="check"> 
                <a class="subscriptionCard-button__big omhms-text-link" :href="donationLink(this.tier1, 'Звичайна перепустка без доната на ЗСУ')" target="_blank">
                  <div class="custom-button"> <div class="button"> Звичайна за {{ subscription("1", false) }} </div></div>
                </a>
                <a class="subscriptionCard-button__big subscriptionCard-button__zsu__tier1 omhms-text-link" :href="donationLink(this.tier1zsu, 'Звичайна перепустка і донат на ЗСУ')" target="_blank">
                  <div class="custom-button"> <div class="button button_clear button_small"> Звичайна + на ЗСУ <strong>за {{ subscription("1", true) }}</strong> </div></div>
                </a>
              </template>

              <template v-if="!check"> 
                Спочатку анкета, потім перепустка :)
                <div class="subscriptionCard-ifnotlogined">
                  <CustomButton value="Заповнити анкету" isSmall isClear @clicked="$router.push('profile')"/>
                  <CustomButton value="Залогінитись" isSmall isClear @clicked="$router.push('profile')"/>
                </div>  
              </template>


            </div>
          </div>

          <!-- START OF CARD -->
          <div class="subscriptionCardHolder__first"> 
            <div class="subscriptionCard">


              <div class="subscriptionCard__titlepart">
                <div>
                  <!-- <div class="subscriptionCard-title">Звичайна</div> -->
                  <p class="omhms-text-bold">Перепустка "Новачка"</p>
                  <p class="omhms-text-caption">Деталі про <a target="_blank" class="omhms-text-link" href="/season#feature-slaves">Новачків</a>.</p>
                </div>  
              </div>
              <div class="subscriptionCard-lineSeparator"></div>
              <div class="spacer-8"></div>
              <div>
                <span class="omhms-text-h4-regular omhms-text-color-95">
                  {{ Math.round(subscription("1", false) / 2) }}<sup>.00</sup><span class="subscriptionCard-priceCaption">грн/місяць</span></span><span class="subscriptionCard-priceCaption">або </span>
                <span class="omhms-text-h4-regular omhms-text-color-95">
                  X
                  <img
                    class="omhms-image-coin"
                    :src="require(`@/assets/images/donationIcons/coin.png`)"
                    style="margin-top: -2px; width: 17px;"
                  />
                  <!-- <span class="subscriptionCard-priceCaption">
                    карбованців
                  </span> -->
                </span>

                <p class="omhms-text-caption omhms-text-color-45">* Ціна в карбованцях ще не визначена.</p>
              </div>
              <div class="spacer-4"></div>
              <div class="subscriptionCard-lineSeparator"></div>
              <div class="subscriptionCard-list">
                <ul>
                  <li><p class="__paragraph">Проходка в проект.</p><p class="__caption">Ігрова роль <span class="omhms-text omhms-text-bold omhms-text-color-server_player omhms-text__player-newbie">Новачка</span></p></li>
                  <li><p class="__paragraph">Базовий ліміт plot'ів: <span class="__limit __colored">4</span></p><p class="__caption">Деталі про <a target="_blank" href="/season#feature-plots">plot'и</a>.</p></li>
                </ul>
              </div>
            </div>
            <div class="subscriptionCard-button">
              <template v-if="check"> 
                <a class="subscriptionCard-button__big omhms-text-link" :href="donationLink(Math.round(this.tier1 / 2), 'Звичайна перепустка для новачка')" target="_blank">
                  <div class="custom-button"> <div class="button button_clear button_small"> Придбати за {{ Math.round(subscription("1", false) / 2) }} </div></div>
                </a>
              </template>

              <template v-if="!check"> 
                Спочатку анкета, потім перепустка :)
                <div class="subscriptionCard-ifnotlogined">
                  <CustomButton value="Заповнити анкету" isSmall isClear @clicked="$router.push('profile')"/>
                  <CustomButton value="Залогінитись" isSmall isClear @clicked="$router.push('profile')"/>
                </div>  
              </template>
            </div>
          </div>
          <!-- END OF CARD -->


          <div class="row">
            <div class="col-12">
              <!-- <div class="col-1 omhms-content-block-decorline"></div> -->
              <div class="spacer-24"></div>  
              <!-- <p class="omhms-text omhms-text-topcap"></p> -->
              <span class="omhms-text omhms-text-h3-regular">Хочеш грати безкоштовно? 🤔</span>
              <div class="spacer-24"></div>
              <p class="omhms-text omhms-text-regular">Якщо ти хочеш бути частиною "лампової"" спільности OMHMS, у нас є для тебе безкоштовний сервер – <a class="omhms-text omhms-text-link" href="https://omhms.notion.site/Sandbox-75df97b917a841b59c6499cb327448cc" target="_blank">пісочниця</a>.</p>
              <p class="omhms-text omhms-text-caption">Виживання тут відрізняється мінімально, але доступ – безкоштовний. Треба лише <a href="https://omhms.notion.site/f28c1331b3904b63921bd7d05443fcfd"  class="omhms-text omhms-text-link" target="_blank">прив'язати діскорд</a> і ти готовий!</p>
            </div>
          </div>

        </div>

        <div class="col-lg-4 col-sm-10 col-12">
          <div class="subscriptionCardHolder__second"> 
            <div class="subscriptionCard">

              <div class="subscriptionCard__titlepart">
                <div class="subscriptionCard-icon">
                  <img src="../assets/images/subscription/tier2.png"/>
                </div>
                <div>
                  <div class="subscriptionCard-title">Потужна</div>
                  <div class="subscriptionCard-description">Дійсно потужна підтримка! Дякую, дуже дякую!</div>      
                </div>
              </div>  
              <div class="subscriptionCard-lineSeparator"></div>
              <div>
                <p class="subscriptionCard-price">{{ subscription("2", false) }}<sup>.00</sup><span class="subscriptionCard-priceCaption">грн/місяць</span></p>
                <p class="subscriptionCard-priceZSU">Або <span class="subscriptionCard-priceZSU__bold">{{ subscription("2", true) }} грн</span>, з яких <span class="subscriptionCard-priceZSU__bold">{{ subscription("2", true) - subscription("2", false) }} грн </span><br>піде на користь ЗСУ<span class="omhms-text-caption">, і {{ subscription("2", false) }} на сервер.</span></p>
              </div>
              <div class="subscriptionCard-lineSeparator"></div>
              <div class="subscriptionCard-list">
                <ul>
                  <li><p class="__paragraph">Проходка в проект.</p><p class="__caption">Ігрова роль <span class="omhms-text omhms-text-bold omhms-text-color-server_player omhms-text__player-player">Гравця</span></p></li>
                  <li><p class="__paragraph">Ексклюзивна роль в Discord</p><p class="__caption"><span class="omhms-text omhms-text-bold omhms-text-color-server_newbie omhms-text__player-tier2">Потужна підтримка</span>.</p></li>
                  <li><p class="__paragraph">Ліміт "Новачків" <span class="__limit __colored">2/2</span></p><p class="__caption">Деталі про <a target="_blank" href="/season#feature-slaves">Новачків</a>.</p></li>
                  <li><p class="__paragraph">Базовий ліміт plot'ів: <span class="__limit __colored">9</span></p><p class="__caption">Деталі про <a target="_blank" href="/season#feature-plots">plot'и</a>.</p></li>
                  <li><p class="__paragraph">Стяги на веб-мапі: <span class="__limit __colored">8</span></p><p class="__caption">Деталі про <a target="_blank" href="/season#feature-flags">стяги</a>.</p></li>
<!--                   <li><p class="__paragraph">Щотижневі виплати від Мерії:
                    <span class="__limit __colored">
                      <img
                        class="omhms-image-coin __coins-img"
                        :src="require(`@/assets/images/donationIcons/coin.png`)"
                      />
                      <span class="__coins">16</span> 
                    </span>
                  </p></li> -->
                  <li><p class="__paragraph">Ліміт приватних порталів: <span class="__limit __colored">2</span></p><p class="__caption">Деталі про <a target="_blank" href="/season#feature-portals">приватні портали</a>.</p></li>
                  <li><p class="__paragraph">Ліміт голограм: <span class="__limit __colored">2</span></p><p class="__caption">Деталі про <a target="_blank" href="/season#feature-holograms">голограми</a>.</p></li>
                  <li><p class="__paragraph">Вишукані штучки: <span class="__limit __colored">1</span></p><p class="__caption">Деталі про <a target="_blank" href="/season#feature-custom-items">ексклюзивні</a> речі.</p></li>
                  <li><p class="__paragraph">Знижка на косметику на Міні-іграх: <span class="__limit __colored"> 5%</span></p></li>
                </ul>
              </div>
            </div>
            <div class="subscriptionCard-button">
              <template v-if="check"> 
                <!-- <CustomButton value="ПРИДБАТИ" @clicked="$router.push('profile')"/> -->
                <a class="subscriptionCard-button__big omhms-text-link" :href="donationLink(this.tier2, 'Потужна перепустка без доната на ЗСУ')" target="_blank">
                  <div class="custom-button"> <div class="button"> Потужна за {{ subscription("2", false) }} </div></div>
                </a>
                <a class="subscriptionCard-button__big subscriptionCard-button__zsu__tier2 omhms-text-link" :href="donationLink(this.tier2zsu, 'Потужна перепустка і донат на ЗСУ')" target="_blank">
                  <div class="custom-button"> <div class="button button_clear button_small"> Потужна + на ЗСУ <strong>за {{ subscription("2", true) }}</strong> </div></div>
                </a>
              </template>

              <template v-if="!check"> 
                Спочатку анкета, потім перепустка :)
                <div class="subscriptionCard-ifnotlogined">
                  <CustomButton value="Заповнити анкету" isSmall isClear @clicked="$router.push('profile')"/>
                  <CustomButton value="Залогінитись" isSmall isClear @clicked="$router.push('profile')"/>
                </div>  
              </template>
            </div>
          </div>
        </div>
        
        <div class="col-lg-4 col-sm-10 col-12">
          <div class="subscriptionCardHolder__third"> 
            <div class="subscriptionCard">
              <div class="subscriptionCard__titlepart">
                <div class="subscriptionCard-icon">
                  <img src="../assets/images/subscription/tier3.png"/>
                </div>
                <div>
                  <div class="subscriptionCard-title">Могутня</div>
                  <div class="subscriptionCard-description">Надзвичайно могутній вклад в наш проект!</div>
                </div>
              </div>
              <div class="subscriptionCard-lineSeparator"></div>
              <div>
                <p class="subscriptionCard-price">{{ subscription("3", false) }}<sup>.00</sup><span class="subscriptionCard-priceCaption">грн/місяць</span></p>
                <p class="subscriptionCard-priceZSU">Або <span class="subscriptionCard-priceZSU__bold">{{ subscription("3", true) }} грн</span>, з яких <span class="subscriptionCard-priceZSU__bold">{{ subscription("3", true) - subscription("3", false) }} грн </span><br>піде на користь ЗСУ<span class="omhms-text-caption">, і {{ subscription("3", false) }} на сервер.</span></p>
              </div>
              <div class="subscriptionCard-lineSeparator"></div>
              <div class="subscriptionCard-list">
                <ul>
                  <li><p class="__paragraph">Проходка в проект.</p><p class="__caption">Ігрова роль <span class="omhms-text omhms-text-bold omhms-text-color-server_player omhms-text__player-player">Гравця</span></p></li>
                  <li><p class="__paragraph">Ексклюзивна роль в Discord</p><p class="__caption"><span class="omhms-text omhms-text-bold omhms-text-color-server_newbie omhms-text__player-tier3">Могутня підтримка</span>.</p></li>
                  <li><p class="__paragraph">Ліміт "Новачків" <span class="__limit __colored">4/4</span></p><p class="__caption">Деталі про <a target="_blank" href="/season#feature-slaves">Новачків</a>.</p></li>
                  <li><p class="__paragraph">Базовий ліміт plot'ів: <span class="__limit __colored">16</span></p><p class="__caption">Деталі про <a target="_blank" href="/season#feature-plots">plot'и</a>.</p></li>
                  <li><p class="__paragraph">Стяги на веб-мапі: <span class="__limit __colored">12</span></p><p class="__caption">Деталі про <a target="_blank" href="/season#feature-flags">стяги</a>.</p></li>
<!--                   <li><p class="__paragraph">Щотижневі виплати від Мерії:
                    <span class="__limit __colored">
                      <img
                        class="omhms-image-coin __coins-img"
                        :src="require(`@/assets/images/donationIcons/coin.png`)"
                      />
                      <span class="__coins">32</span> 
                    </span>
                  </p></li> -->
                  <li><p class="__paragraph">Ліміт приватних порталів: <span class="__limit __colored">4</span></p><p class="__caption">Деталі про <a target="_blank" href="/season#feature-portals">приватні портали</a>.</p></li>
                  <li><p class="__paragraph">Ліміт голограм: <span class="__limit __colored">4</span></p><p class="__caption">Деталі про <a target="_blank" href="/season#feature-holograms">голограми</a>.</p></li>
                  <li><p class="__paragraph">Вишукані штучки: <span class="__limit __colored">2</span></p><p class="__caption">Деталі про <a target="_blank" href="/season#feature-custom-items">ексклюзивні</a> речі.</p></li>
                  <li><p class="__paragraph">Ранній доступ до майбутніх оновлень.</p></li>
                  <li><p class="__paragraph">Знижка на косметику на Міні-іграх: <span class="__limit __colored"> 10%</span></p></li>
                  <li><p class="__paragraph">Приорітет пропозицій</p><p class="__caption">Деталі про <a target="_blank" href="/season#feature-palce-of-honor-and-suggestions">пропозиції</a>.</p></li>
                  <li><p class="__paragraph">IRL продукція та мерч*</p><p class="__caption">Деталі про <a target="_blank" href="/season#merch">сувенірчики</a>.</p><p class="__caption">*за наявності Могутньої перепустки за 3 місяці, або будь-якої – за 12 місяців</p></li>
                </ul>
              </div>
            </div>
            <div class="subscriptionCard-button">
              <template v-if="check"> 
                <!-- <CustomButton isClear value="ПРИДБАТИ" @clicked="$router.push('profile')"/> -->
                <a class="subscriptionCard-button__big omhms-text-link" :href="donationLink(this.tier3, 'Могутня перепустка без доната на ЗСУ')" target="_blank">
                  <div class="custom-button"> <div class="button"> Могутня за {{ subscription("3", false) }} </div></div>
                </a>
                <a class="subscriptionCard-button__big subscriptionCard-button__zsu__tier3 omhms-text-link" :href="donationLink(this.tier3zsu, 'Могутня перепустка і донат на ЗСУ')" target="_blank">
                  <div class="custom-button"> <div class="button button_clear button_small"> Могутня + на ЗСУ <strong>за {{ subscription("3", true) }}</strong> </div></div>
                </a>
              </template>

              <template v-if="!check"> 
                Спочатку анкета, потім перепустка :)
                <div class="subscriptionCard-ifnotlogined">
                  <CustomButton value="Заповнити анкету" isSmall isClear @clicked="$router.push('profile')"/>
                  <CustomButton value="Залогінитись" isSmall isClear @clicked="$router.push('profile')"/>
                </div>  
              </template>
            </div>
          </div>
        </div>
        
      </div>
    </div>

    </div>

    <!-- DISCLAIMER -->
    <div class="container omhms-content-container" id="subDisclaimer">
      <div class="row omhms-content-block">
          <div class="col-lg-5 col-sm-12">
            <div class="col-2 omhms-content-block-decorline"></div>
            <p class="omhms-text omhms-text-topcap">Сповідь Одмєна</p>
            <span class="omhms-text omhms-text-h2-bold"><i>«За що я плачу?»</i></span>
            <div class="spacer-24"></div>
            <p class="omhms-text omhms-text-italic">Проект OMHMS є затишним острівцем в океані різноманітних серверів по Minecraft. <span class="omhms-text-color-60"> Цей проект створюється не для комерції, а перш за все для гравців. Для гравців, які розділяють цінності проекту, бажають комфортної гри та готові самі створювати собі контент.</span></p>
            <p class="omhms-text omhms-text-italic omhms-text-color-60">Проект створюється фактично однією людиною, за парадигмою "все сам", тому я рекомендую не  будовувати хибних очікувань з приводу активного РП-життя на проекті та івентів кожен тиждень.</p>
            <p class="omhms-text omhms-text-italic omhms-text-color-95">OMHMS – це майданчик для ініціативних гравців. Якщо у вас є ідея – кажіть, робіть і будьте ініціативним.</p>
            <p class="omhms-text omhms-text-caption omhms-text-color-60"><i>Проект OMHMS є моїм пет-проектом, і я займаюсь цим здебільшого “для себе” бо отримую певне збочене задоволення від всього того чим я тут займаюсь. <strong>Якщо Ви розділяєте цінності проекту – запрошую вас до OMHMS</strong>, якщо ж ні – існує безліч інших серверів по Minecraft. Впевнений, кожен може знайти щось "для себе".</i></p>
            <p class="omhms-text omhms-text-caption"><i>© Одмєн</i></p>
          </div>
          <div class="col-lg-6 offset-lg-1 offset-md-0 col-md-10 col-sm-12 margin-top ocb-padding-top-50">
            <p class="omhms-text omhms-text-topcap">Мінімальна перепустка</p>
            <span class="omhms-text omhms-text-h2-bold">Що таке {{ subscription("1", false) }} грн?</span>
            <div class="spacer-24"></div>
            <p class="omhms-text omhms-text-regular"><span class="omhms-text-bold">{{ subscription("1", false) }} грн</span> – це ~1,5 доляри.</p>
            <p class="omhms-text omhms-text-regular"><span class="omhms-text-bold">{{ subscription("1", false) }} грн</span> – це ~5-10 проїздів в метро чи маршрутці.</p>
            <p class="omhms-text omhms-text-regular"><span class="omhms-text-bold">{{ subscription("1", false) }} грн</span> – це ~1-2 перекуси випічкою із супермаркету.</p>
            <p class="omhms-text omhms-text-regular"><span class="omhms-text-bold">{{ subscription("1", false) }} грн</span> – це навіть не півфіліжанки кави разом із круасаном.</p>
            <p class="omhms-text omhms-text-caption">Чи багато це, за місяць гри на проекті в який вкладено безліч сил і часу?</p>
          </div>
        </div>
    </div>      
    <!-- END OF DISCLAIMER -->

    <template v-if="!check">  
      <div class="container-fluid omhms-content-container-dark">
        <div class="row omhms-content-block omhms-content-block-cta__img2">
          <div class="col-12">
            <p class="omhms-text omhms-text-h2-regular">То що, готовий вриватися у проект?</p>
            <p class="omhms-text omhms-text-regular">Реєструйся, заповнюй просту форму та швидше приєднуйся до OMHMS!</p>
            <CustomButton value="ЗАПОВНИТИ АНКЕТУ" @clicked="$router.push('profile')"/>
          </div>
        </div>
      </div>
    </template>

    <!-- PAYMENTS -->
    <!--    <div class="container omhms-content-container-dark" >
      <div id="subscriptionPaymentsInfo">
        <div class="row justify-content-center omhms-content-block">
          <div class="col-md-10 col-sm-12">
            <span class="omhms-text omhms-text-h2-bold">Порядок та умови оплати</span>
            <div class="spacer-24"></div>
            <p class="omhms-text omhms-text-regular">Бажано оплачувати проходку <span class="omhms-text-color-success">за наступний місяць – до кінця поточного</span>.</p>
            <p class="omhms-text omhms-text-regular">Якщо оплата була прострочена, є <span class="omhms-text-color-warning">пільговий період до 10 числа кожного місяця</span>.<br> <span class="omhms-text-color-60 omhms-text-caption">У разі несплати, акаунт гравця буде "понижено" до рівня <span class="omhms-textomhms-text-color-server_guest omhms-text__player-guest">Гість</span>.</span></p>
            <p class="omhms-text omhms-text-regular">Гравці, які прийшли в проект <span class="omhms-text-bold omhms-text-color-60">до 24 числа поточного місяця (включно)</span> – оплачують підписку за поточний місяць у звичайному режимі.</p>
            <p class="omhms-text omhms-text-regular">Гравці, які прийшли в проект <span class="omhms-text-bold omhms-text-color-60">25 числа і після</span> – оплачують підписку за наступний місяць, а поточний місяць можуть дограти "безкоштовно".
              <br> <span class="omhms-text-color-60 omhms-text-caption">Така підписка все одно сплачується одразу, під час реєстрації в проекті.</span></p>
            <p class="omhms-text omhms-text-regular">Гравці, які мають безкоштовну чи "пільгову" перепустку, на один або кілька місяців, і хочуть приєднатись до проекту <span class="omhms-text-bold omhms-text-color-60">все одно мають сплатити початкову підписку за перший місяць</span>.
              <br><span class="omhms-text-color-60 omhms-text-caption">Це є певною гарантією того, що людина не буде зловживати виграною підпискою.</span></p>
            <p class="omhms-text omhms-text-caption omhms-text-color-60">Повернення підписки передбачене <span class="omhms-text-color-80">лише за наступні місяці</span>, за попередньою домовленістю із Одмєном.</p>
          </div>
          </div>
      </div>      
    </div> -->
    <!-- END OF PAYMENTS -->

  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader"
import CustomButton from "@/components/CustomButton.vue"
import { mapGetters } from 'vuex'

export default {
  name: 'Subscription',
  components: {
    PageHeader,
    CustomButton
  },
  props: {
  },
  data() {
    return {
      title: "Магазин",
      tier1: 55, // 110 = 75+35
      tier1zsu: 90,
      tier2: 110, // 185 
      tier2zsu: 145,
      tier3: 220, // 365 
      tier3zsu: 365,
      slavePrice: 300
    } 
  },
  mounted() {
    this.images = document.querySelectorAll(".omhms-image");
    this.customTopOffset = -50;
    this.$isMobile() ? this.mobileMultiplier = 0.5 : this.mobileMultiplier = 1
    window.addEventListener('scroll', this.parallaxTheMainImages);
  },
  unmounted() {
    window.removeEventListener('scroll', this.parallaxTheMainImages);
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      check: "auth/check"
    }),
  },
  methods: {
    parallaxTheMainImages() {
      // console.log("i'm working")
      // console.log(this.$isMobile() + " " + this.mobileMultiplier);
      for (var i = 0; i < this.images.length; i++) {
        var top = this.images[i].getBoundingClientRect().top;
        if (top > -500 & top < 1000) {
          this.images[i].style.top = (this.customTopOffset + top/10 * this.images[i].dataset.pm) * this.mobileMultiplier + "px";
        }
      }
    },
    subscription(level, zsu){
      var price = -1;
      switch (level) {
        case "1":
          price = zsu ? this.tier1zsu : this.tier1;
          break;
        case "2":
          price = zsu ? this.tier2zsu : this.tier2;
          break;
        case "3":
          price = zsu ? this.tier3zsu : this.tier3;
          break;
        case "slave":
          price = this.slavePrice;
          break;
        default:
          break;
      }
      return price;
    },
    donationLink(money, comment) {
      // console.log(this.subscription("1", true));
      return `https://donatello.to/omhms?a=${money}&c=${this.user.realName}&m=${this.user.realName}: ${comment}`;
      // https://donatello.to/omhms?a=100&c=NickUserName&m=MyMessageHere
    },
  }
}
</script>