<template>
  <div class="main-layout main-layout_no-margin bg-end-noise">
    <div class="main-image__games">
      <div class="slider">
        <div class="slide__games"></div> 
        <div class="slide__games"></div> 
        <div class="slide__games"></div> 
        <div class="slide__games"></div> 
        <div class="slide__games"></div> 
      </div>
      <div class="main-image__text">
        <div class="main-image__text-subtitle">Міні-ігри, по-домашньому</div>
        <div class="main-image__minigames-logos">
          <img class="icon-logo__big" src="../assets/images/svg/logo_omhms.svg"/>
          <img class="icon-logo__big" src="../assets/images/svg/logo_minigames.svg"/>
        </div>
        <div class="main-image__text-update-games">
          <span class="minigame-span">БедВарс</span>
          <span class="minigame-span">Голодні ігри</span>
          <span class="minigame-span">Паркур</span>
          <span class="minigame-span">Паті-ігри (22 міні-гри)</span>
          <span class="minigame-span">УХК</span>
          <span class="minigame-span">Chunklock</span>
          <span class="minigame-span__more">ще 5 режимів в розробці</span>
        </div>
        <div class="main-image__text-ip">
          <div>
            <span>ip:</span><span class="main-image__text-ip__bold" @click="copyip" id="ipaddress">play.omhms.com</span><span>Версія:</span><span class="main-image__text-ip__bold">1.16.5 – 1.20.x</span>
          </div>  
        </div>
        <div class="main-image__text-buttons">
          <!-- <CustomButton :value="joinButtonTitle" @clicked="$router.push('profile')"/> -->
          <!-- <div class="spacer-16 spacer-16-horizontal"></div> -->
          <CustomButton isClear value="Детальніше" @clicked="scrollTo('zero_anchor')"/>
        </div>
      </div>
    </div>

    <div class="container omhms-content-container mainPageSwitcher"  id="zero_anchor">
      <div class="mainPageSwitcher-block">
          <router-link :to="{ name: 'main' }" :savedPosition="true">
            <span class="mainPageSwitcher-tab-survival">Виживання</span>
          </router-link>
          <!-- <router-link :to="{ name: 'games' }" :savedPosition="true"> -->
            <span class="mainPageSwitcher-tab-games__active">Міні-ігри</span> 
          <!-- </router-link> -->
      </div>
    </div>

    <div class="container omhms-content-container" >

      <div class="row omhms-content-block ">
        <div class="col-lg-6 col-sm-12">
          <div class="col-2 omhms-content-block-decorline"></div>
          <span class="omhms-text omhms-text-h2-regular">Що таке <span class="omhms-text omhms-text-h2-bold">OMHMS Міні-ігри</span>?</span>
          <div class="spacer-24"></div>
          <p class="omhms-text omhms-text-regular"><span class="omhms-text omhms-text-bold">OMHMS Міні-ігри</span> – це безкоштовний сервер, де зібрані популярні (і не дуже) міні-ігри.</p>
          <div class="row margin-top">
            <div class="col offset-lg-2 offset-md-1">
              <p class="omhms-text omhms-text-italic omhms-text-color-95">Колись давно відгалуження "OMHMS Міні-ігри"" з'явилось завдяки TorDanGar`у і його бажання провести великий івент – Голодні ігри. Так і почалась історія OMHMS Міні-ігр.</p>
              <p class="omhms-text omhms-text-italic omhms-text-color-95">Сервер – безкоштовний, і завжди таким буде, але щоб підтримати проект ви можете просто задонатити або розглянути внутрішньоігрову косметику.</p>
              <a class="omhms-text-link" href="https://donatello.to/omhms" target="_blank">
                <!-- Сплатити проходку -->
                <div class="custom-button"> <div class="button button_clear button_small"> Donatello </div></div>
                <!-- <CustomButton isClear isSmall value="Сплатити проходку" @clicked="$router.push('profile')"/> -->
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-5 offset-lg-1 offset-md-0 col-md-10 col-sm-12 margin-top-md-sm">
          <div class="omhms-image" :data-pm='1.4'>
            <img
              id="img1"
              class="omhms-image-stroke"
              alt="Hello, there!"
              :src="require(`@/assets/images/homepage/small/01.jpg`)"
            />
          </div>
        </div>
      </div>

      <div class="row omhms-content-block">
        <div class="col-xl-4 col-lg-5 col-12 ocb-padding-top-0">
          <div class="omhms-image" :data-pm="1.7">
            <img
              class="omhms-image-stroke"
              alt="Підписка"
              :src="require(`@/assets/images/mainbg_games/mg_01.jpg`)"
            />
          </div>
        </div>
        <div class="col-xl-6 col-lg-5 offset-lg-1 col-md-11 offset-md-1 col-12 ocb-padding-top-50 ocb-padding-top-lg-0">
          <p class="omhms-text omhms-text-topcap">ПРО СЕРВЕР</p>
          <p class="omhms-text omhms-text-h2-regular">Спільнота</p>
          <p class="omhms-text omhms-text-regular">Ми намагаємось підтримувати дружню і не конфліктну атмосферу. В OMHMS завжди на першому місці були принципи свободи та взаємоповаги.</p>
          <p class="omhms-text omhms-text-regular">Пам'ятайте головне: <span class="omhms-text omhms-text-bold">Ваші права закінчуються там, де починаються права іншого гравця.</span></p>
          <p class="omhms-text omhms-text-caption">Проте, оскільки сервер із вільним доступом, відповідати за інших людей ми не взмозі.</p>
        </div>
      </div>


      <div class="row omhms-content-block">
        <div class="col-xl-6 col-lg-5 col-md-11 offset-md-1 col-12 ocb-padding-top-50 ocb-padding-top-lg-0">
          <div class="omhms-image" :data-pm="1.2">
            <img
              class="omhms-image-stroke"
              alt="Підписка"
              :src="require(`@/assets/images/mainbg_games/mg_02.jpg`)"
            />
          </div>
        </div>
        <div class="col-xl-4 offset-lg-1 col-lg-5 col-12 ocb-padding-top-0 ocb-padding-top-50 ">
          <div class="omhms-image" :data-pm="0.3">
            <img
              class="omhms-image-stroke"
              alt="Підписка"
              :src="require(`@/assets/images/mainbg_games/mg_03.jpg`)"
            />
          </div>
        </div>
      </div>

    </div>
    <div class="omhms-content-container">
      <div class="row omhms-content-block omhms-content-block-cta">
        <div class="col-12">
          <p class="omhms-text omhms-text-h2-regular">Ну що, го грати?</p>
          <p class="omhms-text omhms-text-regular">Просто приєднуйся до сервера по ip: <span class="omhms-text omhms-text-console">play.omhms.com</span></p>
          <p class="omhms-text omhms-text-regular">заходь на лоббі міні ігр та грай!</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from "@/components/CustomButton.vue"
import { mapGetters } from 'vuex'

export default {
  name: 'Main',
  components: {
    CustomButton
  },
  data() {
    return {
      title: "Головна"
    }
  },
  mounted() {
    this.images = document.getElementsByClassName("omhms-image");
    this.customTopOffset = -50;
    this.$isMobile() ? this.mobileMultiplier = 0.5 : this.mobileMultiplier = 1
    window.addEventListener('scroll', this.parallaxTheMainImages);
  },
  unmounted() {
    window.removeEventListener('scroll', this.parallaxTheMainImages);
  },
  computed: {
    ...mapGetters({
      check: "auth/check"
    }),
    
    joinButtonTitle() {
      return this.check ? 'В особистий кабінет' : 'Приєднатися'
    }
  },
  methods: {
    scrollTo(itemId) {
      if (!document.getElementById(itemId)
        || !document.getElementById(itemId).offsetTop) { return }

      var top = document.getElementById(itemId).offsetTop
      window.scrollTo(0, top)
    },
    parallaxTheMainImages() {
      // console.log("i'm working")
      console.log(this.$isMobile() + " " + this.mobileMultiplier);
      for (var i = 0; i < this.images.length; i++) {
        var top = this.images[i].getBoundingClientRect().top;
        if (top > -500 & top < 1000) {
          this.images[i].style.top = (this.customTopOffset + top/10 * this.images[i].dataset.pm) * this.mobileMultiplier + "px";
        }
      }
    },
    async copyip() {
      var ip = document.getElementById("ipaddress").innerHTML;

      try {
        await navigator.clipboard.writeText(ip);
        console.log('IP скопійовано ;)');
      } catch($e) {
        console.log('Якась халепа. IP не скопійовано, доведеться писати руцями самому :С');
        console.log($e);
      }

      const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

      (async () => {
        document.getElementById("alertCopied").style.opacity = 1;
        await delay(2000);
        document.getElementById("alertCopied").style.opacity = 0;
      })();
    }
  }
}



</script>
