import Question from "./Question"

export default class TextQuestion extends Question {
  constructor(props) {
    super(props)

    this.setDefaultData()
    if (!props) return
    this.type = "text"
    this.answer = props.answer ?? ""
    this.description = props.description ?? ""
    this.placeholder = props.placeholder ?? ""
  }

  update(value) {
    this.answer = value
  }

  hasAnswer() {
    return this.answer
  }

  getJsonObj() {
    let props = {
      type: this.type,
      answer: this.answer,
      description: this.description,
      placeholder: this.placeholder
    }

    return super.getJsonObj(props)
  }

  setDefaultData() {
    this.answer = ""
    this.description = ""
    this.placeholder = ""
  }
}