import QuestionBlock from "@/models/registration/QuestionBlock"

export default class Registration {
  constructor(props) {
    this.personId = props.personId ?? ""
    this.status = props.status ?? ""
    this.approvedBy = props.approvedBy ?? null
    this.votes = props.votes ?? []
    this.blocks = props.blocks.map(bl => new QuestionBlock(bl))
  }


  getJsonObj() {
    return {
      userName: this.userName ?? "",
      personId: this.personId ?? "",
      status: this.status ?? "",
      votes: this.votes ?? [],
      blocks: this.blocks.map(b => b.getJsonObj())
    }
  }

  get isApproved() {
    return this.status === "approved"
  }

  static empty() {
    return new Registration({
        blocks: []
      })
  }
}
