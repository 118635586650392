import { apiUrl } from "@/config"
import Handlers from "@/events/handlers"
import { v4 as uuidv4 } from "uuid"

export default class EventSourseClass {
  constructor(url) {
    this.url = url
    this.token = uuidv4()

    this.evtSource = null
    this.reconnectFrequencySeconds = 1
    this.init()
  }

  init() {
    this.setupEventSource()
  }

  reconnectFunc = this.debounce(this.handleReconnect, () => {
    return this.reconnectFrequencySeconds * 1000
  })

  handleReconnect() {
    this.setupEventSource()

    // Double every attempt to avoid overwhelming server
    this.reconnectFrequencySeconds *= 2

    // Max out at ~1 minute as a compromise between user experience and server load
    if (this.reconnectFrequencySeconds >= 64) {
      this.reconnectFrequencySeconds = 64
    }
  }

  async setupEventSource() {
    this.evtSource = new EventSource(apiUrl(`${this.url}?token=${this.token}`))

    this.evtSource.onopen = () => {
      this.onEvtSourceOpen()
    }

    this.evtSource.onerror = () => {
      this.onEvtSourceError()
    }

    for (const handler of Handlers) {
      this.evtSource.addEventListener(handler.eventType, event => {
        handler.handle(event)
      })
    }
  }

  closeEventSourse() {
    this.evtSource.close()
  }

  onEvtSourceOpen() {
    // Reset reconnect frequency upon successful connection
    this.reconnectFrequencySeconds = 1;
  }

  onEvtSourceError() {
    this.closeEventSourse()
    this.reconnectFunc()
  }

  isFunction(functionToCheck) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]'
  }

  debounce(func, wait) {
    let timeout;
    let waitFunc;

    return function() {
      if (this.isFunction(wait)) {
        waitFunc = wait
      } else {
        waitFunc = function() {
          return wait
        }
      }

      let context = this
      let args = arguments

      let later = function() {
        timeout = null
        func.apply(context, args)
      }

      clearTimeout(timeout)

      timeout = setTimeout(later, waitFunc())
    }
  }
}
