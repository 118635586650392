export default class RconServer {
  constructor(props) {
    this.id = props.id ?? ""
    this.url = props.url ?? ""
    this.port = props.port ?? ""
    this.name = props.name ?? ""
    this.isOnline = props.isOnline ?? false
    this.consoleLines = props.consoleLines ?? []
    this.rconLines = props.rconLines ?? []
  }

  addRconLines(type, lines) {
    if (!type || !lines) { return }
    this.rconLines.push(...lines.map(l => {
      return (l.includes("§")) ? RconServer.createParsedLine(type, l) : RconServer.createDefaultLine(type, l)
    }))
  }

  addConsoleLines(lines) {
    this.consoleLines.push(...lines)
  }

  clearConsoleLines() {
    this.consoleLines = []
  }

  static create(props) {
    return new RconServer(props)
  }

  static createDefaultLine(type, line) {
    return {
      type: type,
      text: line
    }
  }

  static createParsedLine(type, line) {
    return {
      type: type,
      data: line
        .split('§')
        .filter(l => l.length > 0)
        .map(l => {
          return {
            color: RconServer.rconColors.find(c => c.key === l[0]).color ?? "white",
            text: l.slice(1)
          }
        })
    }
  }

  static get rconColors() {
    return rconColors
  }
}

const rconColors = [
  {
    key: "f",
    color: "white"
  },
  {
    key: "0",
    color: "black"
  },
  {
    key: "2",
    color: "darkGreen"
  },
  {
    key: "4",
    color: "darkRed"
  },
  {
    key: "6",
    color: "gold"
  },
  {
    key: "8",
    color: "darkGrey"
  },
  {
    key: "a",
    color: "green"
  },
  {
    key: "c",
    color: "red"
  },
  {
    key: "e",
    color: "yellow"
  },
  {
    key: "1",
    color: "darkBlue"
  },
  {
    key: "3",
    color: "darkAqua"
  },
  {
    key: "5",
    color: "darkPurple"
  },
  {
    key: "7",
    color: "gray"
  },
  {
    key: "9",
    color: "blue"
  },
  {
    key: "b",
    color: "aqua"
  },
  {
    key: "d",
    color: "lightPurple"
  }

]