<template>
  <div class="container-fluid main-layout main-layout_no-margin page-noScrollPage map">
    <div class="row page-noScrollPage__row">
      <div class="col">
        <iframe v-if="showMap" src="https://omhms.com:10000/"></iframe>
        <div v-else>
          <img :src="require(`@/assets/images/omhms_403.jpg`)" class="map__img">
          <p class="map__paragraph">Соррі друже, тобі сюди не можна!</p>
          <p class="map__paragraph">Лише гравці можуть переглядати мапу!</p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters } from "vuex"

export default {
  name: 'Map',
  data() {
    return {
      title: "Карта",
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),

    links() {
      const url = process.env.VUE_APP_MAIN_FRONT_ENDPOINT

      return {
        main: `${url}:9999/main`,
        factory: `${url}:9999/factory`
      }
    },

    showMap() {
      return this.user?.registrationStatus === "approved" || this.$can('newbie') || this.$can('player')
    },

    iframeLink() {
      let param = this.$route.params?.type
      if (!param) {
        param = "main"
      }
      return this.links[param]
    }
  }
}

</script>
