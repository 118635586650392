import store from "@/store/index"

const userGroup = {
  'frontendadmin': 5,
  'admin': 4,
  'player': 3,
  'newbie': 2,
  'default': 1,
}

const vcan = (status) => {
  if (
    !store.getters["auth/user"] ||
    !store.getters["auth/user"].userGroup ||
    !userGroup[status]
    ) return false
  return userGroup[store.getters["auth/user"].userGroup.toLowerCase()] >= userGroup[status]
}

export default vcan
