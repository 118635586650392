<template>
  <Header/>
  <router-view :key="$route.fullPath"></router-view>
  <Footer v-show="displayFooter"/>
</template>

<script>

import Header from './components/Header'
import Footer from './components/Footer'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'OMHMS Front App',
  components: {
    Header,
    Footer,
  },
  computed: {
    ...mapGetters({
      cookieCheck: "auth/cookie"
    }),

    displayFooter() {
      switch (this.$route.name) {
        case 'notFound':
        case 'map':
        case 'map_main':
        case 'map_factory':
        case 'stats':
        case 'rcon':
          return false
        default:
          return true
      }
    }
  },
  async created() {
    document.documentElement.setAttribute('lang', 'ru')
    if (this.cookieCheck) {
      await this.login()
    }
  },
  metaInfo() {
    return {
      title: "OMHMS",
      titleTemplate: `%s · OMHMS`
    }
  },
  methods: {
    ...mapActions({
      login: "auth/loginCookie"
    }),
  }
}
</script>