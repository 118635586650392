// TODO : refactor this page layout
<template>
  <div class="main-layout profile-registration">
    <PageHeader :title="title"/>
    <div class="registration-form container">
      <div v-if="error" class="row justify-content-center omhms-content-block error-block_no-record">
        <span class="omhms-text omhms-text-h2-regular">
          Цієї анкети немає в нашій базі
        </span>
      </div>
      <template v-else>
        <div class="row justify-content-center">
          <div class="profile-content__header col-lg-8 col-11">
            <template v-if="isPreview">
              <div v-if="profile && profile.person && profile.characters.length">
                <div class="header-title">
                  Анкета {{ profile.person.realName}}
                </div>
                <div class="header-subtitle">
                  Також відомий як:
                </div>
                <div class="header-desc">
                  {{ profile.characters.map(c => c.realName).join(" ") }}
                </div>
                <!-- TODO: -->
                <!-- <div class="header-caption">
                  <br><p>Дата створення анкети:<span class="header-caption-date">dd.mm.yyyy</span></p>
                </div> -->
              </div>
            </template>
            <template v-else>
              <div class="header-title">
                Анкета
              </div>
              <div class="header-subtitle">
                Раді, що ти вирішив приєднатися до нас!
              </div>
              <br>
              <!-- <div class="custom-notification" style="justify-content: center; flex-direction: column;">
                <span><strong>На даний момент спостерігаються певні труднощі у роботі системи реєстрації.</strong></span>
                <br>
                <span style="color: #8791AB;">Після заповнення, анкета буде збережена, але зайти в профіль не вдасться.</span>
                <br>
                <span style="color: #5E6A87;">Будь-ласка, зверність за додатковою інформацією в <a target="_blank" style="color: #E1E3EA" href="https://discord.gg/5HugZgxgZz">Діскорд</a> проекту.</span>
              </div> -->
              <br>
              <div v-if="isRegistrationFormCreated" class="header-desc">
                Будь ласка, заповнюй анкету чесно та правдиво! Адже будь-які нестиковки та підозрілі моменти можуть вилізти боком під час гри! Нагадаємо, що після оплати підписки, у разі бану, вона не повертається!
              </div>
              <div v-else class="header-desc">
                Твоя анкета заповнена та проходить модерацію. В твоєму особистому кабінеті будуть відображатися всі актуальні дані по тобі, та твоїй анкеті. Тепер все залежить тільки від ком'юніті :)
              </div>
            </template>
          </div>
        </div>
        <template v-if="isRegistrationFormCreated">
          <form class="registration-form__container">
            <div
              v-for="(questionBlock, qbIndex) in registrationForm.blocks"
              :id="`questionBlock-${qbIndex}`"
              :key="`questionBlock-${qbIndex}`"
              class="row justify-content-center"
            >
              <div class="registration-form__block col-xl-6 col-lg-8 col-10">
                <div class="registration-form__block-header">
                  {{ getQuestionBlockTitle(qbIndex, questionBlock.title) }}
                </div>
                <div
                  class="registration-form__block-content"
                  :class="{collapse : !isPreview && qbIndex !== currentQuestionBlock}"
                >
                  <template v-if="questionBlock.isContactsBlock && isPreview && !this.$can('admin')">
                    <div class="registration-form__block-note">
                      <div class="block-note__title">Контактну інфомацію бачить лише адміністратор</div>
                    </div>
                  </template>
                  <template v-else>
                    <template
                      v-for="(question, qIndex) in questionBlock.questions"
                      :key="`question-${qbIndex}-${qIndex}`"
                    >
                      <template v-if="question.type === 'text'">
                        <CustomInput
                          :id="`question-${qbIndex}-${qIndex}`"
                          :type="(question.tag === 'birthDate' ? 'date' : 'text')"
                          :optional="question.optional"
                          :subtitle="question.description"
                          :answer="question.answer"
                          :pattern="questionPattern(question)"
                          :placeholder="question.placeholder"
                          :disabled="isPreview"
                          @onUpdate="updateQuestion(question, $event)"
                          @onFocusLeave="updateQuestion(question, $event)"
                        >
                          {{ question.text }}
                        </CustomInput>
                      </template>
                      <template v-else>
                        <CustomMultiple
                          :id="`question-${qbIndex}-${qIndex}`"
                          :optional="question.optional"
                          :options="question.options"
                          :disabled="isPreview"
                          @onUpdate="updateQuestion(question, $event)"
                        >
                          {{ question.text }}
                        </CustomMultiple>
                      </template>
                    </template>
                    <div v-if="questionBlock.note" class="registration-form__block-note">
                      <div class="block-note__title"> {{ questionBlock.note.title }} </div>
                      <div class="block-note__text"> {{ questionBlock.note.text }} </div>
                    </div>
                  </template>
                  <div
                    v-if="registrationForm.status === 'created'"
                    class="registration-form__buttons-wrap"
                    :class="[{'registration-form__buttons-wrap_right' : qbIndex === 0}]"
                  >
                    <CustomButton
                      v-if="qbIndex !== 0"
                      value="Назад"
                      isClear
                      withoutBorders
                      @clicked="goToPrevBlock"
                    />
                    <CustomButton
                      :value="(qbIndex + 1 == registrationForm.blocks.length) ? 'Готово' : 'Далі'"
                      :disabled="!questionBlock.isValid()"
                      @clicked="goToNextBlock"
                    />
                  </div>
                </div>
              </div>

              <div></div>
            </div>
          </form>
        </template>
        <template v-else>
          <div
            class="registration-form__submitted-message row justify-content-center omhms-content-block"
          >
            <div
              class="registration-form__block col-xl-6 col-lg-8 col-10 omhms-text omhms-text-regular"
            >
              <div class="submitted-message__block">
                <div class="submitted-message__block-header">Твоя анкета заповнена!</div>
                <div class="omhms-text-bold">Спасибі, що приділив час!</div>
                Ми обов'язково подивимося на твою заявку і зв'яжемося з тобою, коли будемо готові.
              </div>
              <div class="submitted-message__block">
                <div class="submitted-message__block-header">Що буде далі?</div>
                Гравці отримають сповіщення, що хтось новенький хоче потрапити до нас у проект.<br>
                Твоя анкета буде розглянута в найкоротші терміни (якщо нічого не поламається), і загальним голосуванням буде вирішено – приймаємо ми тебе, чи ні.<br>
                Статус перевірки ти зможеш дивитись у своєму особистому кабінеті у нас на сайті!
              </div>
              <div class="submitted-message__block">
                <div class="submitted-message__block-header">А після – оплата передплати.</div>
                Оплата підписки на даний момент відбувається за домовленістю із адміністрацією.
              </div>
              <div class="submitted-message__block">
                <div class="submitted-message__block-header">Чим зайнятися поки анкета на перевірці?</div>
                Чекаючи на рішення гравців, ти можеш побігати на нашому сервері, поспілкуватися з гравцями, озирнутися в центральному місті.
              </div>
              <div class="submitted-message__block">
                <div class="submitted-message__block-header">Залишились питання?</div>
                <span>Звертайся за відповідями в наш </span>
                <a class="footerbutton__discord" style="display: inline;" target="_blank" href="https://discord.gg/5HugZgxgZz">
                  <svg class="icon-socials__discord" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                    <path xmlns="http://www.w3.org/2000/svg" d="M23.1723 7.49003C21.6692 6.80036 20.0574 6.29224 18.3721 6.00122C18.3415 5.9956 18.3108 6.00964 18.295 6.03771C18.0877 6.4064 17.8581 6.88738 17.6973 7.26543C15.8847 6.99407 14.0814 6.99407 12.306 7.26543C12.1452 6.87898 11.9072 6.4064 11.699 6.03771C11.6832 6.01058 11.6525 5.99654 11.6218 6.00122C9.9375 6.29131 8.3257 6.79943 6.8217 7.49003C6.80868 7.49564 6.79752 7.50501 6.79011 7.51717C3.73285 12.0847 2.89534 16.5399 3.30619 20.9399C3.30805 20.9614 3.32014 20.982 3.33687 20.9951C5.35396 22.4764 7.30786 23.3757 9.22548 23.9717C9.25617 23.9811 9.28869 23.9699 9.30822 23.9446C9.76183 23.3251 10.1662 22.672 10.5129 21.9851C10.5333 21.9449 10.5138 21.8971 10.472 21.8812C9.83062 21.6379 9.2199 21.3413 8.63243 21.0044C8.58597 20.9773 8.58225 20.9108 8.62499 20.879C8.74862 20.7864 8.87228 20.69 8.99032 20.5927C9.01168 20.5749 9.04144 20.5711 9.06655 20.5824C12.926 22.3444 17.1042 22.3444 20.9181 20.5824C20.9432 20.5702 20.973 20.574 20.9953 20.5917C21.1134 20.689 21.237 20.7864 21.3615 20.879C21.4043 20.9108 21.4015 20.9773 21.355 21.0044C20.7676 21.3478 20.1568 21.6379 19.5145 21.8803C19.4727 21.8962 19.4541 21.9449 19.4746 21.9851C19.8287 22.671 20.2331 23.3242 20.6783 23.9437C20.6969 23.9699 20.7304 23.9811 20.761 23.9717C22.688 23.3757 24.6418 22.4764 26.6589 20.9951C26.6766 20.982 26.6878 20.9623 26.6896 20.9408C27.1813 15.8539 25.866 11.4352 23.2029 7.5181C23.1964 7.50501 23.1853 7.49564 23.1723 7.49003ZM11.0892 18.2607C9.92727 18.2607 8.96986 17.194 8.96986 15.8839C8.96986 14.5738 9.90871 13.507 11.0892 13.507C12.279 13.507 13.2272 14.5832 13.2086 15.8839C13.2086 17.194 12.2697 18.2607 11.0892 18.2607ZM18.9252 18.2607C17.7633 18.2607 16.8059 17.194 16.8059 15.8839C16.8059 14.5738 17.7447 13.507 18.9252 13.507C20.115 13.507 21.0631 14.5832 21.0446 15.8839C21.0446 17.194 20.115 18.2607 18.9252 18.2607Z" fill="white"/>
                  </svg>
                  <span class="omhms-text omhms-text-link"> Discord </span>
                </a>
                <span>! </span>

              </div>

              <div class="registration-form__buttons_left">
                  <CustomButton
                    value="В особистий кабінет"
                    @clicked="$router.push({ name: 'profile' })"
                  />
                  <CustomButton
                    value="На головну"
                    isClear
                    @clicked="$router.push({ name: 'main' })"
                  />
                </div>
            </div>
          </div>
        </template>
        <template v-if="isPreview">
          <div class="row justify-content-center omhms-content-block">
            <div class="registration-form__voting col-10 col-xl-10">
              <div class="voting-item">
                <span class="voting__header">Голосування</span>
                <span v-if="votingMessage" class="voting__message">{{ votingMessage }}</span>
                <span  v-if="votingSubMessage" class="voting__submessage">{{ votingSubMessage }}</span>
              </div>
              <div v-if="$can('admin')" class="voting-item voting-item__persons">
                <span class="voting__message">Список гравців, які вже віддали свій голос:</span>

                <div v-if="!votedPlayers.length" class="voted-persons">
                  <div class="voted-persons__person voted-persons__person-noone">
                    <span class="voted-person__player-name">Ще ніхто не проголосував</span>
                  </div>
                </div>
                <template v-else>
                  <div class="voted-persons voted-persons_approved">
                    <div class="voted-persons__counter">
                      {{ votedPlayersApproved.length }}
                    </div>
                    <template
                      v-for="(player, index) in votedPlayersApproved"
                      :key="`voted-player-approved-${index}`"
                    >
                      <div class="voted-persons__person">
                        <span class="voted-person__real-name">{{ player.realName }}</span>
                        <span class="voted-person__player-name">{{ player.playerName }}</span>
                      </div>
                    </template>
                    <template v-if="!votedPlayersApproved.length">
                      <div class="voted-persons__person voted-persons__person-noone">
                        <span class="voted-person__player-name">Ще ніхто не проголосував за</span>
                      </div>
                    </template>
                  </div>
                  <div class="voted-persons voted-persons_declined">
                    <div class="voted-persons__counter">
                      {{ votedPlayersDeclined.length }}
                    </div>
                    <template
                      v-for="(player, index) in votedPlayersDeclined"
                      :key="`voted-player-declined-${index}`"
                    >
                      <div class="voted-persons__person">
                        <span class="voted-person__real-name">{{ player.realName }}</span>
                        <span class="voted-person__player-name">{{ player.playerName }}</span>
                      </div>
                    </template>
                    <template v-if="!votedPlayersDeclined.length">
                      <div class="voted-persons__person voted-persons__person-noone">
                        <span class="voted-person__player-name">Ще ніхто не проголосував проти</span>
                      </div>
                    </template>
                  </div>
                  <div class="voted-persons voted-persons_undecided">
                    <div class="voted-persons__counter">
                      {{ votedPlayersUndecided.length }}
                    </div>
                    <template
                      v-for="(player, index) in votedPlayersUndecided"
                      :key="`voted-player-declined-${index}`"
                    >
                      <div class="voted-persons__person">
                        <span class="voted-person__real-name">{{ player.realName }}</span>
                        <span class="voted-person__player-name">{{ player.playerName }}</span>
                      </div>
                    </template>
                    <template v-if="!votedPlayersUndecided.length">
                      <div class="voted-persons__person voted-persons__person-noone">
                        <span class="voted-person__player-name">Ще ніхто не утримався</span>
                      </div>
                    </template>
                  </div>
                </template>
              </div>
              <div v-if="canVote" class="voting-item voting-item__vote">
                <span class="voting__message">Твоє рішення важливе, голосуй:</span>
                <div class="buttons-wrap">
                  <CustomButton
                    value="За"
                    :isClear="!hasVoted('APPROVED')"
                    @clicked="vote('APPROVED')"
                    class="vote-button vote-button_green"
                  />
                  <CustomButton
                    value="Проти"
                    :isClear="!hasVoted('DECLINED')"
                    @clicked="vote('DECLINED')"
                    class="vote-button vote-button_red"
                  />
                  <CustomButton
                    value="Утримався"
                    :isClear="!hasVoted('UNDECIDED')"
                    @clicked="vote('UNDECIDED')"
                    class="vote-button"
                  />
                </div>
              </div>
              <div class="voting-item voting-item__approve">
                <template v-if="registrationForm.isApproved">
                  Гравець <span :class="approvedRegistationFormMessageClass"><template v-if="!registrationForm.approvedBy.vote"> не </template> був прийнятий</span> на сервер адміном: <span style="font-weight: 700">{{registrationForm.approvedBy.userName}}</span>
                </template>
                <template v-else-if="canApprove">
                  <span class="voting__message">Завершити голосування</span>
                  <span class="voting__submessage">Рішення спільноти буде зафіксоване в анкеті гравця.</span>
                  <div class="buttons-wrap">
                    <CustomButton
                      value="Завершити та прийняти"
                      isClear
                      @clicked="approveRegistration(true)"
                      class="vote-button vote-button_green"
                    />
                    <CustomButton
                      value="Завершити та відхилити"
                      isClear
                      @clicked="approveRegistration(false)"
                      class="vote-button vote-button_red"
                    />
                  </div>
                </template>
              </div>
            </div>
          </div>
        </template>
      </template>
    </div>
  </div>

</template>

<script>

import { mapGetters, mapActions } from 'vuex'

import Registration from "@/models/registration/Registration"
import PageHeader from "@/components/PageHeader"
import CustomInput from "@/components/CustomInput"
import CustomMultiple from "@/components/CustomMultiple"
import CustomButton from "@/components/CustomButton"

export default {
  name: 'RegistrationForm',
  components: {
    CustomMultiple,
    CustomInput,
    CustomButton,
    PageHeader
  },
  data() {
    return {
      title: "Реєстрація",
      isRegistrationFormCreated: true,
      registrationForm: Registration.empty(),
      currentQuestionBlock: 0
    }
  },
  async created() {
    await this.getProfile(this.username)
    if (!this.profile || this.profile.registrationStatus === 'created') {
      await this.getNewRegistrationForm()
    } else {
      await this.getRegistrationForm(this.username)
    }
    this.registrationForm = this.form
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      profile: "profile/profile",
      isLoggedIn: "auth/check",
      form: "registration/form",
      error: "registration/error"
    }),

    isPreview() {
      return this.registrationForm.status && this.registrationForm.status !== 'created'
    },

    username() {
      const username = this.$route.params.username
      return username ? username : this.user.userName
    },

    votingMessage() {
      if (this.isYourRegistrationForm) {
        return 'Твоя анкета на голосуванні.'
      }
      if (!this.$can('player')) {
        return 'На жаль, у тебе поки немає можливості голосувати за інших гравців.'
      }
      return ''
    },
    votingSubMessage() {
      if (this.isYourRegistrationForm) {
        return "Сподіваємось, ти добрий і сподобався нашому ком'юніті! Проте голосування покаже."
      }
      if (!this.$can('player')) {
        return 'Ти поки що newbie на нашому проекті, і тобі самому потрібно пройти випробувальний період. Голосувати ти поки не можеш.'
      }
      if (this.isPreview) {
        return 'Дякуємо, що приділив час прочитанню анкети!'
      }
      return ''
    },

    votedPlayersApproved() {
      return this.votedPlayers.filter(p => p.status === 'APPROVED')
    },
    votedPlayersDeclined() {
      return this.votedPlayers.filter(p => p.status === 'DECLINED')
    },
    votedPlayersUndecided() {
      return this.votedPlayers.filter(p => p.status  === 'UNDECIDED')
    },
    votedPlayers() {
      return this.registrationForm.votes ?? []
    },

    isYourRegistrationForm() {
      return this.user.userName === this.$route.params.username
    },

    canVote() {
      return this.registrationForm.status === 'submitted' && !this.isYourRegistrationForm && this.$can('newbie')
    },

    canApprove() {
      return this.registrationForm.status === 'submitted' && this.$can("admin")
    },
    approvedRegistationFormMessageClass() {
      return (this.registrationForm.approvedBy.vote) ? 'voting-item__approved-message' : 'voting-item__declined-message'
    }
  },
  watch: {
    currentQuestionBlock(newVal) {
      let qbOffset = document.getElementById('questionBlock-0').offsetTop
      let headerHeight = document.getElementById('header').offsetHeight
      window.scrollTo(0, qbOffset - headerHeight - 16 + (newVal - 1) * 104);
    }
  },
  methods: {
    ...mapActions({
      getRegistrationForm: "registration/get",
      getProfile: "profile/get",
      submitRegistrationForm: "registration/submit",
      voteRegistrationForm: "registration/vote",
      approveRegistrationForm: "registration/approve",
      getNewRegistrationForm: "registration/new"
    }),

    getQuestionBlockTitle(index, title) {
      return `${ index + 1 } / ${ this.registrationForm.blocks.length } ${ title }`
    },

    updateQuestion(question, value) {
      question.update(value)
    },

    questionPattern(question) {
      if (question.tag === 'discordTag') {
        return '[0-9]{4}'
      }
      return null
    },

    async goToNextBlock() {
      if (this.registrationForm.blocks.length - 1 > this.currentQuestionBlock) {
        this.currentQuestionBlock += 1
      } else {
        if (this.registrationForm.status === 'created') {
          this.isRegistrationFormCreated = false
        }
        await this.submitRegistrationForm(this.registrationForm)
      }
    },

    goToPrevBlock() {
      this.currentQuestionBlock -= (this.currentQuestionBlock > 0) ? 1 : 0
    },

    async vote(value) {
      const voteParams = {
        userName: this.username,
        vote: value
      }
      await this.voteRegistrationForm(voteParams)
      this.registrationForm = this.form
    },

    async approveRegistration(value) {
      const voteParams = {
        userName: this.username,
        vote: value
      }
      await this.approveRegistrationForm(voteParams)
      this.registrationForm = this.form
    },

    hasVoted(vote) {
      if (!this.user?.realName) return false
      return this.registrationForm.votes
                                  .filter(v => v.status === vote)
                                  .map(v => v.realName)
                                  .includes(this.user.realName)
    },
  }
}
</script>
