export default class Payment {

  constructor(props) {
    this.id = props.id ?? ""
    this.personId = props.personId ?? ""
    this.creatorPersonId = props.creatorPersonId ?? ""
    this.date = new Date(props.date) ?? null

    this.paidMonth = null
    this.paidYear = null
    this.paidPeriod = new Date(props.paidPeriod) ?? null
    if (this.paidPeriod) {
      this.paidMonth = this.paidPeriod.getMonth()
      this.paidYear = this.paidPeriod.getFullYear()
    }
    this.amount = props.amount ?? 0
    this.currency = props.currency ?? "UAH"
    this.status = props.status ?? ""
    this.details = props.details ?? ""
    this.photos = props.photos ?? []
    this.canceled = props.canceled ?? false
    this.subscriptionLevel = props.subscriptionLevel ?? null
  }

  getJsonObj() {
    const data = {
      id: this.id,
      personId: this.personId,
      creatorPersonId: this.creatorPersonId,
      date: this.date,
      paidPeriod: this.paidPeriod,
      amount: this.amount,
      currency: this.currency,
      status: this.status,
      details: this.details,
      subscriptionLevel: this.subscriptionLevel,
    }
    return data
  }

  get isFilled() {
    return this.subscriptionLevel
  }

  get isPaid() {
    return this.status === "PAID" || this.status === "NONEED"
  }

  static statuses = {
    'NONEED': 'notRequired',
    'DEBT': 'delayed',
    'PAID': 'paid'
  }

  static currencies = ['UAH', 'RUB', 'USD']

  static create(props) {
    return new Payment(props)
  }
}