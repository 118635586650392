<template>
  <div class="container-fluid main-layout main-layout_no-margin page-not-found">
    <div class="row page-not-found-row">
      <div class="col">
        <div class="text-404">
          <p class="title-404">{{ errorCode }}</p>
          <p class="paragraph-404">{{ errorMessage }}</p>
        </div>
        <CustomButton isClear value="На головну?" @clicked="$router.push({name: 'main'})"/>
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from "@/components/CustomButton.vue"

import { mapGetters, mapActions } from 'vuex'

export default {
  name: "Error",
  components: {
    CustomButton
  },
  data() {
    return {
      title: "Error"
    }
  },
  computed: {
    ...mapGetters({
      error: "errors/error"
    }),

    errorMessage() {
      return this.error?.text ?? "Упс. Щось пішло не так"
    },

    errorCode() {
      return this.error?.code ?? 500
    },
  },
  methods: {
     ...mapActions({
      unsetError: "errors/unsetError"
    }),
  },
  beforeRouteLeave(to, from, next) {
    this.unsetError()
    next()
  }
}
</script>
