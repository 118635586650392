<template>
  <div class="custom-button">
    <button 
      class="button"
      :disabled="disabled"
      :class="[{
        button_clear : isClear,
        button_small : isSmall,
        button_borderless : withoutBorders,
        button_disabled : disabled
      }]"
      @click.prevent="clickedAction"
    >
      <template v-if="waitName && $wait.is(waitName)">
        <svg version="1.1" id="L4" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
          <circle fill="#fff" stroke="none" cx="0" cy="45" r="17">
            <animate
              attributeName="opacity"
              dur="1s"
              values="0;1;0"
              repeatCount="indefinite"
              begin="0.1"/>
          </circle>
          <circle fill="#fff" stroke="none" cx="50" cy="45" r="17">
            <animate
              attributeName="opacity"
              dur="1s"
              values="0;1;0"
              repeatCount="indefinite"
              begin="0.2"/>
          </circle>
          <circle fill="#fff" stroke="none" cx="100" cy="45" r="17">
            <animate
              attributeName="opacity"
              dur="1s"
              values="0;1;0"
              repeatCount="indefinite"
              begin="0.3"/>
          </circle>
        </svg>
      </template>
      <template v-else>
        {{value}}
      </template>
    </button>
  </div>
</template>

<script>

export default {
  name: 'CustomButton',
  components: {
  },
  props: {
    value: {
      type: String,
      required: true
    },
    isClear: {
      type: Boolean,
      required: false,
      default: false
    },
    isSmall: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    withoutBorders: {
      type: Boolean,
      default: false
    },
    waitName: {
      type: String,
      default: ""
    }
  },
  computed: {
    waiting() {
      if (!this.waitName) return false
      return false
    }
  },
  methods: {
    clickedAction() {
      this.$emit('clicked')
    }
  }
}
</script>