import Person from "@/models/Person"

export default class Profile {

  constructor(props) {
    this.set(props)
  }

  set(props) {
    this.createDefault()

    if (!props) {
      return
    }

    this.person = Person.create(props.person) ?? this.person
    this.characters = props.characters ?? this.characters
  }

  get registrationStatus() {
    return this.person?.registrationStatus ?? "created"
  }

  createDefault() {
    this.person = null
    this.characters = []
  }

  static create(props) {
    return new Profile(props)
  }
}