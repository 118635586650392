import axios from "axios"

import * as types from '../mutation_types'
import { apiUrl } from "@/config"

import Profile from "@/models/Profile"

const state = {
  profile: null,
  error: null,
  loginError: null,
}

const getters = {
  profile: (state) => state.profile,
  error: (state) => state.error,
  loginError: (state) => state.loginError,
}

const actions = {
  async get({ commit }, userName) {
    try {
      const { data } = await axios.get(apiUrl(`profile/${userName}`))
      commit(types.SET_PROFILE, Profile.create(data.data.profile))
      commit(types.SET_LOGIN_ERROR, null)
    } catch (e) {
      commit(types.SET_ERROR, e)
    }
  },

  async update({ commit }, payload) {
    try {
      await axios.put(apiUrl(`profile/${payload.id}`), payload)
    } catch (e) {
      commit(types.SET_ERROR, e)
    }
  },

  async addCharacter({ commit }, props) {
    try {
      const { data } = await axios.put(apiUrl(`profile/${props.personId}/add-character`), props)
      console.log(data)
      commit(types.UNSET_ERROR)
    } catch (e) {
      commit(types.SET_LOGIN_ERROR, e)
    }
  },

  async getSelf({ commit }) {
    try {
      const { data } = await axios.get(apiUrl('profile'))
      commit(types.SET_PROFILE, Profile.create(data.data.profile))
      commit(types.UNSET_ERROR)
    } catch (e) {
      commit(types.SET_ERROR, e)
    }
  },

  set({ commit }, profile) {
    commit(types.SET_PROFILE, profile)
  },

  clearLoginError({ commit }) {
    commit(types.SET_LOGIN_ERROR, null)
  },
}

const mutations = {
  [types.SET_PROFILE](state, profile) {
    state.profile = profile
  },

  [types.SET_ERROR](state, error) {
    state.error = error
  },

  [types.SET_LOGIN_ERROR](state, error) {
    state.loginError = error
  },

  [types.UNSET_ERROR](state) {
    state.error = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}