<template>
  <div class="custom-multiple-input">
    <div class="custom-input__item custom-input__title">
      <slot/>
      <span 
        v-if="optional"
        class="custom-input__optional-title"
      >
        {{ optionalTitle }}
      </span>
    </div>
    <template
      v-for="(option, index) in answers"
      :key="index"
    >
      <label :for="`custom-input__radio${id}-${index}`" :tabindex="0" class="custom-input__item custom-input__option">
        <input
          type="radio"
          :id="`custom-input__radio${id}-${index}`"
          :name="`custom-input__radio${id}`"
          :value="index"
          :tabindex="-1"
          :checked="option.isSelected"
          :disabled="disabled"
          v-model="selectedOptionIndex"
          class="custom-input__radio-input visually-hidden"
        >
        <span class="custom-input__radio"></span>
        <span class="custom-input__radio-label">{{ option.value }}</span>
      </label>
    </template>
  </div>
</template>

<script>

import debounce from "lodash/debounce"

export default {
  name: 'CustomMultiple',
  components: {
  },
  props: {
    id: {
      type: String,
      required: true
    },
    optional: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => { return [] }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      optionalTitle: " (не обов'язково)",
      selectedOptionIndex: null,
      answers: []
    }
  },
  created() {
    this.answers = this.options
    let selectedOptionIndex = this.options.findIndex((option) => option.isSelected)
    if (selectedOptionIndex != -1) { this.selectedOptionIndex = selectedOptionIndex}
  },
  watch: {
    selectedOptionIndex: debounce( function(newVal) {
      if (newVal === null) { return }
      this.$emit('onUpdate', newVal)
    }, 300)
  },
  methods: {
  }
}
</script>