<template>
  <div class="main-layout">
    <PageHeader :title="title"/>
    <div class="season-image"></div>
  </div>

  <div class="bg-end-noise">
    <div class="container omhms-content-container" id="first_anchor">
      <div class="row omhms-content-block ">
        <div class="col-lg-6 col-sm-12">
          <div class="col-2 omhms-content-block-decorline"></div>
          <span class="omhms-text omhms-text-h2-bold">OMHMS 5: "Перший сезон"</span>
          <div class="spacer-24"></div>
          <p class="omhms-text omhms-text-regular">Велике оновлення проекту OMHMS, розробка якого була почата ще в середині 2021 року. Майже повністю новий сервер розташувався тоді на трьох серверах, а зараз, <span class="omhms-text-bold">OMHMS – це вже п'ять серверів:</span></p>
          <p class="omhms-text-caption omhms-text-color-60"> 1. два <a href="#main" class="omhms-text-link">основні сервери</a> виживання;</p>
          <p class="omhms-text-caption omhms-text-color-60"> 2. власний <a href="#craetive" class="omhms-text-link">креатив</a>;</p>
          <p class="omhms-text-caption omhms-text-color-60"> 3. <a href="#minigames" class="omhms-text-link">севрер міні-ігр</a>;</p>
          <p class="omhms-text-caption omhms-text-color-60"> 4. безкоштовна <a href="#sandbox" class="omhms-text-link">"пісочниця"</a>.</p>
          <div class="row margin-top">
            <div class="col offset-md-1">
              <p class="omhms-text omhms-text-italic omhms-text-color-60">Через війну росії в Україні відкриття сервера відклалося майже на пів року, а <a target="_blank" href="/rules" class="omhms-text-link">правила</a> проекту було змінено під нові реалії. І, очевидно, проект діє виключно українською мовою!</p>
            </div>
          </div>
        </div>
        <div class="col-lg-5 offset-lg-1 offset-md-0 col-md-10 col-sm-12 margin-top">
          <div class="omhms-image" :data-pm='1.4'>
            <img
              id="img1"
              class="omhms-image-stroke"
              alt="Hello, there!"
              :src="require(`@/assets/images/season/01.jpg`)"
            />
          </div>
        </div>
      </div>
      <div id="main" class="row omhms-content-block">
        <div class="col-lg-6 offset-lg-1 margin-top">
          <div class="omhms-image" :data-pm="0.8">
            <img
            class="omhms-image-stroke"
            alt="Hello, there!"
            :src="require(`@/assets/images/season/02.jpg`)"
            />
          </div>
        </div>
        <div class="col-lg-4 offset-lg-1 ocb-padding-top-lg-0 ocb-padding-top margin-top">
          <div class="col-2 omhms-content-block-decorline"></div>
          <p class="omhms-text omhms-text-h2-regular">Основний сервер</p>
          <p class="omhms-text omhms-text-regular">Це звичний ваніальний майнкрафт, компактний але не тісний верхній світ, небезпечний Нижній світ та бездонний Край.</p>
          <p class="omhms-text omhms-text-regular">Інвентар та досягнення гравців будуть наскрізні через основний світ та світ ферм.</p>
          <p class="omhms-text omhms-text-regular">На основному сервері працюють всі ключові механіки гри без змін*.</p>
          <p class="omhms-text omhms-text-caption">*наскільки це можливо, бо ж ядро сервера – Paper</p>

        </div>
      </div>

      <div class="row omhms-content-block">
        <div class="col-xl-3 offset-xl-1 col-md-5 offset-md-1">
          <p class="omhms-text omhms-text-topcap">Основний сервер</p>
          <p class="omhms-text omhms-text-h2-regular">Верхній світ</p>
          <p class="omhms-text omhms-text-regular">На нульових координатах "пришвартована" повітряна куля, на якій згідно з лором, гравці перебралися на ці Нові землі.</p>
          <!-- <p class="omhms-text omhms-text-regular omhms-text-color-80">Деталі читайте в розділах сторінки <a class="omhms-text omhms-text-link" href="http://localhost:8080/about#PlayersAndGroups">Про сервер</a></p> -->
        </div>
        <div class="col-xl-3 offset-xl-1 col-md-5 offset-md-1 ocb-padding-top-50 ocb-padding-top-lg-100">
          <p class="omhms-text omhms-text-topcap">Основний сервер</p>
          <p class="omhms-text omhms-text-h2-regular">Нижній світ</p>
          <p class="omhms-text omhms-text-regular">Портал у Незер, що на спавні, перенесе гравця на ~нульові координати потойбіччя.</p>
          <p class="omhms-text omhms-text-regular">Тут буде розташований хаб із магістралями за основними напрямками світу. Але все залежить від самих гравців.</p>
        </div>
        <div class="col-xl-3 offset-xl-1 col-md-6 ocb-padding-top-50-lg">
          <p class="omhms-text omhms-text-topcap">Основний сервер</p>
          <p class="omhms-text omhms-text-h2-regular">Край</p>
          <p class="omhms-text omhms-text-regular">Портал у інший вимір буде встановлено неподалік повітряної кулі.</p>
        </div>
      </div>


      <div class="row omhms-content-block">
        <div class="col-lg-5 order-2 order-lg-1 ocb-padding-top-100 ocb-padding-top-lg-0">
          <div class="row">
            <div class="col-12">
              <div class="col-2 omhms-content-block-decorline"></div>
              <p class="omhms-text omhms-text-h2-regular">Сервер ферм</p>
              <p class="omhms-text omhms-text-regular">На цьому сервері ваш ТПС просідатиме (можливо), адже світи цього сервера спеціально створені для навантаження. Тут можна буде будувати великі ферми, які можуть навіть просаджувати ТПС (у розумних межах, звичайно).</p>
              <p class="omhms-text omhms-text-regular">Будувати домівки тут можна, але будьте готові до можливих проблем із ТПС.</p>
              <p class="omhms-text omhms-text-caption">Інвентар, ендер-чест та досягнення гравців будуть єдині для обох світів: для основного сервера та сервера ферм.</p>
              <p class="omhms-text omhms-text-caption">Чат – також єдиний на два світи.</p>
            </div>
          </div>
        </div>
        <div class="col-11 offset-1 col-lg-5 offset-lg-1 ocb-padding-top-lg-100 ocb-padding-top-0 order-1 order-lg-2">
          <div class="omhms-image" :data-pm="1.6">
            <img
            class="omhms-image-stroke"
            alt="Hello, there!"
            :src="require(`@/assets/images/season/03.jpg`)"
            />
          </div>
        </div>
      </div>
      <div class="row omhms-content-block">
        <div class="col-md-5 offset-md-1 ocb-padding-top-0">
              <p class="omhms-text omhms-text-topcap">Cервер ферм</p>
              <p class="omhms-text omhms-text-h2-regular">Верхній світ</p>
              <p class="omhms-text omhms-text-regular">На ~нульових координатах "пришвартований" повітряний корабель, за допомогою якого гравці поторожують між далекими землями двох світів.</p>
        </div>
        <div class="col-md-5 offset-md-1 ocb-padding-top-50">
          <p class="omhms-text omhms-text-topcap">Cервер ферм</p>
          <p class="omhms-text omhms-text-h2-regular">Нижній світ і Край</p>
          <p class="omhms-text omhms-text-regular">Щоб не порушувати механіку багатьох ферм, на сервері ферм присутній Нижній світ.</p>
          <p class="omhms-text omhms-text-regular">Край вимкнено.</p>
        </div>
      </div>

      <div id="creative"  class="row omhms-content-block">
        <div class="col-lg-5 col-md-9 ocb-padding-top-50">
          <div class="col-2 omhms-content-block-decorline"></div>
          <p class="omhms-text omhms-text-h2-regular">Сервер креатива</p>
          <p class="omhms-text omhms-text-regular">Цей світ поділений на квадрати, у кожному з яких буде щось. Що саме – залежить від самих гравців, адже кожен може зайняти свій квадрат (і не один) і творити там в креативі.</p>
          <p class="omhms-text omhms-text-caption">Чат і інвентар, звісно, окремі для цього світу :)</p>
        </div>
        <div class="col-lg-5 offset-lg-1 ocb-padding-top-50">
          <div class="omhms-image" :data-pm="0.5">
            <img
            class="omhms-image-stroke"
            alt="Подписка"
            :src="require(`@/assets/images/season/04.jpg`)"
            />
          </div>
        </div>
      </div>

      <div class="row omhms-content-block">
        <div id="minigames" class="col-lg-5 offset-lg-1 col-12">
          <div class="col-12 ocb-padding-top-50">
            <div class="col-2 omhms-content-block-decorline"></div>
            <p class="omhms-text omhms-text-h2-regular">Сервер міні-ігр</p>
            <p class="omhms-text omhms-text-regular">Власний сервер з міні-іграми.</p>
            <p class="omhms-text omhms-text-regular">Безкоштовний сервер із невеликим набором міні-ігр, в які можна зайти і пограти будь-коли і будь-з-ким!</p>
            <p class="omhms-text omhms-text-caption">Щоб потрапити на сервер міні-ігр, просто пишіть комаду <span class="omhms-text-console omhms-text-color-45">/lobby</span>, з будь-якого сервера ОМХМС!</p>
            <p class="omhms-text omhms-text-caption">Детальніше про сервер Міні-ігр – в нашій <a target="_blank" href="https://omhms.notion.site/Mini-games-4673f72158484af0bca8dc35550d04fe" class="omhms-text-link">Базі Знань</a>!</p>
          </div>
          <div class="col-lg-10 offset-lg-1 ocb-padding-top-50">
            <div class="omhms-image" :data-pm="0.5">
              <img
              class="omhms-image-stroke"
              alt="Подписка"
              :src="require(`@/assets/images/season/season_minigames.png`)"
              />
            </div>
          </div>
        </div>
        <div id="sandbox" class="col-lg-5 col-12 offset-lg-1 ocb-padding-top-50">
          <div class="col-lg-8 offset-lg-4 ocb-padding-top-50 order-lg-2 order-1">
            <div class="omhms-image" :data-pm="0.5">
              <img
              class="omhms-image-stroke"
              alt="Подписка"
              :src="require(`@/assets/images/season/season_sandbox.png`)"
              />
            </div>
          </div>
          <div class="col-10 offset-md-2 ocb-padding-top-50 order-lg-1 order-2">
            <div class="col-2 omhms-content-block-decorline"></div>
            <p class="omhms-text omhms-text-h2-regular">"Пісочниця"</p>
            <p class="omhms-text omhms-text-regular">Майданчик для творчості і РП, сервер із ухилом на івенти від гравців, для гравців!</p>
            <p class="omhms-text omhms-text-caption">Світ для тих, хто хоче пограти на ОМХМС, але з якихось причин ще не готовий купляти перепустку.</p>
            <p class="omhms-text omhms-text-caption">Детальніше про сервер-пісочницю – в нашій <a target="_blank" href="https://omhms.notion.site/Sandbox-75df97b917a841b59c6499cb327448cc" class="omhms-text-link">Базі Знань</a>!</p>
          </div>
        </div>
      </div>


    </div>

    <div class="container omhms-content-container">    

      <!-- Bedrock -->
      <div class="row omhms-content-block" id="bedrock">
        <div class="col-lg-6 offset-lg-1 offset-md-0 col-sm-12">
          <div class="col-2 omhms-content-block-decorline"></div>
          <p class="omhms-text omhms-text-topcap">Фішки сезону</p>
          <span class="omhms-text omhms-text-h2-bold">Minecraft Bedrock</span>
          <div class="spacer-24"></div>
          <p class="omhms-text omhms-text-regular">Якщо ви далеко від свого основного ігрового девайса (від ноута або комп'ютера) <span class="omhms-text omhms-text-bold">це не відмазка не грати на OMHMS!</span></p>
          <div class="row">
            <div class="col offset-md-1">
              <p class="omhms-text omhms-text-regular">Кожен гравець може прив'язати свій Bedrock акаунт до основного ігрового персонажа, і грати на OMHMS будь-де!</p>
              <p class="omhms-text omhms-text-regular">Як це зробити? Описано в <span class="omhms-text-console omhms-text-color-95">/help</span> у грі.</p>
              <p class="omhms-text omhms-text-caption">Правда, якщо чесно, битись із мобами на мобілці – таке собі задоволення.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 offset-lg-1 offset-md-0 col-md-10 col-sm-12 margin-top">
          <div class="omhms-image" :data-pm='1.7'>
            <img
              id="img1"
              class="omhms-image-stroke"
              alt="Hello, there!"
              :src="require(`@/assets/images/season/bedrock.jpg`)"
            />
          </div>
        </div>
      </div>
      <!-- END OF Bedrock -->


      <div class="row omhms-content-block" id="chat">

      <!-- Ingame chat -->
        <div class="col-lg-3 col-sm-12 ocb-padding-top-50">
          <div class="col-2 omhms-content-block-decorline"></div>
          <p class="omhms-text omhms-text-topcap">Фішки сезону</p>
          <span class="omhms-text omhms-text-h2-bold">Наскрізний ігровий чат</span>
          <div class="spacer-24"></div>
          <p class="omhms-text omhms-text-regular">Як було описано вище, на сервері.. декілька серверів! Але ігровий чат - наскрізний!</p>
          <p class="omhms-text omhms-text-caption omhms-color-80">Для креативу покищо чат окремий, але глянемо як там буде.</p>
        </div>
      <!-- END OF Ingame chat -->

      <!-- Voice chat -->
        <div class="col-lg-4 offset-1 col-sm-12 ocb-padding-top-50 ocb-padding-top-lg-0">
          <div class="col-2 omhms-content-block-decorline"></div>
          <p class="omhms-text omhms-text-topcap">Фішки сезону</p>
          <span class="omhms-text omhms-text-h2-bold">Войс-чат</span>
          <div class="spacer-24"></div>
          <p class="omhms-text omhms-text-regular">Погодьтесь, не завжди э можливість написати в чат. Саме для цього у нас є ДС сервер із голосовими каналами! Але якщо із якихось причин Ви не хочете сидіти у відкритому голосовому каналі – для вас є Войс чат на сервері!</p>
          <div class="row">
            <div class="col offset-md-1">
              <p class="omhms-text omhms-text-bold omhms-text-color-80">Щоб працювало: <span class="omhms-text omhms-text-regular">необхідно поставити собі <a class="omhms-text-link" href="https://www.curseforge.com/minecraft/mc-mods/plasmo-voice" target="_">Plasmo-Voice mod</a>.</span></p>
            </div>
          </div>
        </div>
      <!-- END OF Voice chat -->


      <!-- Discord chat -->
        <div class="col-lg-3 col-sm-12 offset-lg-1 offset-sm-0 ocb-padding-top-50">
          <div class="col-2 omhms-content-block-decorline"></div>
          <p class="omhms-text omhms-text-topcap">Фішки сезону</p>
          <span class="omhms-text omhms-text-h2-bold"><a class="omhms-text-link__discord" href="https://discord.gg/5HugZgxgZz" target="_blank">Discord</a>-чат</span>
          <div class="spacer-24"></div>
          <p class="omhms-text omhms-text-regular"><span class="omhms-text-bold">Завжди залишайтесь на зв'язку із гравцями</span>, оскільки на нашому діскорді є наскрізні канали чату пов'язані із сервером. Пишіть в обох напрямах, з гри в діскорд і навпаки.</p>
        </div>
      <!-- END OF Discord chat -->


      </div>
    </div>

    <template v-if="!check">  
      <div class="container-fluid omhms-content-container-dark">
        <div class="row omhms-content-block omhms-content-block-cta">
          <div class="col-12">
            <p class="omhms-text omhms-text-h2-regular">Вже готовий вриватися у проект?</p>
            <p class="omhms-text omhms-text-regular">Реєструйся, заповнюй просту форму та швидше приєднуйся до OMHMS!</p>
            <CustomButton value="ЗАПОВНИТИ АНКЕТУ" @clicked="$router.push('profile')"/>
          </div>
        </div>
      </div>
    </template>

    <div class="container omhms-content-container"> 

      <!-- FEATURE NEWBIE -->
      <div class="row omhms-content-block" id="feature-slaves">
        <div class="col-lg-3 offset-lg-2 offset-md-0 col-md-10 col-sm-12">
          <div class="omhms-image" :data-pm='1.2'>
            <img
              id="img1"
              class="omhms-image-stroke"
              alt="Hello, there!"
              :src="require(`@/assets/images/season/feature-myPesduk.jpg`)"
            />
          </div>
        </div>
        <div class="col-lg-5 offset-lg-1 col-sm-12  margin-top">
          <div class="col-2 omhms-content-block-decorline"></div>
          <p class="omhms-text omhms-text-topcap">Фішки сезону</p>
          <span class="omhms-text omhms-text-h2-bold">Гравець-"Новачок"</span>
          <div class="spacer-24"></div>
          <p class="omhms-text omhms-text-regular">Кожен <span class="omhms-text omhms-text-bold omhms-text-color-server_player omhms-text__player-player">гравець</span>, відповідно до <a target="_blank" class="omhms-text omhms-text-link" href="./subscription">рівня своєї підписки</a>, може запросити в проект <span class="omhms-text omhms-text-bold omhms-text-color-server_newbie omhms-text__player-newbie">"новачка"</span>.</p>
          <p class="omhms-text omhms-text-regular"><span class="omhms-text omhms-text-bold omhms-text-color-server_newbie omhms-text__player-newbie">Новачок</span> може грати у виживанні лише на обмеженій території, яку зайняв і відмітив <span class="omhms-text omhms-text-bold omhms-text-color-server_player omhms-text__player-player">гравець</span> який запрошує "новачка". Поза цією територію режим гри буде змінено на Adventure.</p>
          <p class="omhms-text omhms-text-regular">В <a target="_blank" class="omhms-text omhms-text-link" href="./subscription#subscriptions">Магазині</a> ліміт новачків позначено двома цифрами через прямий слеш: <span  class="omhms-text omhms-text-console">2/2</span>. Перше число означає кількість "новачків" яку гравець може запросити купуючи перепустку за реальні гроші, друге – за серверну валюту.</p>
          <p class="omhms-text omhms-text-caption">Ціну перепустки для Новачка можна подивитись в Магазині.</p>
          <p class="omhms-text omhms-text-caption">Детальніше, читайте в нашій Базі знань про <a href="https://omhms.notion.site/c8fb8b0e3e0148a68355a50b94f99e1a" target="_blank" class="omhms-text omhms-text-caption omhms-text-link">Групи гравців</a>.</p>
        </div>
      </div>
      <!-- END OF FEATURE NEWBIE -->   

      <!-- FEATURE PORTALS -->
      <div class="row omhms-content-block" id="feature-portals">
        <div class="col-lg-7 col-sm-12">
          <div class="col-2 omhms-content-block-decorline"></div>
          <p class="omhms-text omhms-text-topcap">Фішки сезону</p>
          <span class="omhms-text omhms-text-h2-bold">Приватні портали</span>
          <div class="spacer-24"></div>
          <p class="omhms-text omhms-text-regular">В центрі нашого світу є два портали що ведуть на світ Фабрик та Креатив, віповідно. В інших світах є зворотні портали (логічно, правда?). За допомогою цих порталів гравці можеть подорожувати між світами. <span class="omhms-text omhms-text-bold">Але що, якщо вам необхідно звести свій портал?</span></p>
          <div class="row">
            <div class="col offset-md-1">
              <p class="omhms-text omhms-text-regular">Кожен гравець, відповідно до <a target="_blank" class="omhms-text omhms-text-link" href="./subscription">рівня своєї підписки</a>, може запросити будівництво свого приватного порталу.</p>
              <p class="omhms-text omhms-text-regular">Чи то гравець такий лінивий, що не хоче бігти через пів світу в Центр, або ж гравець будує віддалене поселення і бажає провести швидкий портал до світу Фабрик. Причина може бути будь-яка, можливість - є.</p>
              <p class="omhms-text omhms-text-caption">Відповідно до ліміту підписки, гравець отримує безлімітну підтримку по супроводу та обслуговуванню портала. Треба перенести трохи лівіше? Без проблем. Можливо портал взагалі має вести в якесь інше, нове місце? Нема питань. Але така процедура має бути оговорена задалегідь.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 offset-lg-1 offset-md-0 col-md-10 col-sm-12 margin-top">
          <div class="omhms-image" :data-pm='1.7'>
            <img
              id="img1"
              class="omhms-image-stroke"
              alt="Hello, there!"
              :src="require(`@/assets/images/season/feature-portals.jpg`)"
            />
          </div>
        </div>
      </div>
      <!-- END OF FEATURE PORTALS -->

      <!-- FEATURE PLOTS -->
      <div class="row omhms-content-block" id="feature-plots">
        <div class="col-lg-4 col-md-10 col-sm-12 order-lg-1 order-2 ocb-padding-top-50">
          <div class="omhms-image" :data-pm='0.6'>
            <img
              id="img1"
              class="omhms-image-stroke"
              alt="Hello, there!"
              :src="require(`@/assets/images/season/feature-plots.jpg`)"
            />
          </div>
        </div>
        <div class="col-lg-7 col-sm-12 offset-lg-1 offset-md-0 order-lg-2 order-1">
          <div class="col-2 omhms-content-block-decorline"></div>
          <p class="omhms-text omhms-text-topcap">Фішки сезону</p>
          <span class="omhms-text omhms-text-h2-bold">Plot'и на креативному сервері</span>
          <div class="spacer-24"></div>
          <p class="omhms-text omhms-text-regular">На креативному сервері купа квадратів. Безліч. Весь світ із квадратів 56х56 блоків. Мінімальнй ліміт плотів (згідно <a target="_blank" class="omhms-text omhms-text-link" href="./subscription">рівня підписки</a>) до приватизації - 4, а це 8х8 чанків креативного простору. Гадаємо, цілком достатньо аби мати собі клаптик землі з креативом, так?</p>
          <div class="row">
            <div class="col offset-md-1">
              <p class="omhms-text omhms-text-regular">Детальніше про нюанси використання та роботу із плотами можна буде прочитати в ігровому меню: <span class="omhms-text-console omhms-text-color-95">/help</span></p>
              <p class="omhms-text omhms-text-caption">Оскільки наш сервер позиціонується як сервер максимально без обмежень, ліміт на кілкість плотів є відносно умовним. Якщо гравець будує якийсь величезний проект, то під цей проект може бути виділено окрему ділянку в креативному світі, поза лімітом підписки. Але для такого <i>жесту доброї волі</i> необхідно буде аргументувати та довести необхідність окремої креативної ділянки. А вмовити Одмєна щось робити понаднормово досить важко, бо це ж йому із цим поратись, а не тобі.. </p>
            </div>
          </div>
        </div>
      </div>
      <!-- END OF FEATURE PLOTS -->

      <!-- FEATURE HOLOGRAMS -->
      <div class="row omhms-content-block" id="feature-holograms">
        <div class="col-lg-7 col-sm-12">
          <div class="col-2 omhms-content-block-decorline"></div>
          <p class="omhms-text omhms-text-topcap">Фішки сезону</p>
          <span class="omhms-text omhms-text-h2-bold">Голограми</span>
          <div class="spacer-24"></div>
          <p class="omhms-text omhms-text-regular">Ви вже могли бачити голограми на сервері: просто інформативні або на порталах. <span class="omhms-text omhms-text-bold">Хочете таку саму собі?</span> Ізі.</p>
          <div class="row">
            <div class="col offset-md-1">
              <p class="omhms-text omhms-text-regular">Відповідно до <a target="_blank" class="omhms-text omhms-text-link" href="./subscription">рівня своєї підписки</a>, гравці можуть створити собі голорграми різного ступеня складності.</p>
              <p class="omhms-text omhms-text-regular">Найпростіший варіант – створити запит на голограму до адміністрації, та чекати. Складний спосіб, але правильніший, дослідити <a target="_blank" class="omhms-text omhms-text-link"  href="https://wiki.decentholograms.eu/">вікі</a> плагіну, та прийти до Одмєна із більше конкретним запитом.</p>
              <p class="omhms-text omhms-text-caption">Відповідно до ліміту підписки, гравець отримує безлімітну підтримку по супроводу та обслуговуванню голограм. Треба змінити текст, перенести трохи правіше? Без проблем. Але така процедура має бути оговорена задалегідь.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 offset-lg-1 offset-md-0 col-md-10 col-sm-12 margin-top">
          <div class="omhms-image" :data-pm='1.1'>
            <img
              id="img1"
              class="omhms-image-stroke"
              alt="Hello, there!"
              :src="require(`@/assets/images/season/feature-holograms.jpg`)"
            />
          </div>
        </div>
      </div>
      <!-- END OF FEATURE HOLOGRAMS -->

      <!-- FEATURE CUSTOM ITEMS -->
      <div class="row omhms-content-block" id="feature-custom-items">
        <div class="col-lg-3 col-md-10 col-sm-12 order-lg-1 order-2">
          <div class="omhms-image" :data-pm='1.8'>
            <img
              id="img1"
              class="omhms-image-stroke"
              alt="Hello, there!"
              :src="require(`@/assets/images/season/feature-custom-items.jpg`)"
            />
          </div>
        </div>
        <div class="col-lg-8 col-sm-12 offset-lg-1 offset-md-0 order-lg-2 order-1">
          <div class="col-2 omhms-content-block-decorline"></div>
          <p class="omhms-text omhms-text-topcap">Фішки сезону</p>
          <span class="omhms-text omhms-text-h2-bold">Вишукані штучки</span>
          <div class="spacer-24"></div>
          <p class="omhms-text omhms-text-regular">Привілеї на отримання іменних грамот, чи освячення (зачарування) предметів, зброї чи броні, ексклюзивний лор – все це доступне гравцям відповідно до їх <a target="_blank" class="omhms-text omhms-text-link" href="./subscription">рівня підписки</a>.</p>
          <div class="row">
            <div class="col offset-md-1">
              <p class="omhms-text omhms-text-regular">Раз на місяць гравці зможуть зачаровувати предмети на певні зачарування, або додавати ексклюзивний лор на свої предмети. Планується обговорення цього в особистому порядку. Всі деталі – згодом, та/або вже на самому сервері.</p>
              <p class="omhms-text omhms-text-caption">Оскільки наш сервер позиціонується як сервер із рівними можливостями для всіх, можливість отримати кастомні унікальні предмети не є вийнятком. Якщо гравець займається роле-плеєм, і за історією, наприклад, коваль – він може запросити у адміністрації допомогу по створенню унікльнимх предметів саме в його кузні. Що саме? А от вже думайте, як саме ви можете створити щось "унікальне", що для цього потрібно і взагалі навіщо це треба. Зауважимо, що для реалізації, ідею необхідно буде аргументувати і узгодити з Одмєном.</p>
            </div>
          </div>
        </div>
      </div>
      <!-- END OF FEATURE CUSTOM ITEMS -->

      <!-- FEATURE FLAGS -->
      <div class="row omhms-content-block" id="feature-flags">
        <div class="col-lg-5 offset-md-1 col-sm-12">
          <div class="col-2 omhms-content-block-decorline"></div>
          <p class="omhms-text omhms-text-topcap">Фішки сезону</p>
          <span class="omhms-text omhms-text-h2-bold">Стяги на веб-мапі</span>
          <div class="spacer-24"></div>
          <p class="omhms-text omhms-text-regular">Інформативні стяги прямо на <a  target="_blank" class="omhms-text omhms-text-link" href="./map">нашій веб-мапі</a>!</p>
          <p class="omhms-text omhms-text-regular">Відповідно до <a target="_blank" class="omhms-text omhms-text-link" href="./subscription">рівня своєї підписки</a>, гравці можуть розмістити на веб-мапі свої загальнодоступні маркери.</p>
          <p class="omhms-text omhms-text-caption">Стяги-маркери на суспільних проектах, на загальнодоступних фермах та в будь-яких інших громадських місцях не завжди будуть "тарифікуватись" комусь із гравців. Це ж громадські місця :)</p>
          <div class="row">
            <div class="col offset-md-1">
              <p class="omhms-text omhms-text-regular">Як таке зробити? Створити запит на стяг до адміністрації в Діскорді, в каналі <span class="omhms-text-console omhms-text-color-95">#help</span>. В запиті необхідно прописати координати місця, світ, та бажану назву та колір стягу на веб-мапі.</p>
              <p class="omhms-text omhms-text-caption">Відповідно до ліміту підписки, гравець отримує безлімітну підтримку по супроводу та обслуговуванню маркерів. Але пам'ятайте, зруйнувавши стяг в грі, зникне і маркер з веб-мапи.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 offset-lg-1 offset-md-0 col-md-10 col-sm-12 margin-top">
          <div class="omhms-image" :data-pm='1.1'>
            <img
              id="img1"
              class="omhms-image-stroke"
              alt="Hello, there!"
              :src="require(`@/assets/images/season/feature-flag.jpg`)"
            />
          </div>
        </div>
      </div>
      <!-- END OF FEATURE FLAGS -->

      <!-- FEATURE HONOR AND SUGGESTIONS -->
      <div class="row omhms-content-block" id="feature-palce-of-honor-and-suggestions">
        <div class="col-lg-4 col-sm-10 offset-lg-1 offset-sm-2 ocb-padding-top-sm-0 ocb-padding-top-lg-50">
          <div class="col-2 omhms-content-block-decorline"></div>
          <p class="omhms-text omhms-text-topcap">Фішки сезону</p>
          <span class="omhms-text omhms-text-h2-bold">Почесне місце</span>
          <div class="spacer-24"></div>
          <p class="omhms-text omhms-text-regular">Гравці, що внесли особливий вклад в проект будуть відмічені на спеціальній сторінці на сайті. Слава їм і хвала.</p>
          <p class="omhms-text omhms-text-regular">Чи то був великий проект, грандіозна побудова, або ж крутий івент, чи просто гравець був активним членом спільноти і заслугову на пошану – заслужити своє Почесне місце можнарізними шляхами.</p>
          <div class="row">
            <div class="col offset-md-1">
              <p class="omhms-text omhms-text-regular">Такий розділ сайт буде називатись <span class="omhms-text omhms-text-bold">Зала слави</span>.</p>
              <p class="omhms-text omhms-text-caption">Покищо, в розробці, але згадки про ньго будуть як на сайті, так і на сервері. Посиланн буде в основному меню, на видному місці.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-sm-12 offset-lg-1 offset-md-0 margin-top ocb-padding-top-lg-50">
          <div class="col-2 omhms-content-block-decorline"></div>
          <p class="omhms-text omhms-text-topcap">Фішки сезону</p>
          <span class="omhms-text omhms-text-h2-bold">Пропозиції гравців</span>
          <div class="spacer-24"></div>
          <p class="omhms-text omhms-text-regular">Олди проекту не дадуть збрехати, проект OMHMS завжди був відкритим до пропозицій і зауважень з боку гравців. OMHMS – це перш за все спільнота, і сервер має бути зручний для цієї спільноти.</p>
          <p class="omhms-text omhms-text-regular">Гравцями є різні люди, з різними звичками та досвідом гри. <span class="omhms-text omhms-text-bold">Немає "ідеального" серверу, але є той, який мона підлаштувати під себе. Саме це і можуть робити гравці на OMHMS.</span></p>
          <div class="row">
            <div class="col offset-md-1">
              <p class="omhms-text omhms-text-regular">Гравці із найбільшим рівнем підписки, або ж гравці, які "вкладають" себе в проект більше за інших, мають вищий приоритет при розгляді якихось ідей чи пропозицій.</p>
              <p class="omhms-text omhms-text-caption">Ідеї, пропозиції, зауваження, заперечення – все це має місце, і все це має обговорюватись як у спільноті, так і окремо з адміністрацією. Ми можемо побудувати такий сервер, який завтра зможе бути кращим ніж вчора.</p>
            </div>
          </div>
        </div>
      </div>
      <!-- END OF FEATURE HONOR AND SUGGESTIONS -->

      <!-- FEATURE MERCH -->
      <div class="row omhms-content-block" id="feature-merch">
        <div class="col-lg-5 col-md-10 col-sm-12 order-lg-1 order-1">
          <div class="omhms-image" :data-pm='0.8'>
            <img
              id="img1"
              class="omhms-image-stroke"
              alt="Hello, there!"
              :src="require(`@/assets/images/merch/2022/03.jpg`)"
            />
          </div>
          <div class="omhms-image margin-top" :data-pm='0.4'>
            <img
              id="img1"
              class="omhms-image-stroke"
              alt="Hello, there!"
              :src="require(`@/assets/images/season/feature-merch.jpg`)"
            />
          </div>
          <div class="row">
            <div class="col-10 col-lg-8 offset-md-2">
              <p class="omhms-text omhms-text-caption omhms-text-color-60"><i>Я до сих пір зберіг відкриточку що мені колись надіслав <strong>Ваня Orphey</strong>. © Одмєн</i></p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-sm-12 offset-lg-1 offset-md-0 order-lg-1 order-2 margin-top">
          <div class="col-2 omhms-content-block-decorline"></div>
          <p class="omhms-text omhms-text-topcap">Фішки сезону</p>
          <span class="omhms-text omhms-text-h2-bold">Мерч та irl-продукція</span>
          <p class="omhms-text omhms-text-caption"><i>Актуально лише для гравців із України</i></p>
          <div class="spacer-8"></div>
          <CustomButton value="Переглянути доступну продукцію" @clicked="$router.push('merch')"/>
          <div class="spacer-24"></div>
          <p class="omhms-text omhms-text-regular">Гадаємо це круто, коли проект може собі дозволити виробляти мерч для того аби віддячити гравцям та членам спільноти за їх вклад. Саме це і буде відбуватись із донаторами на проекті OMHMS.</p>
          <p class="omhms-text omhms-text-caption">Прототипом та першим привілеєм стала роль <span class="omhms-text-color-server_rubicon">Рубіконців</span> – гравців, що грали в проекті більше 1 року. Зараз такий привілей вже не доступний, проте є інші – кращі і "реальніщі".</p>
          <div class="row">
            <div class="col offset-md-1">
              <p class="omhms-text omhms-text-regular">В планах розробити унікальну футболку із логотипом OMHMS та друкувати нік-нейм гравця на ній. Такий індивідуальний подарунок це ж каєф, скажіть? Окрім футболок, в шафі із ідеями також є різного роду фото-картки, книги, журнальчики, прості магніти та інші дрібниці, що зможуть радувати власників і нагадувати про чудово проведений час.</p>
              <p class="omhms-text omhms-text-caption">Але, ці ідеї поки в розробці. Приорітет – сервер, а потім irl мерч, та інші приколяси.</p>
            </div>
          </div>
        </div>
      </div>
      <!-- END OF FEATURE MERCH -->

      <!-- DISCLAIMER -->
      <div class="row omhms-content-block justify-content-center" id="feature-disclaimer">
        <div class="col-xl-5 col-sm-8 margin-top center-block text-center justify-content-center">
          <p class="omhms-text omhms-text-topcap">Дисклеймер</p>
          <p class="omhms-text omhms-text-caption  omhms-text-color-80">Більшість ігрових фішок не є забороненими для тих, хто не придбав відповідний рівень перепустки де ці фішки є за замовчуванням. Гравці, які придбали вищій рівень підписки мають спрощену систему доступу до ігрових опцій. </p>
          <p class="omhms-text omhms-text-caption">Наш сервер позиціонується як сервер максимально без обмежень і ліміти на ігрові фішки є відносно умовним. Вклад часу у проект навіть більше важливий аніж грошові донати.</p>
        </div>
      </div>
      <!-- END OF DISCLAIMER -->

    </div>

  </div>




</template>

<script>
import PageHeader from "@/components/PageHeader"
import CustomButton from "@/components/CustomButton.vue"
import { mapGetters } from 'vuex'

export default {
  name: 'CurrentSeason',
  components: {
    PageHeader,
    CustomButton
  },
  props: {
  },
  data() {
    return {
      title: "Поточний сезон"
      
    }
  },
  mounted() {
    this.images = document.getElementsByClassName("omhms-image");
    this.customTopOffset = -50;
    this.$isMobile() ? this.mobileMultiplier = 0.5 : this.mobileMultiplier = 1
    window.addEventListener('scroll', this.parallaxTheMainImages);
  },
  unmounted() {
    window.removeEventListener('scroll', this.parallaxTheMainImages);
  },
  computed: {
    ...mapGetters({
      check: "auth/check"
    })
  },
  methods: {
    parallaxTheMainImages() {
      // console.log("i'm working")
      console.log(this.$isMobile() + " " + this.mobileMultiplier);
      for (var i = 0; i < this.images.length; i++) {
        var top = this.images[i].getBoundingClientRect().top;
        if (top > -500 & top < 1000) {
          this.images[i].style.top = (this.customTopOffset + top/10 * this.images[i].dataset.pm) * this.mobileMultiplier + "px";
        }
      }
    }
  }
}
</script>