import store from "@/store/index"

const notification = {
  eventType: "notification",
  handle: function (event) {
    const data = JSON.parse(event.data)
    store.dispatch("rcon/consoleUpdate", data)
  }
}

export default notification
