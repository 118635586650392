import { ifUrlExist } from '@/helpers'

export default () => {
  if (!process.env.VUE_APP_MAIN_FRONT_ENDPOINT || window.location.origin === process.env.VUE_APP_MAIN_FRONT_ENDPOINT) {
    return
  }

  ifUrlExist(process.env.VUE_APP_MAIN_FRONT_ENDPOINT, (res) => {
    if (res) {
      window.location.href = process.env.VUE_APP_MAIN_FRONT_ENDPOINT + window.location.pathname
      return
    }
  })
}
