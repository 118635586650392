import axios from "axios"
import store from "@/store"
import router from "@/router/index"

axios.interceptors.request.use((request) => {
  const user = store.getters["auth/user"]
  if (user && user.userName) {
    request.headers.common["UserName"] = user.userName
  }
  const token = store.getters["auth/token"]
  if (token) {
    request.headers.common["Authorization"] = token
  }

  return request
})

axios.interceptors.response.use((response) => {
    return response
  },
  (error) => {
    if (!error.response) {
      // No connection
      store.dispatch("errors/setError", {text: "Сервіс офлайн, спробуй пізніше", code: 500})
      router.push({ name: "error" })

    }

    return Promise.reject(error)
  }
)