import TextQuestion from "@/models/questions/TextQuestion"
import MultipleQuestion from "@/models/questions/MultipleQuestion"
import Question from "@/models/questions/Question"

export default class QuestionBlock {
  constructor(props) {
    this.title = props.title
    this.questions = props.questions.map(q => {
      switch (q.type) {
        case 'text': 
          return new TextQuestion(q)
        case 'multiple':
          return new MultipleQuestion(q)
        default:
          return new Question(q)
      }
    })
    this.note = props.note
  }

  getJsonObj() {
    return {
      title: this.title,
      note: (this. note) ? {
        title: this.note.title ?? "",
        text: this.note.text ?? ""
      } : {},
      questions: this.questions.map(q => {
        let question
        switch (q.type) {
          case 'text': 
            question = new TextQuestion(q)
            break
          case 'multiple':
            question = new MultipleQuestion(q)
            break
          default:
            question = new Question(q)
            break
        }
        return question.getJsonObj()
      })
    }
  }

  get isContactsBlock() {
    return this.questions.map(q => q.tag).includes("email")
  }

  isValid() {
    return this.questions
      .filter( (q) => !q.optional )
      .filter( (q) => !q.hasAnswer() )
      .length === 0;
  }
}
