<template>
  <div class="main-layout">
    <PageHeader :title="title"/>

    <div v-if="$can('admin')" class="container omhms-content-container">
      <div class="row omhms-content-block">
        <p class="omhms-text omhms-text-h4-regular">Фільтри:</p>
        <div class="row">
          <PlayersFilter
            :value="filters.registrationStatus"
            :options="registrationStatusFilters"
            title="Статус анкети:"
            item-class="label-reg"
            @updateValue="onFilterUpdate('registrationStatus', $event)"
          />

          <PlayersFilter
            :value="filters.playerGroup"
            :options="playerGroupFilters"
            title="Група гравця:"
            item-class="label-group"
            @updateValue="onFilterUpdate('playerGroup', $event)"
          />

          <PlayersFilter
            :value="filters.subscriptionLevel"
            :options="subscriptionLevelFilters"
            title="Рівень підписки:"
            item-class="label-sub"
            @updateValue="onFilterUpdate('subscriptionLevel', $event)"
          />

          <PlayersFilter
            :value="filters.lastSubscription"
            :options="lastSubscriptionFilters"
            title="Підписка за..."
            item-class="label-month-default "
            :allow-empty="true"
            @updateValue="onFilterUpdate('lastSubscription', $event)"
          />
        </div>
      </div>
      <div class="row omhms-content-block">
        <div class="players-header row">
          <p class="col-auto omhms-text omhms-text-h4-regular">Список гравців проекту:</p>
          <div class="col-auto players-header__stats">
            <span  
              v-for="(tier, index) in subscriptionLevelFilters.slice().reverse()"
              :key="index"
            >
              <span :class="`label-sub-${tier.id}`"> {{tier.title }} </span>
              <span class="omhms-text-caption"> x{{ stats[tier.id] }}</span>
            </span>
          </div>
        </div>
        <p v-if="hasFiltered && filteredPlayers.length === 0">По таким фільтрам не знайшлося нікого</p>
        <router-link
            v-for="player in filteredPlayers"
            :key="player.id"
            :to="{name: 'profile', params: { username: player.mainCharacter }}"
            class="col-12 players-card"
        >
          <!-- <span class="omhms-text omhms-text-bold">{{ player }}</span> -->
          <div class="row">
            <div class="col-xxl-3 col-md-6 col-12 order-first">
              <span class="omhms-text omhms-text-caption">{{ player.id }}</span>
              <span :id="`user-name-${player.id}`" class="omhms-text omhms-text-regular">{{ player.realName }}</span>
              <span :id="`user-username-${player.id}`" class="omhms-text omhms-text-bold">{{ player.mainCharacter }}</span>
            </div>
            <div class="col-xxl col-auto  order-2 omhms-text omhms-text-regular omhms-text-color-60">
              <!-- <span class="hideOnSmall">Анкета:</span> -->
              <span :id="`user-reg-${player.id}`" class="label-reg-approved" :style="{ color: registrationStatusColor(player), borderColor: registrationStatusColor(player)}">
                    {{ registrationStatusTitle(player) }}
              </span>
            </div>
            <div class="col-xxl col-auto order-3 omhms-text omhms-text-regular omhms-text-color-60">
              <!-- <span class="hideOnSmall">Група:</span> -->
              <span
                id="user-group"
                class="label-group-player"
                :class="`omhms-text__player-${player.userGroup.toLowerCase()}`"
              >{{ firstLetterCapitalized(player.userGroup) }}</span>
            </div>
            <div class="col-xxl col-auto order-4 omhms-text omhms-text-regular omhms-text-color-60">
              <!-- <span class="hideOnSmall">Підписка:</span> -->
              <span :id="`user-sub-${player.id}`" :class="getUserTierClass(player)">{{ player.subscriptionLevel.label }}</span>
            </div>
            <div class="col-xxl-4 col-lg-6 col-12 order-last order-lg-1 order-xxl-4 omhms-text omhms-text-regular omhms-text-color-60">
              <Calendar
                :years="[2023, 2024]"
                :payments="player.payments"
                :show-legend="false"
              />
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <div v-else style="text-align: center;">
      <img :src="require(`@/assets/images/omhms_403.jpg`)" class="map__img">
      <p class="map__paragraph">Соррі</p>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader"
import PlayersFilter from "@/components/PlayersFilter"
import Calendar from "@/components/paymentCalendar/PaymentCalendar"

import { mapGetters, mapActions } from 'vuex'
import { firstLetterCapitalized } from "@/helpers"
export default {
  name: 'Players',
  components: {
    PageHeader,
    Calendar,
    PlayersFilter,
  },
  data() {
    return {
      title: "Гравці проекту",
      filters: {
        registrationStatus: ["all"],
        playerGroup: ["all"],
        subscriptionLevel: ["all"],
        lastSubscription: [],
      },
      lastSubscriptionFilters: [
        {
          id: "currentMonth",
          title: "Поточний місяць",
        },
        {
          id: "prevMonth",
          title: "Минулий місяць",
        },

      ],
      registrationStatusFilters: [
        {
          id: "created",
          title: "Не створена",
        },
        {
          id: "submitted",
          title: "Розглядається",
        },
        {
          id: "approved",
          title: "Опрацьована",
        },
        {
          id: "declined",
          title: "Відхилена",
        },
      ],
      playerGroupFilters: [
        {
          id: "default",
          title: "Default",
        },
        {
          id: "newbie",
          title: "Newbie",
        },
        {
          id: "player",
          title: "Player",
        },
        {
          id: "admin",
          title: "Admin",
        },
      ],
      subscriptionLevelFilters: [
        {
          id: "tier0",
          title: "Немає",
        },
        {
          id: "tier1",
          title: "Звичайна",
        },
        {
          id: "tier2",
          title: "Потужна",
        },
        {
          id: "tier3",
          title: "Могутня",
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      players: "players/players",
    }),
    stats() {
      return Object.fromEntries(this.subscriptionLevelFilters.map(f => [f.id, this.filteredPlayers.filter(p => f.id === `tier${p.subscriptionLevel.value ?? 0}`).length]))
    },
    filteredPlayers() {
      return this.players
        .filter((player) => player.matchesFilters(this.filters))
    },
    hasFiltered() {
      return !this.filters.registrationStatus.includes("all") ||
            !this.filters.playerGroup.includes("all") ||
            !this.filters.subscriptionLevel.includes("all") ||
            this.filters.lastSubscription.length > 0
    },
  },
  created() {
    this.getPlayers()
  },
  methods: {
    ...mapActions({
      getPlayers: "players/get",
    }),

    registrationStatusTitle(profile) {
      switch (profile?.registrationStatus) {
        case 'submitted':
          return 'На розгляді'
        case 'approved':
          return 'Опрацьована'
        case 'declined':
          return 'Відхилена'
        default:
          return 'Не створена'
      }
    },

    getUserTierClass(player) {
      return player.subscriptionLevel.value ? `label-sub-tier${player.subscriptionLevel.value}` : `label-sub-tier0`
    },

    registrationStatusColor(profile) {
      switch (profile?.registrationStatus) {
        case 'submitted':
          return '#F2994A'
        case 'approved':
          return '#27AE60'
        default:
          return '#EB5757'
      }
    },

    onFilterUpdate(field, value) {
      this.filters[field] = value
    },

    firstLetterCapitalized,
  }
}
</script>

