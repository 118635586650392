<template>
  <div class="main-layout">
    <PageHeader :title="title"/>

 <!--    <div class="container-fluid omhms-content-container-dark"> 
      <div class="container omhms-content-container ">

        <div class="row omhms-content-block justify-content-center">
          <div class="col-lg-8 col-md-10 col-12">
            <div class="spacer-24"></div>
            <p class="omhms-text omhms-text-italic">"Есть несколько идей по поводу будущего всего сервера, но основное направление движения уже выбрано. Самое важное то, что я не хотел бы заниматься сервером в одиночку, а это вообще такая-себе затея. Что это значит? Я хотел бы видеть поддержку или какое-то желание от тех людей, которые будут играть у нас. От вас, ребята, тех, кто читает этот текст! И хочу я этого не просто так.</p>
            <p class="omhms-text omhms-text-italic">Я думаю, крайне логично, что делать что-то вместе будет куда проще, продуктивнее и быстрее, а главное - лучше, так что, you're welcome к нам в команду, если есть желание!"</p>
            <p class="omhms-text omhms-text-italic omhms-text-caption omhms-text-right"> OilMan <span class="omhms-text-caption"><br>когда-то в 2015-ом</span></p>
          </div>
        </div> 
      </div>
    </div> -->
    <div class="container omhms-content-container">
      <div class="row omhms-content-block justify-content-center">


        <div class="col-lg-3 col-md-4 col-12"> 
          <nav class="ocb-sticky-toc__sticky">
            <p class="omhms-text-caption">Зміст:</p>
            <ul class="list-inline">
              <li><a class="omhms-text-link__monitoring omhms-text-regular" href="#Begining">Початок</a></li>
              <li><a class="omhms-text-link__monitoring omhms-text-regular" href="#First">"Перший" запуск</a></li>
              <li><a class="omhms-text-link__monitoring omhms-text-regular" href="#Reload">OMHMS Перезавантаження</a></li>
              <li><a class="omhms-text-link__monitoring omhms-text-regular" href="#TwoPointO">OMHMS 2.0</a></li>
              <li><a class="omhms-text-link__monitoring omhms-text-regular" href="#ThreePointO">OMHMS 3.0</a></li>
              <li><a class="omhms-text-link__monitoring omhms-text-regular" href="#Four">OMHMS IV</a></li>
              <li><a class="omhms-text-link__monitoring omhms-text-regular" href="#Five">OMHMS 5</a></li>
            </ul>
          </nav>
        </div>

        <div class="col-lg-7 col-md-8 col-12">

          <div id="Begining" class="omhms-content-block scroll-margin-top-160">
            <span class="omhms-text omhms-text-h2-regular ocb-sticky-toc_item-header">Початок і домашній сервер – "Нульовий" запуск</span>
            <div class="spacer-24"></div>
            <p class="omhms-text omhms-text-regular">На початку квітня 2015-го року стартував локальний приватний сервер “для своїх”, на якому грали та фанилися теперішні старожили. З тих пір, загалом, багато що в проекті змінилося, але вже тоді з'являлися думки про вихід у добовий онлайн 24/7.</p>
            <p class="omhms-text omhms-text-regular">По-справжньому “Домашнього” OMHMS було дві ітерації, і обидві не можна назвати успішними, але це дуже великий досвід для мене як адміністратора та творця цього проекту. Тож – вчимося на помилках і продовжуємо працювати!</p>
          </div>
          <!-- END OF Begining -->

          <div id="First" class="omhms-content-block ocb-sticky-toc_item scroll-margin-top-160"> 
            <span class="omhms-text omhms-text-h2-regular ocb-sticky-toc_item-header">"Перший" запуск</span>
            <div class="spacer-24"></div>
            <p class="omhms-text omhms-text-regular">Майже через два роки, <span class="omhms-text-bold">2 липня 2017, стартував OMHMS 24/7</span>. Приватний сервер за підпискою, який був осередком ванільного виживання для кола друзів та знайомих. Було круто, було весело, але недовго. У перший місяць зареєструвалися 13 гравців. Але згодом ентузіазм і бажання грати згасало.</p>
            <p class="omhms-text omhms-text-regular">У результаті майже через три місяці довелося заново "відкривати" проект. Причиною цього були як непорозуміння між гравцями, так і мої помилки в адмініструванні. Не можна сказати, що "перший млинець - комом", але не все було ідеально ;)</p>

          </div>
          <!-- END OF First -->

          <div id="Reload" class="omhms-content-block ocb-sticky-toc_item scroll-margin-top-160"> 
            <span class="omhms-text omhms-text-h2-regular ocb-sticky-toc_item-header">Другий запуск – OMHMS Перезавантаження</span>
            <div class="spacer-24"></div>            
            <p class="omhms-text omhms-text-regular">OMHMS Перезавантаження. Четверта ітерація проекту. Четверта, і найуспішніша. Той вид сервера дуже добре себе показав як в ігровому плані, так і у плані спільноти. Дуже приємно було бачити нових людей та підтримку самої концепції сервера. В той раз сервер був готовий для того, щоб показати його публіці - виставити на моніторинги. Це дало добрий приріст. Кількість зареєстрованих гравців зросла до 43, а кільість активних гравців трималася в районі ~10 (активних не в плані гри на сервері, а в плані будь-якої активності в нашому товаристві). Цифри не найоптимістичніші, але, мені здається, що це цілком закономірно і нормально для проекту, який більшою мірою робиться і підтримується однією людиною. Також наш сервер на той момент ніде не рекламується. Головне – ком'юніті, яке у нас, як і раніше, залишається.</p>

          </div>
          <!-- END OF Reload -->

          <div id="TwoPointO" class="omhms-content-block ocb-sticky-toc_item scroll-margin-top-160"> 
            <span class="omhms-text omhms-text-h2-regular ocb-sticky-toc_item-header">Третій запуск – OMHMS 2.0</span>
            <div class="spacer-24"></div>            
            <p class="omhms-text omhms-text-regular">Той самий +- ванільний Minecraft-сервер із платною місячною підпискою, тією самою спільнотою та ідеологією. Все, за що гравці цінують та шанують наш проекті – залишається незмінним.</p>
            <p class="omhms-text omhms-text-regular">OMHMS 2.0 планувався як глобальний апдейт проекту зі своїм самописним плагіном, автоматичною реєстрацією в проекті та іншими штуками, про які я мрійливо писав у статті "Щоденники розробника #1 Пишемо свій перший minecraft-код" в тожі ще группі в ВК. Проте все пішло трохи не за планом, про що було детально розписано вже в іншій статті - "Щоденники розробника #2 Мінус мораль або як зафакапити проект?". Назва говорить сама за себе і там багато описано, так що, кому цікава наша історія – кілька хвилин на вивчення цих статей думаю можете їх знайти.</p>
            <p class="omhms-text omhms-text-regular">Тоді справи OMHMS IV потроху набирали швидкість. Ми рухалися вперед, заплановано було багато всього, але часу, на жаль, було катастрофічно мало.</p>

          </div>
          <!-- END OF TwoPointO -->

          <div id="ThreePointO" class="omhms-content-block ocb-sticky-toc_item scroll-margin-top-160"> 
            <span class="omhms-text omhms-text-h2-regular ocb-sticky-toc_item-header">OMHMS 3.0</span>
            <div class="spacer-24"></div>            
            <p class="omhms-text omhms-text-regular">Досить серйозне оновлення проекту. Ми перейшли на нову версію – 1.14.4. Ми змінили спосіб відбору гравців, тепер до цього процеса долучаються всі гравці. Було написано багато сторінок тексту, який мав би допомогти і зробив би наш світ прекраснішим, але.. Через якийсь час все знову скотилося до стану, коли сервер повільно але живе своїм життям і особливо не росте. Гравці грають, вайпів немає, але і активності теж.</p>
            <p class="omhms-text omhms-text-regular">У спробах підняти онлайн та активність була спроба побудувати РП-селище "Тристрам"! Дуже амбітний проект, який вистрілив голосно, але так само голосно (насправді дуже тихо і швидко) впав, бо вимагав дуже багато часу з боку адміністрації, а пустити на самотік на жаль не вийшло.</p>
            <p class="omhms-text omhms-text-regular">Докладніше про <span class="omhms-text-bold">OMHMS 3.0</span> можна <a href="https://docs.google.com/document/d/1OG0cqOV4oDh2R2mOdOHP87b5WrfobswF34NcAFHKuPk/edit" target="_blank" class="omhms-text-link">почитати тут</a>.</p>
          </div>
          <!-- END OF ThreePointO -->

          <div id="Four" class="omhms-content-block ocb-sticky-toc_item scroll-margin-top-160"> 
            <span class="omhms-text omhms-text-h2-regular ocb-sticky-toc_item-header">OMHMS IV</span>
            <div class="spacer-24"></div>            
            <p class="omhms-text omhms-text-regular">Нова сторінка у житті нашого проекту. Нові сили, новий вогонь в очах, нові амбіції та чергові оновлення. Так вже склалося в історії OMHMS, що графік активність на ньому як синусоїда. Кривенька така, але синусоїда ;) Ми без вайпа перейшли на 1.15.1, розміри світу збільшені до 8k, і сервер знову вийшов на моніторинги!</p>
            <p class="omhms-text omhms-text-regular">Однак не довго грала музика, проект після сплеску активності плавно почав затихати.</p>
          </div>
          <!-- END OF Four -->

          <div id="Five" class="omhms-content-block ocb-sticky-toc_item scroll-margin-top-160"> 
            <span class="omhms-text omhms-text-h2-regular ocb-sticky-toc_item-header">OMHMS 5</span>
            <div class="spacer-24"></div>            
            <p class="omhms-text omhms-text-regular">Новий початок. Велике оновлення, яке включає повністю перероблену систему адміністрування: від таблиць в Google Slides ми перейшли на власноруч з нуля написаний сайт та його бекенд. Власний плагін дасть можливість зручніше модерувати гравців, що приходять. Зміни також торкнулися і ігрового сервера - тепер їх три. Планується.. Мінімум три :)</p>    
            <!-- <p class="omhms-text omhms-text-regular">А от детальніше за посиланнями: <a href="./season" target="_blank" class="omhms-text-link">про поточний "сезон"</a> і <a href="./about" target="_blank" class="omhms-text-link">про сервер</a>.</p> -->
          </div>
          <!-- END OF Five -->


        </div> 

      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader"

export default {
  name: 'History',
  components: {
    PageHeader
  },
  props: {
  },
  data() {
    return {
      title: "Історія"
      
    }
  },
  mounted() {
    this.anchors = document.getElementsByClassName('ocb-sticky-toc_item-header');
    window.addEventListener('scroll', this.updateReadingProgress);
  },
  unmounted() {
    window.removeEventListener('scroll', this.updateReadingProgress);
  },
  methods: {
    updateReadingProgress() {
      var scrollTop = window.scrollY;
      var currentLinkElemNodeList;
      for (const anchor of this.anchors){

        var elementOffsetTop = anchor.parentElement.offsetTop - 250;
        var elementOffsetBottom = anchor.parentElement.offsetTop + anchor.parentElement.offsetHeight - 150;

        // console.log(anchor.parentElement);
        // if (anchor.parentElement.id == 0) {
          // elementOffsetTop = anchor.parentElement.offsetTop - 750;
        // }

        if (scrollTop > elementOffsetTop && scrollTop < elementOffsetBottom) {
          var ending = elementOffsetBottom - elementOffsetTop;
          var scroll = scrollTop - elementOffsetTop;
          var scrollPercent = scroll * 100 / ending + "%";

          currentLinkElemNodeList = document.querySelectorAll("a[href='#"+anchor.parentElement.id+"']");

          if (currentLinkElemNodeList) { // если нашло сответствущую ссылку
            currentLinkElemNodeList[0].parentElement.classList.add("current-anchor-active");
            currentLinkElemNodeList[0].parentElement.style.setProperty("--scrollAmount", scrollPercent);
          } else {
            break;
          }
        } else {
          currentLinkElemNodeList = document.querySelectorAll("a[href='#"+anchor.parentElement.id+"']")

          if (currentLinkElemNodeList) { // если ссылка вообще есть
            currentLinkElemNodeList[0].parentElement.classList.remove("current-anchor-active");
          } else {
            break;
          }
        }
      }
    }
  }
}
</script>