<template>
  <b-modal
    id="profileAddCharacterModal"
    no-close-on-backdrop
    v-model="showModal"
    class="profile-add-character-modal custom-modal"
    @show="onShown"
    @hidden="onHidden"
  >
    <template #title>
      <div>
        <span class="modal-title modal-title_bold">Додання персонажа</span>
      </div>
    </template>
    <template #header-close>
      <img type="button" class="modal-closeButton" data-bs-dismiss="modal">
    </template>
    <template #default>
      <span class="main-text">Якщо у тебе є ще один ігровий обліковий запис, з яким ти коли-небудь грав на нашому проекті, ти можеш спробувати додати цей нікнейм до свого профілю.</span>
      <span class="secondary-text">Не факт що твій акаунт збережено у нашій базі гравців, однак можеш спробувати :)</span>

      <div v-if="error" class="login-error">
        <span class="error-title">
          Такої зв'язки <span class="error-title_bold">гравець-пароль</span> немає у нашій базі даних.<br/>
        </span>
        <span class="error-text">
          Ти точно входиш під своїм акаунтом з сервера?
        </span>
      </div>

      <CustomInput
        :id="`question-0`"
        :is-optional="nicknameQuestion.optional"
        :subtitle="nicknameQuestion.description"
        :answer="nicknameQuestion.answer"
        :disabled="$wait.is(waitName)"
        :placeholder="nicknameQuestion.placeholder"
        @onUpdate="updateQuestion(nicknameQuestion, $event)"
        :class="[{'opacity-50' : $wait.is(waitName)}]"
      >
        {{ nicknameQuestion.text }}
      </CustomInput>
      <CustomInput
        :id="`question-1`"
        :type="passwordInputType"
        :is-optional="passwordQuestion.optional"
        :subtitle="passwordQuestion.description"
        :answer="passwordQuestion.answer"
        :disabled="$wait.is(waitName)"
        custom-class="custom-input_password"
        :placeholder="passwordQuestion.placeholder"
        :image="passwordInputImage"
        @onUpdate="updateQuestion(passwordQuestion, $event)"
        @onSubmit="loginAction"
        @iconClicked="passowordButtonClicked"
        :class="[{'opacity-50' : $wait.is(waitName)}]"
      >
        {{ passwordQuestion.text }}
      </CustomInput>
    </template>
    <template #footer>
      <CustomButton
        isClear
        value="Додати"
        :disabled="!nicknameQuestion.hasAnswer() || !passwordQuestion.hasAnswer()"
        :wait-name="waitName"
        @clicked="loginAction"
        :class="[{'opacity-50' : $wait.is(waitName)}]"
      />
    </template>
  </b-modal>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import { sha256 } from "js-sha256"

import CustomButton from "@/components/CustomButton"
import CustomInput from "@/components/CustomInput"

import TextQuestion from "@/models/questions/TextQuestion"

export default {
  name: "ProfileAddCharacter",
  components: {
    CustomInput,
    CustomButton
  },
  props: {

  },
  data() {
    return {
      nicknameQuestion: new TextQuestion(),
      passwordQuestion:  new TextQuestion(),
      waitName: 'profile.addCharacter',
      isPasswordVisible: false,
      showModal: false,
    }
  },
  computed: {
    ...mapGetters({
      error: "profile/loginError",
      profile: "profile/profile",
    }),

    personId() {
      return this.profile?.person?.id ?? null
    },

    passwordInputImage() {
      return this.isPasswordVisible ? "visibility_off" : "visibility"
    },

    passwordInputType() {
      return this.isPasswordVisible ? "text" : "password"
    },
  },
  methods: {
    ...mapActions({
      addCharacter: "profile/addCharacter",
      clearLoginError: "profile/clearLoginError",
      unsetError: "auth/unsetError",
    }),

    onShown() {
      this.nicknameQuestion = new TextQuestion({
        text: "Нікнейм на ігровому сервері",
        placeholder: "Твій ігровий username",
        answer: ""
      })
      this.passwordQuestion = new TextQuestion({
        text: "Пароль на ігровому сервері",
        placeholder: "Пароль",
        answer: ""
      })
    },

    async loginAction() {
      this.$wait.start(this.waitName)
      await this.addCharacter({
        personId: this.personId,
        userName: this.nicknameQuestion.answer,
        hash: sha256(this.passwordQuestion.answer)
      })
      this.$wait.end(this.waitName)
      if (!this.error) {
        this.showModal = false
        this.$emit("characterAdded")
      }
    },

    updateQuestion(question, value) {
      this.unsetError()
      question.update(value)
    },

    passowordButtonClicked() {
      this.isPasswordVisible = !this.isPasswordVisible
    },

    onHidden() {
      this.clearLoginError()
    },
  }
}
</script>