<template>
  <div class="col-12 custom-notification">
    <div class="custom-notification-content">
      <div
        v-if="notification.header"
        class="custom-notification__header"
      >
        {{ notification.header }}
    </div>
    <div class="custom-notification__title">{{ notification.title }}</div>
    <div class="custom-notification__text">{{ notification.text }}</div>
    <div v-if="notification.items.length" class="custom-notification__items">
      <div
        v-for="(item, index) in notification.items"
        :key="index"
        class="custom-notification__item"
        @click="$router.push({name: 'registration', params: {username: item.userName }})"
      >
        {{ item.userName }}
        <span class="custom-notification__real-name">{{ item.realName }}</span>
      </div>
    </div>
  </div>
  <div v-if="notification.action" class="custom-notification__button">
    <CustomButton
      :value="notification.action"
      isClear
      @clicked="clickAction"
    />
  </div>
</div>
</template>

<script>
  import CustomButton from "@/components/CustomButton"

  import Notification from "@/models/Notification"

  export default {
    name: 'CustomNotification',
    components: {
      CustomButton
    },
    props: {
      notification: {
        type: Notification,
        required: true
      }
    },
    data() {
      return {
      }
    },
    methods: {
      clickAction() {
        if (this.notification.type === "no_registration") {
          this.$router.push({name: "registration"})
        }
      }
    }
  }
</script>