<template>
  <div class="main-layout">
    <PageHeader :title="title"/>
    <!-- <div class="season-image"></div> -->
  </div>

  <div class="bg-end-noise">
    <div class="container omhms-content-container" id="first_anchor">
      <div class="row omhms-content-block">
        <div class="col-lg-6 col-sm-12">
          <div class="col-2 omhms-content-block-decorline"></div>
          <span class="omhms-text omhms-text-h2-bold">OMHMS Media kit</span>
          <div class="spacer-24"></div>
          <p class="omhms-text omhms-text-bold">Медіакіт? Що це?</p>
          <p class="omhms-text omhms-text-regular">Медіакіт, або прескіт — це набір матеріалів, презентація бренду, опис продукту, переважно для тих, хто потенційно може потребувати ці матеріали для використання їх у своїй творчості та/чи роботах.</p>
          <p class="omhms-text-caption omhms-text-color-60">Конкретно у випадку ОМХМС, наш медіакіт створено для контент-мейкерів, які роблять відео на нашому сервері.</p>
          <div class="spacer-24"></div>
          <a href="https://omhms.com/share/media/omhms-media-kit.zip" class="omhms-text-button">Завантажити медіа-кіт</a>
        </div>

        <div class="col-lg-5 offset-lg-1 offset-md-0 col-md-10 col-sm-12 margin-top">
          <div class="omhms-image" :data-pm='1.4'>
            <img
              id="img1"
              class="omhms-image-stroke"
              alt="Hello, there!"
              :src="require(`@/assets/images/season/01.jpg`)"
            />
          </div>
        </div>

        <div class="row omhms-content-block justify-content-center ocb-padding-top-100">
          <div class="col-lg-3 col-md-4 col-12"> 
            <nav class="ocb-sticky-toc__sticky">
              <p class="omhms-text-bold omhms-text-color-60">OMHMS Медіа кіт:</p>
              <ul class="list-inline">
                <li><span class="omhms-text-caption">Набір медіа-матеріалів:</span></li>
                <li><a class="omhms-text-link__monitoring omhms-text-regular" href="#media_logos">Логотипи</a></li>
                <li><a class="omhms-text-link__monitoring omhms-text-regular" href="#media_icons">Іконки</a></li>
                <li><a class="omhms-text-link__monitoring omhms-text-regular" href="#media_video">Анімоване відео-інтро логотипу</a></li>
                <li><a class="omhms-text-link__monitoring omhms-text-regular" href="#media_font">Шрифт</a></li>
                <li><a class="omhms-text-link__monitoring omhms-text-regular" href="#media_color">Колір</a></li>
                <li><span class="omhms-text-caption"></span></li>
                <li><span class="omhms-text-caption">Текстовий супровід:</span></li>
                <li><a class="omhms-text-link__monitoring omhms-text-regular" href="#media_info">Загальна інформація</a></li>
                <li><a class="omhms-text-link__monitoring omhms-text-regular" href="#media_specs">Технічна інформація</a></li>
                <li><span class="omhms-text-caption"></span></li>
                <li><span class="omhms-text-caption">Інше:</span></li>
                <li><a class="omhms-text-link__monitoring omhms-text-regular" href="#media_socials">Соціальні мережі</a></li>
              </ul>
            </nav>
          </div>
          <div class="col-lg-7 col-md-8 col-12 ">
          
               <div id="media_logos" class="ocb-padding-top-50">
                 <p class="omhms-text omhms-text-h3-regular ocb-sticky-toc_item-header">Логотипи</p>

                 <div class="row">  
                    <div class="col-md-6 col-12">
                      <div class="spacer-24"></div>
                      <img
                        id="logo_old_a"
                        class="omhms-image-media"
                        alt="Hello, there!"
                        style="width: 100%"
                        :src="require(`@/assets/images/media/logo_old_b.png`)"
                      />
                      <p class="omhms-text omhms-text-caption" style="text-align: center">
                        Основний логотип без підписа
                        <a href="https://omhms.com/share/media/logo_old_b.png" target="_blank"><img class="omhms-image__clickable-icon" :src="require(`@/assets/images/svg/download-cloud.svg`)"></a>
                      </p>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="spacer-24"></div>
                      <img
                        id="logo_old_b"
                        class="omhms-image-media"
                        alt="Hello, there!"
                        style="width: 100%"
                        :src="require(`@/assets/images/media/logo_old_a.png`)"
                      />
                      <p class="omhms-text omhms-text-caption" style="text-align: center">
                        Основний логотип з підписом
                        <a href="https://omhms.com/share/media/logo_old_a.png" target="_blank"><img class="omhms-image__clickable-icon" :src="require(`@/assets/images/svg/download-cloud.svg`)"></a>
                      </p>
                    </div> 
                  </div>
                  <div class="row"> 
                    <div class="col-md-6 col-12">
                        <div class="spacer-24"></div>
                        <img
                         id="logo_new"
                         class="omhms-image-media"
                         alt="Hello, there!"
                         style="width: 100%"
                         :src="require(`@/assets/images/media/logo_new.png`)"
                       />
                        <p class="omhms-text omhms-text-caption" style="text-align: center">
                          Новий логотип (з відео) без підписа
                          <a href="https://omhms.com/share/media/logo_new.png" target="_blank"><img class="omhms-image__clickable-icon" :src="require(`@/assets/images/svg/download-cloud.svg`)"></a>
                      </p>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="spacer-24"></div>
                        <img
                         id="logo_new"
                         class="omhms-image-media"
                         alt="Hello, there!"
                         style="width: 100%"
                         :src="require(`@/assets/images/media/logo_new_text.png`)"
                       />
                        <p class="omhms-text omhms-text-caption" style="text-align: center">
                          Новий логотип (з відео) з підписом
                          <a href="https://omhms.com/share/media/logo_new_text.png" target="_blank"><img class="omhms-image__clickable-icon" :src="require(`@/assets/images/svg/download-cloud.svg`)"></a>
                      </p>
                      </div>
                  </div>
               </div> 
               <!-- END OF ЛОГОТИПИ -->

               <div id="media_icons" class="ocb-padding-top-50">
                 <p class="omhms-text omhms-text-h3-regular ocb-sticky-toc_item-header">Іконки</p>

                 <div class="row">  
                    <div class="col-md-3 col-6">
                      <div class="spacer-24"></div>
                      <img
                        id="icon_ua"
                        class="omhms-image-media"
                        alt="Hello, there!"
                        style="width: 100%"
                        :src="require(`@/assets/images/media/icon_ua.png`)"
                      />
                      <p class="omhms-text omhms-text-caption" style="text-align: center">
                        Іконка з фоном
                        <a href="https://omhms.com/share/media/icon_ua.png" target="_blank"><img class="omhms-image__clickable-icon" :src="require(`@/assets/images/svg/download-cloud.svg`)"></a>
                      </p>
                    </div>
                    <div class="col-md-3 col-6">
                      <div class="spacer-24"></div>
                      <img
                        id="icon"
                        class="omhms-image-media"
                        alt="Hello, there!"
                        style="width: 100%"
                        :src="require(`@/assets/images/media/icon.png`)"
                      />
                      <p class="omhms-text omhms-text-caption" style="text-align: center">
                        Іконка без фона
                        <a href="https://omhms.com/share/media/icon.png" target="_blank"><img class="omhms-image__clickable-icon" :src="require(`@/assets/images/svg/download-cloud.svg`)"></a>
                      </p>
                    </div>
                 </div>
                 
               </div> 
               <!-- END OF ІКОНКИ -->

               <div id="media_video" class="ocb-padding-top-50">
                 <p class="omhms-text omhms-text-h3-regular ocb-sticky-toc_item-header">Відео-інтро логотипу</p>

                 <div class="row">  
                    <div class="col-md-6 col-12">
                      <div class="spacer-24"></div>
                      <img
                        id="logo_new"
                        class="omhms-image-media"
                        alt="Hello, there!"
                        style="width: 100%"
                        :src="require(`@/assets/images/media/logo_intro.gif`)"
                      />
                      <p class="omhms-text omhms-text-caption" style="text-align: center">
                        Відео-інтро логотипу <span class="omhms-text-color-45">(16мб)</span>
                        <a href="https://omhms.com/share/media/video_logo_intro.zip" target="_blank"><img class="omhms-image__clickable-icon" :src="require(`@/assets/images/svg/download-cloud.svg`)"></a>
                      </p>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="spacer-24"></div>
                      <img
                        id="logo_new"
                        class="omhms-image-media"
                        alt="Hello, there!"
                        style="width: 100%"
                        :src="require(`@/assets/images/media/logo_intro_text.gif`)"
                      />
                      <p class="omhms-text omhms-text-caption" style="text-align: center">
                        Відео-інтро логотипу з підписом <span class="omhms-text-color-45">(29мб)</span>
                        <a href="https://omhms.com/share/media/video_logo_intro_text.zip" target="_blank"><img class="omhms-image__clickable-icon" :src="require(`@/assets/images/svg/download-cloud.svg`)"></a>
                      </p>
                    </div>
                 </div>

               </div> 
               <!-- END OF ВІДЕО -->

               <div id="media_font" class="ocb-padding-top-50">
                 <p class="omhms-text omhms-text-h3-regular ocb-sticky-toc_item-header">Шрифт</p>
                 <div class="row">  
                    <div class="col-6">
                      <div class="spacer-24"></div>
                      <img
                        id="font"
                        class="omhms-image-media"
                        alt="Hello, there!"
                        style="width: 100%"
                        :src="require(`@/assets/images/media/font.png`)"
                      />
                      <p class="omhms-text omhms-text-caption" style="text-align: center">
                         Основний шрифт
                         <a href="https://omhms.com/share/media/minecraft1.ttf" target="_blank"><img class="omhms-image__clickable-icon" :src="require(`@/assets/images/svg/download-cloud.svg`)"></a>
                      </p>
                    </div>
                  </div>
               </div> 
               <!-- END OF ШРИФТ -->

               <div id="media_color" class="ocb-padding-top-50">
                 <p class="omhms-text omhms-text-h3-regular ocb-sticky-toc_item-header">Колір</p>
                 <div class="row">  
                    <div class="col-lg-4 col-6">
                      <div class="spacer-24"></div>
                      <img
                        id="icon_ua"
                        class="omhms-image-media"
                        alt="Hello, there!"
                        style="width: 100%"
                        :src="require(`@/assets/images/media/color_00_general.png`)"
                      />
                      <p class="omhms-text omhms-text-caption" style="text-align: center">
                        Просто білий
                        <img class="omhms-image__clickable-icon" @click="copyToClipboard('#FFFFFF')" :src="require(`@/assets/images/svg/copy.svg`)">
                      </p>
                    </div>
                    <div class="col-lg-4 col-6">
                      <div class="spacer-24"></div>
                      <img
                        id="icon"
                        class="omhms-image-media"
                        alt="Hello, there!"
                        style="width: 100%"
                        :src="require(`@/assets/images/media/color_01_main.png`)"
                      />
                      <p class="omhms-text omhms-text-caption" style="text-align: center">
                        Колір основного севреру виживання
                        <img class="omhms-image__clickable-icon" @click="copyToClipboard('#00AA00')" :src="require(`@/assets/images/svg/copy.svg`)">
                      </p>
                    </div>
                    <div class="col-lg-4 col-6">
                      <div class="spacer-24"></div>
                      <img
                        id="icon"
                        class="omhms-image-media"
                        alt="Hello, there!"
                        style="width: 100%"
                        :src="require(`@/assets/images/media/color_02_factory.png`)"
                      />
                      <p class="omhms-text omhms-text-caption" style="text-align: center">
                        Колір світу фабрик
                        <img class="omhms-image__clickable-icon" @click="copyToClipboard('#5555FF')" :src="require(`@/assets/images/svg/copy.svg`)">
                      </p>
                    </div>
                    <div class="col-lg-4 col-6">
                      <div class="spacer-24"></div>
                      <img
                        id="icon_ua"
                        class="omhms-image-media"
                        alt="Hello, there!"
                        style="width: 100%"
                        :src="require(`@/assets/images/media/color_03_creative.png`)"
                      />
                      <p class="omhms-text omhms-text-caption" style="text-align: center">
                        Колір Креативу
                        <img class="omhms-image__clickable-icon" @click="copyToClipboard('#AA00AA')" :src="require(`@/assets/images/svg/copy.svg`)">
                      </p>
                    </div>
                    <div class="col-lg-4 col-6">
                      <div class="spacer-24"></div>
                      <img
                        id="icon"
                        class="omhms-image-media"
                        alt="Hello, there!"
                        style="width: 100%"
                        :src="require(`@/assets/images/media/color_04_minigames.png`)"
                      />
                      <p class="omhms-text omhms-text-caption" style="text-align: center">
                        Колір Міні-ігр
                        <img class="omhms-image__clickable-icon" @click="copyToClipboard('#FFAA00')" :src="require(`@/assets/images/svg/copy.svg`)">
                      </p>
                    </div>
                    <div class="col-lg-4 col-6">
                      <div class="spacer-24"></div>
                      <img
                        id="icon"
                        class="omhms-image-media"
                        alt="Hello, there!"
                        style="width: 100%"
                        :src="require(`@/assets/images/media/color_05_sandbox.png`)"
                      />
                      <p class="omhms-text omhms-text-caption" style="text-align: center">
                        Колір Пісочниці
                        <img class="omhms-image__clickable-icon" @click="copyToClipboard('#EED919')" :src="require(`@/assets/images/svg/copy.svg`)">
                      </p>
                    </div>
                  </div>
               </div> 
               <!-- END OF ШРИФТ -->

              <div id="media_info" class="ocb-padding-top-50">
                <p class="omhms-text omhms-text-h3-regular ocb-sticky-toc_item-header">Загальна інформація про сервер та проект</p>
                <p class="omhms-text omhms-text-regular">OMHMS – це платний приватний сервер без донату, із ванільним виживанням, для гравців які хочуть комфортної та спокійної гри. Додатково є сервер-пісочниця (безкоштовне виживання), та сервер безкоштовний міні-ігр.</p>
                <div class="spacer-24"></div>                
                <p class="omhms-text omhms-text-regular omhms-text-no-p-bottom-margin">Назва <span class="omhms-text-bold">OMHMS</span> – це аббревіатура від <span class="omhms-text-bold">OilMan Home Minecraft Server</span>.</p>
                <p class="omhms-text-caption"> OMHMS – це проект, який почав свій шлях як домашній сервер у 2015, коли адмін хостив його на своєму ноутбуці. Але із роками, сервер розрісся до комплексу з п'яти серверів.
                Детальніше про <a class="omhms-text-link" href="/season" target="_blank">поточний созон</a> проекту.</p>
                <div class="spacer-24"></div>
                <p class="omhms-text omhms-text-regular omhms-text-no-p-bottom-margin">Проект буквально розробляється однією людиною – <span class="omhms-text-bold">MrOilman</span>'ом,</p>
                <p class="omhms-text omhms-text-caption">але сайтом та бекендом займається адмін і ще один розробник. Деякі штуки на сервері допомагає робити TorDanGar, а у розвитку активно приймає участь сама спільнота проекту (текстури, моделі, мапи для міні-ігр, РП, тощо).</p>
                <div class="spacer-24"></div>
                <p class="omhms-text omhms-text-caption omhms-text-no-p-bottom-margin">За будь-якими питаннями звертайтесь в <a href="https://discord.gg/5HugZgxgZz" class="omhms-text-link" target="_blank">діскорд сервера</a> або <a href="https://t.me/VladOliinyk" class="omhms-text-link" target="_blank">телеграм одмєна</a>.
                </p>
              </div>

              <div id="media_specs" class="ocb-padding-top-50">
                <p class="omhms-text omhms-text-h3-regular ocb-sticky-toc_item-header">Технічна інформація</p>
                <ul class="list-inline">
                  <li>
                    <p class="omhms-text-caption omhms-text">Ядро: <span class="omhms-text-console">Pufferfish+</span></p>
                  </li>
                  <li>
                    <p class="omhms-text-caption omhms-text">Версія основи: <span class="omhms-text-console">1.20.1</span></p>
                  </li>
                  <li>
                    <p class="omhms-text-caption omhms-text">Версія міні-ігр: <span class="omhms-text-console">1.19.3</span></p>
                  </li>
                  <li>
                    <p class="omhms-text-caption omhms-text">Проксі: <span class="omhms-text-console">Waterfall</span></p>
                  </li>
                  <li>
                    <p class="omhms-text-caption omhms-text">Бедрок: <span class="omhms-text-console">Geyser</span></p>
                  </li>
                  <li>
                    <p class="omhms-text-caption omhms-text">Хостинг: <span class="omhms-text-console">Hetzner</span></p>
                  </li>
                  <li>
                    <p class="omhms-text-caption omhms-text">Сайт: <span class="omhms-text-console">Vue</span>+<span class="omhms-text-console">Java Backend</span></p>
                  </li>
                </ul>
              </div>


              <div id="media_socials" class="ocb-padding-top-50">
                <p class="omhms-text omhms-text-h3-regular ocb-sticky-toc_item-header">Соціальні мережі</p>
                <ul class="list-inline">
                  <li>
                    <p class="omhms-text-caption omhms-text">
                      Сайт:
                      <a href="https://omhms.com/" class="omhms-text-console omhms-text-link__noline" target="_blank">
                        omhms.com
                      </a>
                    </p>
                  </li>
                  <li>
                    <p class="omhms-text-caption omhms-text">
                      YouTube:
                      <a href="https://youtube.com/channel/UCj3cuVAttxJp9jCtx0mjMsA" class="omhms-text-console omhms-text-link__noline" target="_blank">
                        @omhms
                      </a>
                    </p>
                  </li>
                  <li>
                    <p class="omhms-text-caption omhms-text">
                      Discord:
                      <a href="https://discord.gg/5HugZgxgZz" class="omhms-text-console omhms-text-link__noline" target="_blank">
                        discord.gg/5HugZgxgZz
                      </a>
                    </p>
                  </li>
                  <li>
                    <p class="omhms-text-caption omhms-text">
                      Telegram:
                      <a href="https://t.me/omhms_mroilman" class="omhms-text-console omhms-text-link__noline" target="_blank">
                        t.me/omhms_mroilman
                      </a>
                    </p>
                  </li>
                  <li>
                    <p class="omhms-text-caption omhms-text">
                      Instagram:
                      <a href="https://instagram.com/p/CkJA0glI12v/" class="omhms-text-console omhms-text-link__noline" target="_blank">
                        @omhms.mroilman
                      </a>
                    </p>
                  </li>
                  <li>
                    <p class="omhms-text-caption omhms-text">
                      Twitch:
                      <a href="https://twitch.tv/mroilman" class="omhms-text-console omhms-text-link__noline" target="_blank">
                        twitch.tv/mroilman
                      </a>
                    </p>
                  </li>
                  <li>
                    <p class="omhms-text-caption omhms-text">
                      TikTok:
                      <a href="https://www.tiktok.com/@omhms.com" class="omhms-text-console omhms-text-link__noline" target="_blank">
                        @omhms.com
                      </a>
                    </p>
                  </li>
                </ul>
              </div>
              <div class="spacer-24"></div>
              <a href="https://omhms.com/share/media/omhms-media-kit.zip" class="omhms-text-button">Завантажити медіа-кіт</a>
          </div>

        </div>
      </div>
    </div>

  </div>




</template>

<script>
import PageHeader from "@/components/PageHeader"
import { mapGetters } from 'vuex'

export default {
  name: 'Media',
  components: {
    PageHeader
  },
  props: {
  },
  data() {
    return {
      title: "OMHMS Медіа-кіт"
      
    }
  },
  mounted() {
    this.images = document.getElementsByClassName("omhms-image");
    this.customTopOffset = -50;
    this.$isMobile() ? this.mobileMultiplier = 0.5 : this.mobileMultiplier = 1
    window.addEventListener('scroll', this.parallaxTheMainImages);

    this.anchors = document.getElementsByClassName('ocb-sticky-toc_item-header');
    window.addEventListener('scroll', this.updateReadingProgress);
  },
  unmounted() {
    window.removeEventListener('scroll', this.parallaxTheMainImages);
    window.removeEventListener('scroll', this.updateReadingProgress);
  },
  computed: {
    ...mapGetters({
      check: "auth/check"
    })
  },
  methods: {
    parallaxTheMainImages() {
      // console.log("i'm working")
      // console.log(this.$isMobile() + " " + this.mobileMultiplier);
      for (var i = 0; i < this.images.length; i++) {
        var top = this.images[i].getBoundingClientRect().top;
        if (top > -500 & top < 1000) {
          this.images[i].style.top = (this.customTopOffset + top/10 * this.images[i].dataset.pm) * this.mobileMultiplier + "px";
        }
      }
    },
    copyToClipboard(value) {
      // Get the text field
      // var copyText = document.getElementById("myInput");

      // Select the text field
      // copyText.select();
      // copyText.setSelectionRange(0, 99999); // For mobile devices

       // Copy the text inside the text field
      navigator.clipboard.writeText(value);

      // Alert the copied text
      // alert("Copied the text: " + "qwe");
    },
      updateReadingProgress() {
        var scrollTop = window.scrollY;
        var currentLinkElemNodeList;
        for (const anchor of this.anchors){

          var elementOffsetTop = anchor.parentElement.offsetTop - 250;
          var elementOffsetBottom = anchor.parentElement.offsetTop + anchor.parentElement.offsetHeight - 250;

          // console.log(anchor.parentElement);
          // if (anchor.parentElement.id == 0) {
            // elementOffsetTop = anchor.parentElement.offsetTop - 750;
          // }

          if (scrollTop > elementOffsetTop && scrollTop < elementOffsetBottom) {
            var ending = elementOffsetBottom - elementOffsetTop;
            var scroll = scrollTop - elementOffsetTop;
            var scrollPercent = scroll * 100 / ending + "%";

            currentLinkElemNodeList = document.querySelectorAll("a[href='#"+anchor.parentElement.id+"']");

            if (currentLinkElemNodeList) { // если нашло сответствущую ссылку
              currentLinkElemNodeList[0].parentElement.classList.add("current-anchor-active");
              currentLinkElemNodeList[0].parentElement.style.setProperty("--scrollAmount", scrollPercent);
            } else {
              break;
            }
          } else {
            currentLinkElemNodeList = document.querySelectorAll("a[href='#"+anchor.parentElement.id+"']")

            if (currentLinkElemNodeList) { // если ссылка вообще есть
              currentLinkElemNodeList[0].parentElement.classList.remove("current-anchor-active");
            } else {
              break;
            }
          }
        }
      },

      // calls each time the dropdown button clicked
      updateReadingProgressDropdown() {

          var i=0;  
          while(i<=500) {
            (async () => {
                await this.delay(i);
                 this.updateReadingProgress();
            })();
            i+=25;
          }
      }
  }
}

</script>