<template>
  <div class="main-layout">
    <PageHeader :title="title"/>
  </div>

  <div class="bg-end-noise">
    <div class="container omhms-content-container omhms-content-block" id="first_anchor">
      <div class="row justify-content-center text-center">
        <div class="col-lg-8 col-sm-12">
          <span class="omhms-text omhms-text-h2-bold">OMHMS: Сувенірна продукція</span>
          <div class="spacer-24"></div>
          <p class="omhms-text omhms-text-regular">Пілотна партія сувенірної продукції вже підготована, і чекає на своїх власників!</p>
        </div>
      </div>
      <div class="row omhms-content-block justify-content-center">
        <div class="col-lg-4 col-md-8 col-11 margin-top">
          <div class="omhms-image" :data-pm="0.8">
            <img
            class="omhms-image-stroke"
            alt="Hello, there!"
            :src="require(`@/assets/images/merch/2022/02.jpg`)"
            />
          </div>
        </div>
        <div class="col-lg-5 col-md-8 col-11 offset-lg-1 ocb-padding-top-lg-100 ocb-padding-top-0">
          <div class="omhms-image margin-top" :data-pm="0.2">
            <img
            class="omhms-image-stroke"
            alt="Hello, there!"
            :src="require(`@/assets/images/merch/2022/01.jpg`)"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="offset-0 col-md-3 offset-lg-1 col-12 ocb-padding-top-0 ocb-padding-top-lg-50">
          <div class="row ocb-padding-top-100">
            <div class="col-12">
              <p class="omhms-text omhms-text-h2-regular">Набір стікерів</p>
              <p class="omhms-text omhms-text-regular">Набір крутезних тематичних стікерів!</p>
              <p class="omhms-text omhms-text-caption">Формат аркуша: А5</p>
            </div>
          </div>
        </div>
        <div class="offset-md-1 col-md-7 col-12 offset-0 ocb-padding-top-lg-50 ocb-padding-top-0">
          <div class="omhms-image " :data-pm="0.8">
            <img
            class="omhms-image-stroke"
            alt="Hello, there!"
            :src="require(`@/assets/images/merch/2022/product_stickers.jpg`)"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="offset-0 col-md-3 offset-lg-1 col-12 ocb-padding-top-0 ocb-padding-top-lg-50">
          <div class="row ocb-padding-top-100">
            <div class="col-12">
              <p class="omhms-text omhms-text-h2-regular">Постер</p>
              <p class="omhms-text omhms-text-regular">Один з 5 постерів різних локацій світу OMHMS!</p>
              <p class="omhms-text omhms-text-caption">Формат аркуша: А5</p>
            </div>
          </div>
        </div>

        <div class="offset-0 col-md-7 offset-lg-1 col-12 ocb-padding-top-0 ocb-padding-top-lg-50">
          <div class="product__poster__carousel">
            <div class="product__poster__carousel-item">
                <div class="omhms-image" :data-pm="1.2">
                  <img
                  class="omhms-image-stroke product__poster__slides"
                  alt="Hello, there!"
                  :src="require(`@/assets/images/merch/2022/product_poster_01.jpg`)"
                  />
                </div>
            </div>
            <div class="product__poster__carousel-item">
                <div class="omhms-image" :data-pm="1.2">
                  <img
                  class="omhms-image-stroke product__poster__slides"
                  alt="Hello, there!"
                  :src="require(`@/assets/images/merch/2022/product_poster_02.jpg`)"
                  />
                </div>
            </div>
            <div class="product__poster__carousel-item">
                <div class="omhms-image" :data-pm="1.2">
                  <img
                  class="omhms-image-stroke product__poster__slides"
                  alt="Hello, there!"
                  :src="require(`@/assets/images/merch/2022/product_poster_03.jpg`)"
                  />
                </div>
            </div>
            <div class="product__poster__carousel-item">
                <div class="omhms-image" :data-pm="1.2">
                  <img
                  class="omhms-image-stroke product__poster__slides"
                  alt="Hello, there!"
                  :src="require(`@/assets/images/merch/2022/product_poster_04.jpg`)"
                  />
                </div>
            </div>
            <div class="product__poster__carousel-item">
                <div class="omhms-image" :data-pm="1.2">
                  <img
                  class="omhms-image-stroke product__poster__slides"
                  alt="Hello, there!"
                  :src="require(`@/assets/images/merch/2022/product_poster_05.jpg`)"
                  />
                </div>
            </div>
            <div class="product__poster__carousel-item_bg">
                <div class="omhms-image" :data-pm="1.2">
                  <img
                  class="omhms-image-stroke product__poster__slides"
                  alt="Hello, there!"
                  :src="require(`@/assets/images/merch/2022/product_poster_01.jpg`)"
                  />
                </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="offset-0 col-md-3 offset-lg-1 col-12 ocb-padding-top-0">
          <div class="row ocb-padding-top-100">
            <div class="col-12">
              <p class="omhms-text omhms-text-h2-regular">Магнітик</p>
              <p class="omhms-text omhms-text-regular">Звичайний магнітний магнітик ;)</p>
              <p class="omhms-text omhms-text-caption">Розмір: 90х50мм</p>
            </div>
          </div>
        </div>
        <div class="offset-md-1 col-md-7 col-12 offset-0 ocb-padding-top-50">
          <div class="omhms-image" :data-pm="0.8">
            <img
            class="omhms-image-stroke"
            alt="Hello, there!"
            :src="require(`@/assets/images/merch/2022/product_magnet_2.jpg`)"
            />
          </div>
        </div>
      </div>

      <template v-if="check">  
        <div class="container-fluid omhms-content-container-dark ocb-padding-top-100">
          <div class="row omhms-content-block omhms-content-block-cta justify-content-center">
            <div class="col-10">
              <p class="omhms-text omhms-text-h2-regular">Хочеш собі такий наборчик?</p>
              <p class="omhms-text omhms-text-regular">Купуй <span class="omhms-text omhms-text-bold omhms-text-color-server_newbie omhms-text__player-tier3">Могутню</span> підписку <br> та пиши одмєну!</p>
              <p class="omhms-text omhms-text-caption">* Пропозиція на набір сувенірки діє для гравців хто придбав Могутню перепустку на 3 місяці (за весь період гри на сервері), <br>або для тих у кого є перепустка за 12 місяців (тобто хто грає в проекті рік).</p>
              <CustomButton isClear value="Переглянути рівні підписок" @clicked="$router.push('subscription')"/>
            </div>
          </div>
        </div>
      </template>

      <template v-if="!check">  
        <div class="container-fluid omhms-content-container-dark ocb-padding-top-100">
          <div class="row omhms-content-block omhms-content-block-cta justify-content-center">
            <div class="col-10">
              <p class="omhms-text omhms-text-h2-regular">Хочеш собі такий наборчик?</p>
              <p class="omhms-text omhms-text-regular">Реєструйся, заповнюй просту форму, купуй <span class="omhms-text omhms-text-bold omhms-text-color-server_newbie omhms-text__player-tier3">Могутню</span> підписку та приєднуйся до OMHMS!</p>
              <p class="omhms-text omhms-text-caption">* Пропозиція на набір сувенірки діє для гравців хто придбав Могутню перепустку на 3 місяці (за весь період гри на сервері), <br>або для тих у кого є перепустка за 12 місяців (тобто хто грає в проекті рік).</p>
              <div class="merch-cta-buttons-fix">
                <CustomButton value="ЗАПОВНИТИ АНКЕТУ" @clicked="$router.push('profile')"/>
                <CustomButton isClear value="Переглянути рівні підписок" @clicked="$router.push('subscription')"/>
              </div>
            </div>
          </div>
        </div>
      </template>

      <div class="row omhms-content-block">
        <div class="offset-md-1 col-md-6 col-12 offset-0 ocb-padding-top-lg-100 ocb-padding-top-50">
          <div class="omhms-image" :data-pm="1.2">
            <img
            class="omhms-image-stroke"
            alt="Hello, there!"
            :src="require(`@/assets/images/merch/2022/03.jpg`)"
            />
          </div>
        </div>
        <div class="offset-0 col-md-4 offset-sm-1 col-12 ocb-padding-top-50 ocb-padding-top-lg-100">
          <div class="row ocb-padding-top-100">
            <div class="col-12">
              <div class="col-2 omhms-content-block-decorline"></div>
              <p class="omhms-text omhms-text-h2-regular">Упаковка:</p>
              <p class="omhms-text omhms-text-regular">Запаковано це все в крафтовий конверт із власною печаткою!</p>
              <p class="omhms-text omhms-text-caption">Власна! Печатка! OMHMS! Це ж ааааа!! <br><i>*емоції одмєна*</i></p>
            </div>
          </div>
        </div>
      </div>

      <!-- DISCLAIMER -->
      <div class="row omhms-content-block justify-content-center" id="feature-disclaimer">
        <div class="col-xl-5 col-sm-8 margin-top center-block text-center justify-content-center">
          <p class="omhms-text omhms-text-topcap">Логістика</p>
          <p class="omhms-text omhms-text-caption  omhms-text-color-80">Доставка по Україні – безкоштовна ❤</p>
        </div>
      </div>
      <!-- END OF DISCLAIMER -->

    </div>

  </div>




</template>

<script>
import PageHeader from "@/components/PageHeader"
import CustomButton from "@/components/CustomButton.vue"
import { mapGetters } from 'vuex'

export default {
  name: 'Merch',
  components: {
    PageHeader,
    CustomButton
  },
  props: {
  },
  data() {
    return {
      title: "Сувенірна продукція"
      
    }
  },
  mounted() {
    this.images = document.getElementsByClassName("omhms-image");
    this.customTopOffset = -50;
    this.$isMobile() ? this.mobileMultiplier = 0.5 : this.mobileMultiplier = 1
    window.addEventListener('scroll', this.parallaxTheMainImages);
  },
  unmounted() {
    window.removeEventListener('scroll', this.parallaxTheMainImages);
  },
  computed: {
    ...mapGetters({
      check: "auth/check"
    })
  },
  methods: {
    parallaxTheMainImages() {
      // console.log("i'm working")
      console.log(this.$isMobile() + " " + this.mobileMultiplier);
      for (var i = 0; i < this.images.length; i++) {
        var top = this.images[i].getBoundingClientRect().top;
        if (top > -500 & top < 1000) {
          this.images[i].style.top = (this.customTopOffset + top/10 * this.images[i].dataset.pm) * this.mobileMultiplier + "px";
        }
      }
    }
  }
}
</script>