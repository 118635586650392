<template>
  <div class="service-offline">
    <strong>Сервера OMHMS зараз недоступні.</strong> Скоріше за все, хост-комп’ютер зараз без світла. Йобана русня.
  </div>
</template>

<script>

export default {
  name: "ServiceOffline",
  components: {
  },
  computed: {
  }
}
</script>
.vue