import camelCase from "lodash/camelCase"

// Load store modules dynamically.

const requireModule = require.context(".", false, /\.js$/) //extract js files inside modules folder.
const modules = {}

requireModule.keys().forEach((fileName) => {
  if (fileName === "./index.js") return //reject the index.js file.

  const moduleName = camelCase(fileName.replace(/(\.\/|\.js)/g, ""))

  modules[moduleName] = requireModule(fileName).default

  if (modules[moduleName].namespaced === undefined) {
    modules[moduleName].namespaced = true
  }
})
export default modules