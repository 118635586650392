<template>
  <img class="icon" :src="require(`../assets/images/svg/${icon}.svg`)"/>
</template>

<script>

export default {
  name: 'SvgIcon',
  props: {
    icon: {
      type: String,
      required: true
    },
  },
  data() {
    return {
    }
  }
}
</script>