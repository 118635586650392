import dayjs from 'dayjs'

const formatDate = (date, format = 'YYYY-MM-DD') => {
  const parsedDate = dayjs(date)
  return parsedDate.format(format)
}

const parseDate = (dateString, format = 'YYYY-MM-DD') => {
  const parsedDate = dayjs(dateString, format)
  return parsedDate?.$d
}

const firstLetterCapitalized = (str) => {
  if (!str) return ""
  const [firstLetter, ...rest] = str
  return firstLetter.toUpperCase() + rest.join("").toLowerCase()
}

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

const monthNames = () => ['Січень','Лютий','Березень','Квітень','Травень','Червень','Липень','Серпень','Вересень','Жовтень','Листопад','Грудень']

const onlyNumber = (event) => {
  if (!/\d/.test(event.key)) return event.preventDefault()
}

const ifUrlExist = (url, callback) => {
  const timer = setTimeout(function(){
    callback(false);
  },5000)

  var img = document.createElement("img");
  img.onload = function() {
    clearTimeout(timer);
    callback(true);
  }

  img.onerror = function() {
    clearTimeout(timer);
    callback(false);
  }

  img.src = `${url}/favicon.png`;
}

export {
  delay,
  monthNames,
  formatDate,
  parseDate,
  onlyNumber,
  firstLetterCapitalized,
  ifUrlExist,
}