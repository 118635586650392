import axios from "axios"
import Cookies from "js-cookie"
import isEmpty from "lodash/isEmpty"

import { apiUrl } from "@/config"
import * as types from '../mutation_types'

import User from "@/models/User"

const cookies_vars = {
	username: "omhms_username",
	token: "omhms_token"
}

const state = {
	user: User.create({userName: Cookies.get(cookies_vars.username)}),
	token: Cookies.get(cookies_vars.token),
	error: null
}

const getters = {
	user(state) { return state.user },
	token(state) { return state.token },
	error: (state) => state.error,
	check: (getters) => !isEmpty(getters.token) && !isEmpty(getters.user),
	cookie: (getters) => !isEmpty(getters.token) && getters.user && getters.user.userName
}

const actions = {
	async loginCookie( { dispatch, getters }) {
		await dispatch("login", {userName: getters.user.userName, hash: getters.token })
	},

	async login( { commit, dispatch }, props) {
		commit(types.SET_ERROR, null)
		try {
			const { data } = await axios.post(
				apiUrl("login"),
				props
			)
			const newUser = User.create(data.data.login)
			commit(types.SET_USER, newUser)
			commit(types.SAVE_TOKEN, data.data.login.token)
		} catch (e) {
			console.log(e)
			commit(types.SET_ERROR, e)
			dispatch("logout")
		}
	},
	
	logout({ commit, dispatch }) {
		commit(types.LOGOUT)
		dispatch("profile/set", null, {root: true})
	},

	unsetError({ commit }) {
		commit(types.UNSET_ERROR)		
	}
}

const mutations = {
	[types.SAVE_TOKEN](state, token) {
		state.token = token
		Cookies.set(cookies_vars.token, token, { expires: 365 })
	},

	[types.LOGOUT](state) {
		state.token = ""
		state.user = {}
		Cookies.remove(cookies_vars.username)
		Cookies.remove(cookies_vars.token)
	},

	[types.SET_USER](state, user) {
		state.user = user
		Cookies.set(cookies_vars.username, user.userName, { expires: 365 })
	},

	[types.SET_ERROR](state, error) {
		state.error = error
	},
	
	[types.UNSET_ERROR](state) {
		state.error = null
	}
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
