<template>
  <div class="rcon-tab">
    <div class="omhms-content-block console-logs rcon__with-scroll-bar" :ref="'consoleLogs' + id">
      <p
        v-for="(line, index) in consoleLines"
        :key="index"
        class="omhms-text omhms-text-console"
      >{{line}}</p>
    </div>
    <div class="omhms-content-block custom-rcon">
      <div class="rcon-logs rcon__with-scroll-bar" :ref="'rconLogs' + id">
        <p
          v-for="(line, lineIndex) in rconLines"
          :key="lineIndex"
          class="omhms-text omhms-text-console"
          :class="[
            {'command': line.type === 'command'},
            {'response': line.type === 'response'}
          ]"
        >
        <template v-if="line.text">
          {{line.text}}
        </template>
        <template v-if="line.data">
          <span
            v-for="slice, sliceIndex in line.data"
            :key="sliceIndex"
            :class="`omhms-text-console_${slice.color}`"
          >
            {{slice.text}}
          </span>
        </template>
        </p>
      </div>
      <div class="rcon__inputs">
        <input
          type="text"
          :value="inputValue"
          :disabled="!server.isOnline"
          :placeholder="server.isOnline ? '> console' : '> server is offline'"
          @keyup.up="onUpArrowKeypress"
          @keyup.down="onDownArrowKeypress"
          @keypress.enter="enterCommand"
          @input="onInput"
        />
        <CustomButton
          value="Запустить"
          :disabled="server.isOnline"
          @click="launchServer(server.id)"
        ></CustomButton>
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from "@/components/CustomButton.vue"
import { mapActions, mapGetters } from 'vuex'

export default {
  name: "RconTab",
  components: {
    CustomButton
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    server: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      commands: [""],
      currentCommandIndex: 0
    }
  },
  watch: {
    consoleLines: {
      handler: function () {
        this.handleLogsScroll(this.consoleLogsRef)
      },
      deep: true
    },
    rconLines: {
      handler: function () {
        this.handleLogsScroll(this.rconLogsRef)
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      servers: "rcon/servers"
    }),

    inputValue() {
      return this.commands[this.currentCommandIndex] ?? ""
    },

    consoleCommandsLastIndex() {
      return this.commands.length - 1
    },

    consoleLogsRef() {
      return this.$refs['consoleLogs' + this.id]
    },
    rconLogsRef() {
      return this.$refs['rconLogs' + this.id]
    },

    consoleLines() {
      return this.server.consoleLines
    },

    rconLines() {
      return this.server.rconLines
    }
  },
  mounted() {
    this.consoleLogsRef.scrollTop = this.consoleLogsRef.scrollHeight
  },
  methods: {
    ...mapActions({
      sendRconCommand: "rcon/sendCommand",
      launchServer: "rcon/launchServer"
    }),

    handleLogsScroll(el) {
      const scrollDiff = el.scrollHeight - el.scrollTop
      if (el.clientHeight == scrollDiff) {
        this.$nextTick(() => {
          el.scrollTop = el.scrollHeight
        })
      }
    },

    onUpArrowKeypress() {
      if (this.currentCommandIndex > 0) {
        this.currentCommandIndex -= 1
      }
    },

    onDownArrowKeypress() {
      if (this.currentCommandIndex < this.commands.length - 1) {
        this.currentCommandIndex += 1
      }
    },

    onInput(event) {
      this.commands[this.consoleCommandsLastIndex] = event.target.value
    },

    enterCommand(event) {
      const value = event.target.value
      if (!value) return
      this.sendRconCommand({
        serverId: this.id,
        command: value
      })
      this.commands.pop()
      this.commands.push(value)
      this.commands.push("")
      this.currentCommandIndex = this.consoleCommandsLastIndex
    },
  }
}

</script>