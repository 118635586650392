import { parseISO } from "date-fns"

export default class User {
  constructor(props) {
    this.userName = props.userName ?? ""
    this.realName = props.realName ?? ""
    this.regDate = props.regDate ? parseISO(props.regDate) : ""
    this.userGroup = props.userGroup ?? ""
    this.personId = props.personId ?? ""
    this.registrationStatus = props.registrationStatus ?? ""
  }

  getJsonObj() {
    return {
      userName: this.userName,
      realName: this.realName,
      regDate: this.regDate,
      userGroup: this.userGroup,
      personId: this.personId
    }
  }

  static create(props) {
    return new User(props)
  }
}