import axios from "axios"
import * as types from '../mutation_types'
import { apiUrl } from "@/config"
import Payment from '@/models/Payment'

const state = {
  payments: [],
  error: null

}

const getters = {
  payments: (state) => state.payments,
  error: (state) => state.error,
  paymentByDate: (state) => (date) => {
    return state.payments.find(p => p.paidMonth === date?.month && p.paidYear === date?.year )
  }
}

const actions = {
  async get({ commit }, personId) {
    try {
      const { data } = await axios.get(apiUrl('payment'), { params: { personId } })
      commit(types.SET_PAYMENTS, data.data.payments.map(p => Payment.create(p)))
      commit(types.UNSET_ERROR)
    } catch (e) {
      commit(types.SET_ERROR, e)
    }
  },

  async create({ commit }, payment) {
    try {
      await axios.post(apiUrl('payment'), payment.getJsonObj())
      commit(types.UNSET_ERROR)
    } catch (e) {
      console.log(e)
      commit(types.SET_ERROR, e)
    }
  },

  async update({ commit }, payment) {
    try {
      await axios.put(apiUrl(`payment/${payment.id}`), payment.getJsonObj())
      commit(types.UNSET_ERROR)
    } catch (e) {
      console.log(e)
      commit(types.SET_ERROR, e)
    }
  },

  async delete({ commit }, payment) {
    try {
      await axios.delete(apiUrl(`payment/${payment.id}/hard`))
      commit(types.UNSET_ERROR)
    } catch (e) {
      console.log(e)
      commit(types.SET_ERROR, e)
    }
  }
}

const mutations = {
  [types.SET_PAYMENTS](state, payments) {
    state.payments = payments
  },

  [types.DELETE_PAYMENT](state, payment) {
    state.payments = state.payments.filter(p => p.id !== payment.id)
  },

  [types.SET_ERROR](state, error) {
    state.error = error
  },

  [types.UNSET_ERROR](state) {
    state.error = null
  }
}

export default {
 namespaced: true,
 state,
 getters,
 actions,
 mutations
}