\<template>
  <div class="container-fluid main-layout main-layout_no-margin page-noScrollPage">
    <div class="row page-noScrollPage__row">
      <div class="col">
        <iframe :src="iframeLink"></iframe>
      </div>
    </div>
  </div>
</template>


<script>

  export default {
    name: 'Stats',
    data() {
      return {
        title: "Статистика"   
      }
    },
    computed: {
      iframeLink() {
        const url = process.env.VUE_APP_MAIN_FRONT_ENDPOINT
        return `${url}:9998/`
      }
    }
  }

</script>
