import Question from "./Question"

export default class MultipleQuestion extends Question {
  constructor(props) {
    super(props)
    this.type = "multiple"
    this.options = props.options ?? []
  }

  update(index) {
    this.options.map(option => option.isSelected = false)
    this.options[index].isSelected = true
  }

  hasAnswer() {
    return this.options.filter( (option) => option.isSelected ).length
  }

  getAnswer() {
   return this.options.find( (option) => option.isSelected )?.value
  }

  getJsonObj() {
      let props = {
        type: this.type,
        options: this.options.map(o => {
          return {
            value: o.value,
            isSelected: o.isSelected
          }
        })
      }

      return super.getJsonObj(props)
  }
}