<template>
  <div class="profile-characters__item col-12 col-md-6 col-lg-4 col-xl-3">
    <div class="profile-character">
      <div class="profile-character__info">
        <div class="profile-character__info-item profile-character__info-item_main">
          <span>{{ character.realName }}</span>
          <span :class="[getUserGroupClass(character), 'profile-character__info-item_main_statusGroup']">{{ character.userGroup.toLowerCase() }}</span>
        </div>
        <div class="profile-character__info-item">
          <span class="profile-character__info-item_header">Зареєстрований:</span>
          <span>{{ character.regDate }}</span>
        </div>
        <div class="profile-character__info-item">
          <span class="profile-character__info-item_header">Останній логін:</span>
          <span>{{ character.lastLogin }}</span>
        </div>
        <div v-if="character.seasons" class="profile-character__info-item">
          <span class="profile-character__info-item_header">Сезони:</span>
          <span>{{ character.seasons }}</span>
        </div>
      </div>
      <div class="profile-character__is-main-character">
        <label
          :for="`custom-input__radio${index}`"
          class="custom-input__item custom-input__option"
        >
          <input
            type="radio"
            :id="`custom-input__radio$${index}`"
            :name="`character__radio-${index}`"
            :value="character.userName"
            :checked="isMainCharacter(character)"
            disabled
            v-model="profile.person.mainCharacter"
            class="custom-input__radio-input visually-hidden"
          >
          <span class="custom-input__radio"></span>
          <span class="custom-input__radio-label">Основний персонаж</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: "ProfileCharacterCard",
  props: {
    character: {
      required: true,
    },
    index: {
      tyme: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      profile: "profile/profile",
    }),
  },
  methods: {
    getUserGroupClass(character) {
      return `omhms-text__player-${character.userGroup.toLowerCase()}`
    },

    isMainCharacter(character) {
      return this.profile.person.mainCharacter === character.realName
    },
  },
}
</script>