import axios from "axios"

import { apiUrl } from '@/config'
import * as types from '../mutation_types'
import Registration from '@/models/registration/Registration'

const state = {
  form: {},
  error: null
}

const getters = {
  form: (state) => state.form,
  error: (state) => state.error
}

const actions = {
  async new({ commit }) {
    try {
      const { data } = await axios.get(
        apiUrl(`registration/new`)
      )
      commit(types.SET_FORM, new Registration(data.data.form))
      commit(types.UNSET_ERROR)
    } catch (e) {
      commit(types.SET_ERROR, e)
    }   
  },

  async get({ commit }, username) {
    try {
      const { data } = await axios.get(
        apiUrl(`registration/${username}`)
      )
      commit(types.SET_FORM, new Registration(data.data.form))
      commit(types.UNSET_ERROR)
    } catch (e) {
      commit(types.SET_ERROR, e)
    }
  },

  async submit( { commit, rootGetters }, form) {
    try {
      const registrationForm = new Registration(form)
      registrationForm.userName = rootGetters["auth/user"].realName
      const { data } = await axios.post(
        apiUrl("registration"),
        registrationForm.getJsonObj()
      )
      commit(types.SET_FORM, new Registration(data.data.form))
    } catch (e) {
      commit(types.SET_ERROR, e)
    }
  },

  async vote({ commit }, params) {
    try {
      const { data } = await axios.get(
        apiUrl(`registration/${params.userName}/vote`),
        {params: {vote: params.vote}}
      )
      commit(types.SET_FORM, new Registration(data.data.form))
    } catch (e) {
      commit(types.SET_ERROR, e)
    }
  },

  async approve({ commit }, params) {
    try {
      const { data } = await axios.get(
        apiUrl(`registration/${params.userName}/approve`),
        {params: {vote: params.vote}}
      )
      commit(types.SET_FORM, new Registration(data.data.form))
    } catch (e) {
      commit(types.SET_ERROR, e)
    }
  },

  update( { commit }, form) {
    commit( types.SET_FORM, form )
  },

}

const mutations = {
  [types.SET_FORM](state, form) {
    state.form = form
  },

  [types.SET_ERROR](state, error) {
    state.error = error
  },

  [types.UNSET_ERROR](state) {
    state.error = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}