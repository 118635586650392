<template>
  <div class="main-layout">
    <PageHeader :title="title"/>

    <div class="container omhms-content-container">
     <div class="row omhms-content-block justify-content-center">
      <div class="col-lg-8 col-md-10 col-12">
        <div class="spacer-32"></div>
        <p class="omhms-text omhms-text-h2-regular">Збірка від Одмєна:</p>
        <p class="omhms-text omhms-text-regular">Для тих, кому це може бути корисно або цікаво: збірка модів і доповнень від Одмєна, яку ви можете бачити на відосах, стрімах, скріншотах.</p>
        <p class="omhms-text omhms-text-caption">У нас дозволені аналоги вказаних модифікацій за наявності таких, моди/бібліотеки, необхідні для роботи зазначених, або будь-які інші косметичні моди для Miencraft.</p>
        <div class="row">
          <p><br></p>
          <p class="col-12 omhms-text omhms-text-regular"><span class="omhms-text-bold">Fabric</span>, та інші моди які зроблять гру на вашому калькуляторі трохи стібільніше та комфортніше:</p>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://curseforge.com/minecraft/mc-mods/fabric-api">Fabric API</a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/lithium">Lithium</a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/phosphor">Phosphor</a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/sodium">Sodium</a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/sodium-extra">Sodium Extra</a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/reeses-sodium-options">Reese's Sodium Options</a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/smooth-boot">Smooth Boot</a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/modmenu">Mod Menu</a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/tooltipfix">ToolTipFix</a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/irisshaders">Iris Shaders<span class="omhms-text-caption">(для роботи шейдерів)</span></a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/mixintrace">MixinTrace</a>
        </div>
        <div class="row">
          <p><br></p>
          <p class="col-12 omhms-text omhms-text-regular">Візуал та #покращення – <span class="omhms-text-bold">моди:</span></p>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/texture-packs/default-dark-mode">Default Dark Mode<span class="omhms-text-caption">Темна тема для GUI</span></a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://beta.curseforge.com/minecraft/mc-mods/visuality">Visuality</a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/fabrishot">Fabrishot</a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/better-third-person">Better Third Person</a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/cameraoverhaul">CameraOverhaul</a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/not-enough-animations">Not Enough Animations</a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/eating-animation-fabric">Eating Animation</a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/falling-leaves-fabric">Falling Leaves</a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/illuminations">Illuminations</a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/visuality">Visuality</a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/litematica">Litematica</a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/malilib">MaLiLib<span class="omhms-text-caption">для Litematica</span></a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/appleskin">AppleSkin<span class="omhms-text-caption">GUI для їжі</span></a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/armor-chroma-for-fabric">Armor Chroma<span class="omhms-text-caption">GUI для броні</span></a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://chocolateminecraft.com/minimap2.php">Xaero's Minimap</a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://chocolateminecraft.com/worldmap.php">Xaero's World Map</a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/advancements-enlarger/files/4104551">Advancements Enlarger</a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/cit-resewn">CIT Resewn<span class="omhms-text-caption">Для унік. текстур</span></a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/emotecraft">Emotecraft</a>
          <p><br></p>
          <p class="col-12 omhms-text omhms-text-regular">Візуал та #покращення – <span class="omhms-text-bold">ресурси:</span></p>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/customization/complementary-shaders"><span class="omhms-text-caption">Шейдери</span>Complementary Shaders</a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://drive.google.com/file/d/1DDEAwdVK90JUp2pZjSF4xtPYYWVXwOl4/view?usp=share_link"><span class="omhms-text-caption">Налаштування Complementary Shaders як в одмєна</span></a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/customization/complementary-reimagined"><span class="omhms-text-caption">Шейдери</span>Complementary Reimagined</a>
        </div>
        <div class="row">
          <p><br></p>
          <p class="col-12 omhms-text omhms-text-bold">Звук та "занурення":</p>
          <a class="col-6 col-sm-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/simple-voice-chat">Simple Voice Chat</a>
          <a class="col-6 col-sm-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/dynamic-sound-filters">Dynamic Sound Filters</a>
          <a class="col-6 col-sm-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/presence-footsteps">Presence Footsteps</a>
          <a class="col-6 col-sm-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/extrasounds">ExtraSounds</a>
        </div>
        <div class="row">
          <p><br></p>
            <p class="col-12 omhms-text omhms-text-bold">Сам майнкрафт, лол:</p>
            <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.minecraft.net/ru-ru/download">Minecraft.net<span class="omhms-text-caption">офіційний лаунчер, вибір справжніх поціновувачів</span></a>
            <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://download.curseforge.com/">CurseForge<span class="omhms-text-caption">залупа, а не лаунчер, громісткий і повільний</span></a>
            <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://multimc.org/#Download"
              data-bs-toggle="tooltip" data-bs-html="true" title="Але ж ми тут дивимось на модпак від одмєна? От власне, тому і залупа а не лаунчер."
              v-b-tooltip.hover
            >
              MultiMC
              <span class="omhms-text-caption">теж залупа, а не лаунчер, але всі чогось радять*</span>
            </a>
            <a 
              class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://llaun.ch/uk"
              data-bs-toggle="tooltip" data-bs-html="true" title="Це - лаунчер з відкритим вихідним кодом, але, наскільки я зрозумів, від російсько-турецького розробника 🤷🏼‍♂️. Лаунчер має підтримку української мови, безкоштовний і не був помічений в зашкварах. Найзручніший з усіх що я коли-небудь юзав."
              v-b-tooltip.hover
            >
              Legacy Launcher
              <span class="omhms-text-caption">одмєн юзає цей *</span>
            </a>
        </div>
      </div>
      <div class="col-lg-8 col-md-10 col-12 omhms-text-color-80">
        <div class="spacer-32"></div>
        <div class="spacer-32"></div>
        <div class="spacer-32"></div>
        <div class="col-2 omhms-content-block-decorline"></div>
        <p class="omhms-text omhms-text-h2-regular omhms-text-color-95">Будь-ласка,</p>
        <p class="omhms-text omhms-text-regular">
          Качайте моди і ресурси тільки з їх офіційних (переважно англомовних) джерел:
          <a class="omhms-text omhms-text-link" href="https://modrinth.com/">modrinth.com</a>,
          <a class="omhms-text omhms-text-link" href="https://www.curseforge.com/">curseforge.com</a>,
          <a class="omhms-text omhms-text-link" href="https://github.com/">github.com</a>,
          <a class="omhms-text omhms-text-link" href="https://www.planetminecraft.com/">planetminecraft.com</a>,
           та інші, схожі.
        </p>
        <p class="omhms-text omhms-text-regular">На цих ресурсах не має бути мільйонів кнопок скачати, не має бути чогось над-то підозрілого.</p>
        <p class="omhms-text omhms-text-bold">Лише ви самі віоповідальні за свою безпеку в інтернеті, дбайте і пам'ятайте про це!</p>
      </div>
    </div>
   </div>
  </div>

</template>
<script>
  import PageHeader from "@/components/PageHeader"


  export default {
    name: 'Modpack',
    components: {
      PageHeader
    },
    props: {
    },
    data() {
      return {
        title: "Збірка модів від одмєна"
      }
    }
  }
</script>

