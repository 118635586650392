<template>
  <div class="page-noScrollPage rcon">
    <b-tabs>
      <b-tab
        v-for="(server, serverIndex) in servers"
        :key="serverIndex"
      >
        <template #title>
          <div class="rcon__tab-title">
            <span>{{server.name}}</span>
            <span :class="[
              'rcon__server-status',
              {'rcon__server-online' : server.isOnline}
            ]"/>
          </div>
        </template>
        <RconTab
          :id="server.id"
          :server="server"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import RconTab from '@/components/RconTab'

import configureServerNotificationEvent from '@/events'
import { mapGetters, mapActions } from 'vuex'

let events = null

export default {
  name: 'Rcon',
  components: {
    RconTab
  },
  data() {
    return {
      title: "Rcon"
    }
  },
  computed: {
    ...mapGetters({
      servers: "rcon/servers"
    })
  },
  async created() {
    events = configureServerNotificationEvent()
    await this.getRconServersInfo()
  },

  beforeUnmount() {
    events.closeEventSourse()
  },
  methods: {
    ...mapActions({
      getRconServersInfo: "rcon/getServersInfo"
    })
  }
}

</script>
