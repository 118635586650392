import { createRouter, createWebHistory} from "vue-router"
import routes from './routes'

const routeMiddleware = resolveMiddleware(
  require.context("../middlewares", false, /.*\.js$/)
)

const router = createRouter({
  history: createWebHistory(),
  hash: false,
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return {selector: to.hash}
    }
    return { left: 0, top: 0 }
  }
})

router.beforeEach(async (to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)
  if (nearestWithTitle) document.title = `OMHMS - ${nearestWithTitle.meta.title}`

  routeMiddleware["isMainAppLive"](to, from, next)

  if (to.meta.middleware && routeMiddleware[to.meta.middleware]) {
    routeMiddleware[to.meta.middleware](to, from, next)
    return
  }

  next();
});

function resolveMiddleware(requireContext) {
  return requireContext
    .keys()
    .map((file) => [file.replace(/(^.\/)|(\.js$)/g, ""), requireContext(file)])
    .reduce(
      (guards, [name, guard]) => ({ ...guards, [name]: guard.default }),
      {}
    )
}

export default router