<template>
  <div
    class="custom-input"
    :class="[{'custom-input_date': isDate}]"
  >
    <div class="custom-input__item custom-input__title">
      <label :for="`custom-input_${id}`"> 
        <slot/>
        <span 
          v-if="optional"
          class="custom-input__optional-title"
        >
          {{ optionalTitle }}
        </span>
      </label>
    </div>      
    <div class="custom-input__item  custom-input__date-item" :class="customClass">
      <template v-if="isDate">
        <input
          type="date"
          class="custom-input__input"
          :id="`custom-input_${id}`"
          :placeholder="placeholder"
          :disabled="disabled"
          min="1900-01-01"
          :max="new Date().toISOString().split('T')[0]"
          v-model="inputValue"
          @input="enteredValue"
          @focusout="onFocusLeave"
        >
        <SvgIcon
          icon="calendar"
          class="custom-input__icon"
        />
      </template>
      <template v-else-if="type === 'password'">
        <input
          type="password"
          class="custom-input__input"
          :class="[
            {'custom-input__with-image': image},
          ]"
          :id="`custom-input_${id}`"
          :placeholder="placeholder"
          :pattern="pattern"
          :disabled="disabled"
          :minlength="minlength"
          :maxlength="maxlength"
          v-model="inputValue"
          @input="enteredValue"
          @focusout="onFocusLeave"
          @keypress.enter="finished"
          @keypress="onKeyPress"
        >
        <slot name="image">
          <SvgIcon
            v-if="image"
            :icon="image"
            class="custom-input__icon"
            @click="iconClicked"
          />
        </slot>
      </template>
      <template v-else>
        <textarea
          :type="type"
          :rows="rows"
          ref="textarea"
          class="custom-input__input"
          :class="[
            {'custom-input__with-image': image},
            {'custom-input__input-big': rows > 1}
          ]"
          :id="`custom-input_${id}`"
          :placeholder="placeholder"
          :pattern="pattern"
          :disabled="disabled"
          :minlength="minlength"
          :maxlength="maxlength"
          v-model="inputValue"
          @input="enteredValue"
          @focusout="onFocusLeave"
          @keypress.enter="finished"
          @keypress="onKeyPress"
        >
        </textarea>
        <slot name="image">
          <SvgIcon
            v-if="image"
            :icon="image"
            class="custom-input__icon"
            @click="iconClicked"
          />
        </slot>
      </template>

    </div>
    <div v-if="subtitle" class="custom-input__item custom-input__subtitle">{{ subtitle }}</div>
  </div>
</template>

<script>
import SvgIcon from "@/components/SvgIcon"
import debounce from "lodash/debounce"
import { onlyNumber } from "@/helpers"

export default {
  mounted() {
    this.setRows();
  },
  name: 'CustomInput',
  components: {
    SvgIcon
  },
  props: {
    id: {
      type: String,
      required: true
    },
    customClass: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "text"
    },
    optional: {
      type: Boolean,
      default: false
    },
    pattern: {
      type: String,
      default: null
    },
    subtitle: {
      type: String,
      default: ""
    },
    answer: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    minlength: {
      type: Number,
      default: -1
    },
    maxlength: {
      type: Number,
      default: -1
    },
    image: {
      type: String,
      default: ""
    },
    numeric: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      optionalTitle: " (не обов'язково)",
      rows: "1"
    }
  },
  created() {
    this.inputValue = this.answer
  },
  watch: {
    answer() {
      this.inputValue = this.answer
    },
  },
  computed: {
    isDate(){
      return this.type === 'date'
    },
  },
  methods: {
    enteredValue: debounce(function()  {
      this.$emit('onUpdate', this.getValidValue())
    }, 100),

    onKeyPress(event) {
      if (this.numeric) {
        this.onlyNumber(event)
      }
    },

    onFocusLeave() {
      this.$emit('onFocusLeave', this.getValidValue())
    },

    getValidValue() {
      const input = document.getElementById(`custom-input_${this.id}`)
      return (input && input.checkValidity()) ? this.inputValue : ""
    },

    finished() {
      this.$emit('onSubmit')      
    },

    iconClicked() {
      if (!this.disabled) {
        this.$emit('iconClicked')
      }
    },

    onlyNumber,

    /*
    Вєлічайшьіє умьі чєловечіства роботолі над цим кодом.
    НЕ РЕФАКТОРИТИ, БАН.
    */
    calculateCharsInRow(text = "а", chars = 1) {
      const textarea = document.getElementById(`custom-input_${this.id}`);
      const computedStyle = window.getComputedStyle(textarea);
      const charWidth = this.measureCharWidth(computedStyle.font, text, chars);
      const textareaWidth = textarea.offsetWidth - parseFloat(computedStyle.paddingLeft) - parseFloat(computedStyle.paddingRight);
      const charsInRow = Math.floor(textareaWidth / charWidth);

      return charsInRow;
    },

    measureCharWidth(font, text = "а", chars = 1) {
      const testContainer = document.createElement("div");

      testContainer.style.visibility = "hidden";
      testContainer.style.position = "absolute";
      testContainer.style.width = "auto";
      testContainer.style.height = "auto";
      testContainer.style.whiteSpace = "nowrap";
      testContainer.style.font = font;
      testContainer.innerHTML = text;

      document.body.appendChild(testContainer);
      const charWidth = testContainer.offsetWidth / chars;
      document.body.removeChild(testContainer);

      return charWidth;
    },

    setRows() {

      var text = document.getElementById(`custom-input_${this.id}`).value;
      var charCount = document.getElementById(`custom-input_${this.id}`).value.length;

      var rowsCount = 1;

      rowsCount = Math.ceil( charCount / this.calculateCharsInRow(text, charCount)) || 1;
      rowsCount = Math.ceil( (charCount + rowsCount) / this.calculateCharsInRow(text, charCount)) || 1; // чіста па пріколу фіксимо можливий баг коли доданий дефіс - поломає всю маліну

      this.rows = rowsCount;

    }

  }
}
</script>