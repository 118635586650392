<template>
  <div class="col-md-auto col-sm-6 col-12 filter-column">
    <div><span class="omhms-text omhms-text-caption">{{ title }}</span></div>
    <div v-for="(filter, index) in options" :key="index">
      <span
        class="filter-item"
        :class="[
          `${itemClass}-${filter.id}`,
          { 'filter-item-disabled': !hasAll && !value.includes(filter.id) }
        ]"
        @click="updateFilter(filter.id)"
      > {{ filter.title }} </span>
    </div>
    <div class="filter-bottom-wrapper">
      <span
        v-if="!hasAll"
        class="filter-bottom-optoin"
        @click="setAllFilter"
      > Вибрати всі </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlayersFilter',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
    itemClass: {
      type: String,
      default: "",
    },
    allowEmpty: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    hasAll() {
      return this.value.includes("all")
    },
  },
  methods: {
    updateFilter(id) {
      let filter = [...this.value]

      // if all filters were selected before
      if (filter.includes("all")) {
        filter = this.options
          .filter(x => x.id !== id)
          .map(x => x.id)
      } else {
        if (filter.includes(id)) {
          // item is already present in the filter - drop it
          if (filter.length === 1 && !this.allowEmpty) {
            return
          }
          const index = filter.findIndex(x => x === id)
          filter.splice(index, 1)
        } else {
          // item not in the list - add it or replace the whole list with 'all' value if the list is full
          filter.length < this.options.length - 1 ? filter.push(id) : filter = ["all"]
        }

      }

      this.$emit("updateValue", filter)
    },

    setAllFilter() {
      this.$emit("updateValue", ["all"])
    }
  }
}
</script>
