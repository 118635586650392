<template>
  <div class="container-fluid main-layout main-layout_no-margin page-not-found">
    <div class="row page-not-found-row">
      <div class="col">
        <div class="text-404">
          <p class="title-404">404</p>
          <p class="h2-404">Дідько, ти що написав оце таке?</p>
          <p class="h2-404"><span class="userpath">{{userpath}}</span></p>
          <p class="paragraph-404">У нас немає такої сторінки!</p>
        </div>
        <CustomButton isClear value="На головну?" @clicked="$router.push({name: 'main'})"/>
      </div>
    </div>
  </div>
</template>

<script>

import CustomButton from "@/components/CustomButton.vue"

export default {
  name: 'Not found',
  components: {
    CustomButton
  },
  props: {
  },
  data() {
    return {
      title: "404",
      routeLenghtToTrim: 12
    }
  },
  computed: {
    userpath() {
      var route = (this.$route.params && this.$route.params.catchAll)
        ? this.$route.params.catchAll : ""
      var newRoute = route.substring(
        0, 
        (route.length > this.routeLenghtToTrim) ? this.routeLenghtToTrim : route.length
      ) 
      newRoute = newRoute + ((route.length > this.routeLenghtToTrim) ? "..." : "");
      return newRoute;
    }
  }
}
</script>
