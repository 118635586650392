export default class Question {
	constructor(props) {
		this.setDefaultData()

		if (!props) return

		this.text = props.text ?? ""
		this.tag = props.tag ?? ""
		this.optional = props.optional ?? false
	}

	update(answer) {
		alert(`Question of type ${this.type} must have it's own update function to handle the answer: ${answer}`)
	}

	hasAnswer() {
		alert(`Question of type ${this.type} must have it's own hasAnswer function`)		
	}

	getJsonObj(params) {
		const data = {
			text: this.text,
			tag: this.tag,
			optional: this.optional
		}

		Object.assign(data, params)
		return data
	}

	setDefaultData() {
		this.text = ""
		this.tag = ""
		this.optional =	false
	}
}