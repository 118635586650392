import { parseISO } from "date-fns"
import Payment from "@/models/Payment"

export default class Person {
  constructor(props) {
    this.set(props)
  }

  set(props) {
    this.setDefaultData()

    if (!props) {return}

    this.discordTag = props.discordTag ?? ""
    this.email = props.email ?? ""
    this.id = props.id ?? ""
    this.joinDate = props.joinDate ? parseISO(props.joinDate) : ""
    this.mainCharacter = props.mainCharacter ?? ""
    this.newbieDate = props.newbieDate ? parseISO(props.newbieDate) : ""
    this.playerDate = props.playerDate ? parseISO(props.playerDate) : ""
    this.realName = props.realName ?? ""
    this.registrationStatus = props.registrationStatus ?? "created"
    this.rubiconDate = props.rubiconDate ? parseISO(props.rubiconDate) : ""
    this.tgLink = props.tgLink ?? ""
    this.fbLink = props.fbLink ?? ""

    this.characters = props.characters ?? []
    this.payments = props.payments?.map(p => Payment.create(p)) ?? []
  }

  setDefaultData() {
    this.discordTag = ""
    this.email = ""
    this.id = ""
    this.joinDate = ""
    this.mainCharacter = ""
    this.newbieDate = ""
    this.playerDate = ""
    this.realName = ""
    this.registrationStatus = "created"
    this.rubiconDate = ""
    this.tgLink = ""
    this.fbLink = ""
    this.characters = []
    this.payments = []
  }

  get currentMonthPayment() {
    const newDate = new Date()
    return this.payments.find(payment => payment.paidMonth === newDate.getMonth() && payment.paidYear === newDate.getFullYear())
  }

  get prevMonthPayment() {
    const newDate = new Date()
    newDate.setMonth(newDate.getMonth() - 1)
    return this.payments.find(payment => payment.paidMonth === newDate.getMonth() && payment.paidYear === newDate.getFullYear())
  }

  get userGroup() {
    return this.characters.find(c => c.realName === this.mainCharacter)?.userGroup ?? "DEFAULT"
  }

  get subscriptionLevel() {
    const level = this.currentMonthPayment?.subscriptionLevel ?? null
    return subscriptionLabels.find(l => l.value === level) ?? subscriptionLabels[0]
  }

  static create(props) {
    return new Person(props)
  }

  matchesFilters(filters) {
    let status = true
    if (!filters.registrationStatus.includes("all")) {
      status = filters.registrationStatus.includes(this.registrationStatus)
    }

    let group = true
    if (!filters.playerGroup.includes("all")) {
      group = filters.playerGroup.includes(this.userGroup.toLowerCase())
    }

    let lastSubscription = true
    if (filters.lastSubscription.length > 0) {
      lastSubscription = false

      if (filters.lastSubscription.includes("currentMonth") || filters.lastSubscription.includes("all")) {
        lastSubscription = !!this.currentMonthPayment
        if (lastSubscription && !filters.subscriptionLevel.includes("all")) {
          const subscriptionLevelLabel = `tier${this.currentMonthPayment?.subscriptionLevel ?? 0}`
          lastSubscription = filters.subscriptionLevel.includes(subscriptionLevelLabel)
        }
      }
      if (filters.lastSubscription.includes("prevMonth") || filters.lastSubscription.includes("all")) {
        lastSubscription = !!this.prevMonthPayment
        if (lastSubscription && !filters.subscriptionLevel.includes("all")) {
          const subscriptionLevelLabel = `tier${this.prevMonthPayment?.subscriptionLevel ?? 0}`
          lastSubscription = filters.subscriptionLevel.includes(subscriptionLevelLabel)
        }
      }
    } else if (!filters.subscriptionLevel.includes("all")) {
      const subscriptionLevelLabel = `tier${this.currentMonthPayment?.subscriptionLevel ?? 0}`
      lastSubscription = filters.subscriptionLevel.includes(subscriptionLevelLabel)
    }

    return status && group && lastSubscription
  }
}

const subscriptionLabels = [
  {
    value: null,
    label: "Немає",
  },
  {
    value: 1,
    label: "Звичайна",
  },
  {
    value: 2,
    label: "Потужна",
  },
  {
    value: 3,
    label: "Могутня",
  },
]
