<template>
  <div class="profile-info col-xxl-4 order-xxl-1 order-lg-1 col-md-6 col-12">
    <div class="row">
      <span class="omhms-text omhms-text-h4-regular">Головне:</span>
    </div>

    <div class="profile-info-row">
      <div class="profile-info-row__title profile-info-row__title__first">omhms id:</div>
      <div class="profile-info-row__text">{{ profile?.person?.id ?? '--' }}</div>
    </div>

    <div class="profile-info-row">
      <div class="profile-info-row__title profile-info-row__title__first">Нікнейм:</div>
      <div class="profile-info-row__text">{{ profileUserName }}</div>
    </div>

    <div v-if="profile?.person?.realName" class="profile-info-row">
      <div class="profile-info-row__title profile-info-row__title__first">Реальне ім'я:</div>
      <div class="profile-info-row__text-bold">{{ profile.person.realName }}</div>
    </div>

    <div class="profile-info-row">
      <div class="profile-info-row__title profile-info-row__title__first">Дата реєстрації:</div>
      <div class="profile-info-row__text">{{ profileRegDate }}</div>
    </div>

    <div class="profile-info-row">
      <div class="profile-info-row__title profile-info-row__title__first">Статус анкети:</div>
      <div>
        <div class="profile-info-row__text__regStatus" :style="{ color: registrationStatusColor, borderColor: registrationStatusColor}">
          {{ registrationStatusTitle }}
        </div>
        <div v-if="canSeeRegistrationLink">
          <router-link
            :to="registrationLinkTo"
            class="profile-info-row__link profile-info-sublabel"
          >
            {{ registrationLinkTitle }}
          </router-link>
        </div>
      </div>
    </div>

<!--     <div class="profile-info-row">
      <div class="profile-info-row__title profile-info-row__title__first">Карбованці:</div>
      <a href="/subscription/#donat" target="_blank" class="profile-info-row__coins omhms-text-link__noline">
        <img
          class="omhms-image-coin"
          :src="require(`@/assets/images/donationIcons/coin.png`)"
        />
        <span class="omhms-text-bold profile-info-row__coins-value">100</span> 
      </a>
    </div> -->
 

    <div class="profile-info-row">
      <div class="profile-info-row__title profile-info-row__title__first">Перепустка:</div>
      <div>
        <div :class="getUserTierClass(profile)">
          {{ profile?.person?.subscriptionLevel?.label ?? 'Немає' }}
        </div>
        <div>
          <!-- <p class="omhms-text-caption profile-info-sublabel">Діє до: <strong>31 січня</strong></p> -->
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { format as formatDate } from "date-fns"

export default {
  name: "ProfileMainInfo",
  computed: {
    ...mapGetters({
      user: "auth/user",
      profile: "profile/profile",
    }),

    isPreview() {
      return this.$route.params?.username
    },

    profileUserName() {
      return this.isPreview ? this.$route.params.username : this.user.userName
    },

    profileRegDate() {
      if (!this.user?.regDate || (this.isPreview && !this.profile?.person?.joinDate)) { return "" }

      const date = this.isPreview ? this.profile?.person?.joinDate : this.user?.regDate

      return this.formatDate(date, "dd.MM.yyyy")
    },

    registrationStatusTitle() {
      if (!(this.profile?.registrationStatus)) {return ''}
      switch (this.profile.registrationStatus) {
        case 'created':
          return 'Не створена'
        case 'submitted':
          return 'На розгляді'
        case 'approved':
          return 'Опрацьована'
        case 'declined':
          return 'Відхилена'
        default:
          return ''
      }
    },

    registrationStatusColor() {
      if (!(this.profile?.registrationStatus)) {return ''}

      switch (this.profile.registrationStatus) {
        case 'created':
        case 'declined':
          return '#EB5757'
        case 'submitted':
          return '#F2994A'
        case 'approved':
          return '#27AE60'
        default:
          return 'white'
      }
    },

    canSeeRegistrationLink() {
      return this.isPreview ? this.$can('newbie') : true
    },

    registrationLinkTo() {
      return (this.profile?.registrationStatus == 'created') ?
        { name: 'registration' } :
        { name: 'registration', params: { username: this.profileUserName }}
    },

    registrationLinkTitle() {
      return (this.profile?.registrationStatus == 'created') ?
      'Заповнити анкету' :
      'Переглянути анкету'
    },

  },
  methods: {
    getUserTierClass(profile) {
      return profile?.person?.subscriptionLevel.value ? `profile-info-row__text__subscriptionStatus-${profile.person.subscriptionLevel.value}` : `profile-info-row__text__subscriptionStatus`
    },
    getUserTierColorClass(profile) {
      return profile?.person?.subscriptionLevel.value ? `profile-info-row__text__subscriptionColor-${profile.person.subscriptionLevel.value}` : `profile-info-row__text__subscriptionColor`
    },

    formatDate,
  }
}
</script>
