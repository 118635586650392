<template>
  <div class="main-layout main-layout_no-margin bg-end-noise">
    <div class="main-image">
      <div class="slider">
        <div class="slide__main"></div> 
        <div class="slide__main"></div> 
        <div class="slide__main"></div> 
        <div class="slide__main"></div> 
        <div class="slide__main"></div> 
      </div>
      <div class="main-image__text">
        <div class="main-image__text-subtitle">Твій приватний minecraft сервер</div>
        <img class="icon-logo__big" src="../assets/images/svg/logo.svg"/>
        <div class="main-image__text-update">ВАНІЛЬНИЙ* СЕРВЕР</div>
        <div class="main-image__text-ip">
          <div>
            <span>ip:</span><span class="main-image__text-ip__bold" @click="copyip" id="ipaddress">play.omhms.com</span><span>Версія:</span><span class="main-image__text-ip__bold">1.16.5 – 1.20.x</span>
          </div>  
        </div>
        <div class="main-image__text-buttons">
          <CustomButton :value="joinButtonTitle" @clicked="$router.push('profile')"/>
          <div class="spacer-16 spacer-16-horizontal"></div>
          <CustomButton isClear value="Детальніше" @clicked="scrollTo('zero_anchor')"/>
        </div>
      </div>
    </div>

    <div class="container omhms-content-container mainPageSwitcher"  id="zero_anchor">
      <div class="mainPageSwitcher-block">
          <!-- <router-link :to="{ name: 'main' }"> :savedPosition="true" -->
            <span class="mainPageSwitcher-tab-survival__active">Виживання</span>
          <!-- </router-link> -->
          <router-link :to="{ name: 'games' }" :savedPosition="true">
            <span class="mainPageSwitcher-tab-games">Міні-ігри</span> 
          </router-link>
      </div>
    </div>


    <div class="container omhms-content-container" id="first_anchor">
      <div class="row omhms-content-block ">
        <div class="col-lg-6 col-sm-12">
          <div class="col-2 omhms-content-block-decorline"></div>
          <span class="omhms-text omhms-text-h2-regular">Раді вітати на <span class="omhms-text omhms-text-h2-bold">OMHMS</span>!</span>
          <div class="spacer-24"></div>
          <p class="omhms-text omhms-text-regular"><span class="omhms-text omhms-text-bold">OilMan Home Minecraft Server</span> – це практично ванільний сервер для комфортного виживання та мирної гри, із чудовою та дружною спільнотою!</p>
          <div class="row margin-top">
            <div class="col offset-lg-2 offset-md-1">
              <p class="omhms-text omhms-text-italic omhms-text-color-95">Наш проект для тих, хто втомився від тягару "громадських" серверів. Ми стараємось бути нетоксичною, мало конфліктною, чесною та доброзичливою ігровою спільнотою гравців, які грають у своє задоволення і не заважають отримувати задоволення від гри іншим.</p>
              <CustomButton isClear :value="joinButtonTitle" @clicked="$router.push('profile')"/>
            </div>
          </div>
        </div>
        <div class="col-lg-5 offset-lg-1 offset-md-0 col-md-10 col-sm-12 margin-top-md-sm">
          <div class="omhms-image" :data-pm='1.4'>
            <img
              id="img1"
              class="omhms-image-stroke"
              alt="Hello, there!"
              :src="require(`@/assets/images/homepage/small/01.jpg`)"
            />
          </div>
        </div>
      </div>
      <div class="row omhms-content-block">
        <div class="col-lg-5 col-md-8 offset-lg-1 offset-md-3 order-lg-1 order-2 ocb-padding-top-50 ocb-padding-top-lg-0">
          <div class="omhms-image" :data-pm='0.8'>
            <img
              class="omhms-image-stroke"
              alt="Наш подход."
              :src="require(`@/assets/images/homepage/small/02.jpg`)"
            />
          </div>
        </div>
        <div class="col-lg-5 col-md-10 offset-lg-1 offset-md-1 order-lg-2 order-1">
          <p class="omhms-text omhms-text-topcap">ПРО ПРОЕКТ</p>
          <p class="omhms-text omhms-text-h2-regular">Підхід</p>
          <p class="omhms-text omhms-text-regular"><span class="omhms-text omhms-text-bold">OilMan Home Minecraft Server</span> – це сервер, що "будується" самими гравцями. Нам би хотілося бути якісним сервером для виживання і комфортної гри максимально без заборон та обмежень.</p>
          <p class="omhms-text omhms-text-regular">Ми цінуємо чесність та взаємоповагу, тому наш сервер – це комфортне місце для гравців, які розділяють наші принципи.</p>
        </div>
      </div>
      <div class="row omhms-content-block">
        <div class="col-lg-5 col-md-12">
          <div class="row omhms-content-block omhms-content-block-mb-50">
            <div class="col-12">
              <p class="omhms-text omhms-text-topcap">ПРО СЕРВЕР</p>
              <p class="omhms-text omhms-text-h2-regular">Ванілла "Плюс"</p>
              <p class="omhms-text omhms-text-regular"><span class="omhms-text omhms-text-bold">У нас хардкорне виживання</span>, без модів. Кількість мобів практично не урізана. Центральна економіка є, але вона не обов'язкова. "Консольної" валюти немає, бо є – "реальна" 👀. АФК ферми – не заборонені, наскільки дозволяє ядро серверу – Paper.</p>
            </div>
          </div><!-- 
          <div class="row omhms-content-block">
            <div class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-md-10 offset-md-2">
              <div class="omhms-image" :data-pm="1.4">
                <img
                  class="omhms-image-stroke"
                  alt="Виживання 1"
                  :src="require(`@/assets/images/homepage/small/04.jpg`)"
                />
              </div>
            </div>
          </div> -->
        </div>
        <div class="col-lg-5 col-md-8 offset-lg-1 offset-md-2 d-none d-lg-block ocb-padding-top-50">
          <div class="omhms-image"  :data-pm="0.4">
            <img
            class="omhms-image-stroke"
            alt="Виживання 2"
            :src="require(`@/assets/images/homepage/small/03.jpg`)"
            />
          </div>
        </div>
      </div>
      <div class="row omhms-content-block">
        <div class="col-xl-4 col-lg-5 col-12 ocb-padding-top-0 ocb-padding-top-lg-50">
          <p class="omhms-text omhms-text-topcap">ПРО СЕРВЕР</p>
          <p class="omhms-text omhms-text-h2-regular">Обмеження</p>
          <p class="omhms-text omhms-text-regular">У нас немає привату території, або захисту скринь. <span class="omhms-text omhms-text-bold">Ми не беремо чуже, та довіряємо один одному.</span></p>
          <p class="omhms-text omhms-text-regular">У нас немає плагінів, що обмежують гравця в ігровому процесі.</p>
          <p class="omhms-text omhms-text-caption">Звісно є ліміт світу та деякі ігрові умовності, але ви ж зрозуміли, так?</p>
        </div>
        <div class="col-xl-6 col-lg-5 offset-lg-1 col-md-11 offset-md-1 col-12 ocb-padding-top-50 ocb-padding-top-lg-0">
          <p class="omhms-text omhms-text-topcap">ПРО ПРОЕКТ</p>
          <p class="omhms-text omhms-text-h2-regular">Спільнота</p>
          <p class="omhms-text omhms-text-regular">У спільноті OMHMS завжди на першому місці були принципи свободи та взаємоповаги. Ми присікаємо токсичність та особистості в конфліктах.</p>
          <p class="omhms-text omhms-text-regular">Пам'ятайте головне: <span class="omhms-text omhms-text-bold">Ваші права закінчуються там, де починаються права іншого гравця.</span></p>
          <p class="omhms-text omhms-text-caption">Але порофлити з когось, звісно, інколи навіть треба ;)</p>
        </div>
      </div>
      <div class="row omhms-content-block">
        <div class="col-xl-5 col-lg-6 ocb-padding-top-0 ocb-padding-top-lg-50">
          <p class="omhms-text omhms-text-topcap">ПРО ГРОШІ</p>
          <p class="omhms-text omhms-text-h2-regular">Підписка</p>
          <p class="omhms-text omhms-text-regular">Сервер – приватний. Оплата "проходки" – це непереборний бар'єр для значної кількості гравців які не є нашою ЦА. Тож, що більше гравців ми відсіємо, тим безпечнішою буде наша спільнота.</p>
          <p class="omhms-text omhms-text-regular">Відбір досить простий – <span class="omhms-text omhms-text-bold">треба бути нормальним!</span></p>
          <CustomButton isClear value="Варіанти підписки" @clicked="$router.push('subscription')"/>
        </div>
        <div class="col-xl-6 col-lg-5 offset-lg-1 ocb-padding-top-50 ocb-padding-top-lg-0">
          <div class="omhms-image" :data-pm="1.7">
            <img
              class="omhms-image-stroke"
              alt="Підписка"
              :src="require(`@/assets/images/homepage/small/05.jpg`)"
            />
          </div>
        </div>
      </div>
      <div class="row omhms-content-block">
        <div class="col-xl-5 offset-xl-1 col-lg-4 order-lg-1 order-2 ocb-padding-top-50">
          <div class="omhms-image" :data-pm="0.9">
            <img
              class="omhms-image-stroke"
              alt="Підписка"
              :src="require(`@/assets/images/homepage/small/06.jpg`)"
            />
          </div>
        </div>
        <div class="col-xl-5 col-lg-7 offset-lg-1 order-lg-2 order-1 ocb-padding-top-lg-0">
          <p class="omhms-text omhms-text-topcap">ПРО ПРОЕКТ</p>
          <p class="omhms-text omhms-text-h2-regular">Правила</p>
          <p class="omhms-text omhms-text-regular"><span class="omhms-text omhms-text-bold">Правила потрібні для того, щоб їх дотримуватися!</span></p>
          <p class="omhms-text omhms-text-regular">Щоб підтримувати лампове та доброзичливе співтовариство одних самої ідей обмаль. У нас є свій <a href="https://omhms.notion.site/bb231f80a6e247d5b03c36295ac68846" class="omhms-text omhms-text-link" target="_blank">збір правил</a>, ціль яких запобігти можливі конфліктні ситуації, спірні моменти, та у разі необхідності врегулювати конфлікт.</p>
          <p class="omhms-text omhms-text-regular">І всеж, <span class="omhms-text omhms-text-bold">адміністратори сервера є найвищою формою правління на сервері</span>. Так, їхня думка та слова можуть бути засуджені та оскаржені спільнотою, останнє слово завжди за Одмєном*.</p>
          <p class="omhms-text omhms-text-caption">*Одмєн – вища форма життя на проекті, він же MrOilMan</p>
        </div>
      </div>
    </div>


<!-- 
    <div class="omhms-content-container-dark" id="How-to-join">
      <div class="block">
        <div class="row omhms-content-block justify-content-center">
          <div class="col-lg-6">
            <div class="row">
              <div class="col-12">
                <div class="col-2 omhms-content-block-decorline"></div>
                <p class="omhms-text omhms-text-topcap">ЯК ПОТРАПИТИ?</p>
                <p class="omhms-text omhms-text-h2-regular">План дій:</p>
                <p class="omhms-text omhms-text-regular"><span class="omhms-text-caption">1.</span> Зареєструватись на ігровому сервері. <span class="omhms-text-bold">Так, прямо в грі!</span></p>
                <p class="omhms-text omhms-text-regular"><span class="omhms-text-caption">2.</span> Погуляти центральним містом, познайомитися з гравцями.</p>
                <p class="omhms-text omhms-text-regular"><span class="omhms-text-caption">3.</span> Залогіниться на сайті та подати заявку на вхід.</p>
                <p class="omhms-text omhms-text-regular"><span class="omhms-text-caption">4.</span> Отримати схвалення* від ігрової спільноти.</p>
                <p class="omhms-text omhms-text-regular"><span class="omhms-text-caption">5.</span> Сплатити підписку.</p>
                <p class="omhms-text omhms-text-caption">*у нас децентралізована процедура відбору гравців, детальніше – нижче</p>
              </div>
            </div>
            <div class="row">
              <div class="col-10 offset-2 ocb-padding-top-50">
                <div class="omhms-image"  :data-pm="0.4">
                  <img
                  class="omhms-image-stroke"
                  alt="Подписка"
                  :src="require(`@/assets/images/homepage/small/07.jpg`)"
                  />
                </div>
              </div>
            </div>
          </div>

          <template v-if="!check">
            <div class="col-lg-5 offset-lg-1 ocb-padding-top-100">
              <div class="row">
                <div class="col-lg-12 col-sm-6 col-12">
                  <p class="omhms-text omhms-text-topcap">ЗАЯВКА НА ВХІД</p>
                  <p class="omhms-text omhms-text-h2-regular">Вже готовий?</p>
                  <CustomButton value="ЗАПОВНИТИ АНКЕТУ" @clicked="$router.push('profile')"/>
                </div>
                <div class="col-lg-10 offset-lg-2 col-sm-6 offset-sm-0 col-10 offset-2 ocb-padding-top-100">
                  <p class="omhms-text omhms-text-topcap">ХМ...</p>
                  <p class="omhms-text omhms-text-h2-regular">Ще не віришив?</p>
                  <CustomButton isClear value="БІЛЬШЕ ПОДРОБИЦЬ!" @clicked="scrollTo('second_anchor')"/>
                </div>
              </div>
            </div> 
          </template>
        </div>
      </div>
    </div>
-->


    <template v-if="!check">  
      <div class="omhms-content-container">
        <div class="row omhms-content-block omhms-content-block-cta">
          <div class="col-12 text-center">
            <p class="omhms-text omhms-text-h2-regular">Вже готовий вриватися у проект?</p>
            <p class="omhms-text omhms-text-regular">Реєструйся, заповнюй просту форму та швидше приєднуйся до OMHMS!</p>
            <CustomButton value="ЗАПОВНИТИ АНКЕТУ" @clicked="$router.push('profile')"/>
          </div>
        </div>
      </div>
    </template>



    <div class="container omhms-content-container" id="servers_main">
      <div class="row omhms-content-block ">

        <div class="col-lg-5">
          <div class="row">
            <div class="col-12">
              <!-- <div class="col-2 omhms-content-block-decorline"></div> -->
              <p class="omhms-text omhms-text-topcap">Платні сервери</p>
              <p class="omhms-text omhms-text-h2-regular">Основа OMHMS</p>
              <p class="omhms-text omhms-text-regular"><span class="omhms-text-caption">1.</span> Основний сервер виживання – <span class="omhms-text-bold">Main</span></p>
              <p class="omhms-text omhms-text-regular"><span class="omhms-text-caption">2.</span> Додатковий сервер виживання для ферм – <span class="omhms-text-bold">Factory</span></p>
              <p class="omhms-text omhms-text-regular"><span class="omhms-text-caption">3.</span> Власний креативний сервер – <span class="omhms-text-bold">Creactive</span></p>
              <p class="col-7 omhms-text omhms-text-caption">На основних серверах є ГУІ-менюшка яку можна відкрити командами <span class="omhms-text-console omhms-text-color-95">/gui</span>, <span class="omhms-text-console omhms-text-color-95">/profile</span>, <span class="omhms-text-console omhms-text-color-95">/menu</span></p>
              <p class="col-8 omhms-text omhms-text-caption">Гості проекту можуть відвідувати сервери виживання але не зможуть там грати, лише спостерігати за центральною  частиною світу. Подробиці про севрери <a target="_blank" href="season" class="omhms-text-link">тут, на сайті</a>, або в нашій <a target="_blank" href="https://omhms.notion.site/OMHMS-5-57d48a5ee5134a7fbd6123a08e0969e5" class="omhms-text-link">Базі Знань</a></p>
            </div>
          </div>
          <div class="row">
            <div class="col-10 offset-1 ocb-padding-top-50">
              <div class="omhms-image"  :data-pm="1.0">
                <img
                class="omhms-image-stroke"
                alt="Подписка"
                :src="require(`@/assets/images/homepage/fullhd/new01.png`)"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="offset-lg-1 col-lg-6">
          <div class="row">
            <div class="col-12 ocb-padding-top-100">
              <!-- <div class="col-2 omhms-content-block-decorline"></div> -->
              <p class="omhms-text omhms-text-topcap">Безкоштовні сервери</p>
              <p class="omhms-text omhms-text-h2-regular">Міні-ігри та Пісочниця</p>
              <p class="omhms-text omhms-text-regular"><span class="omhms-text-caption">1. </span> Сервер <router-link :to="{ name: 'games' }" :savedPosition="true"><span class="omhms-text-bold omhms-text-color-80 omhms-text-link">міні-ігр!</span></router-link> *</p>
              <p class="omhms-text omhms-text-regular"><span class="omhms-text-caption">2.</span> Сервер <span class="omhms-text-bold">"Пісочниця"!</span> **</p>
              <p class="col-7 omhms-text omhms-text-caption">* одразу на міні-ігри можна потрапити за допомогою команди <span class="omhms-text-console omhms-text-color-95">/lobby</span></p>
              <p class="col-7 omhms-text omhms-text-caption">** у пісочницю можуть потрапити всі, але треба "дещо"" зробити: <a target="_blank" href="https://omhms.notion.site/Sandbox-75df97b917a841b59c6499cb327448cc" class="omhms-text-link">інструкція тут</a>.</p>
            </div>
          </div>
          <div class="row">
            <div class="col-5 ocb-padding-top-100">
              <div class="omhms-image"  :data-pm="0.7">
                <img
                class="omhms-image-stroke"
                alt="Подписка"
                :src="require(`@/assets/images/homepage/fullhd/minigames.png`)"
                />
              </div>
            </div>
            <div class="col-5 offset-1">
              <div class="omhms-image"  :data-pm="1.7">
                <img
                class="omhms-image-stroke"
                alt="Подписка"
                :src="require(`@/assets/images/homepage/fullhd/sandbox.png`)"
                />
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
<!-- 
    <div class="omhms-content-container-dark" id="How-to-join">
      <div class="block">
        <div class="row omhms-content-block justify-content-center">
          <div class="col-lg-5">
            <div class="row">
              <div class="col-12">
                <p class="omhms-text omhms-text-topcap">Платні сервери</p>
                <p class="omhms-text omhms-text-h2-regular">Основа OMHMS</p>
                <p class="omhms-text omhms-text-regular"><span class="omhms-text-caption">1.</span> Основний сервер виживання – <span class="omhms-text-bold">Main</span></p>
                <p class="omhms-text omhms-text-regular"><span class="omhms-text-caption">2.</span> Додатковий сервер виживання для ферм – <span class="omhms-text-bold">Factory</span></p>
                <p class="omhms-text omhms-text-regular"><span class="omhms-text-caption">3.</span> Власний креативний сервер – <span class="omhms-text-bold">Creactive</span></p>
                <p class="col-7 omhms-text omhms-text-caption">На основних серверах є ГУІ-менюшка яку можна відкрити командами <span class="omhms-text-console omhms-text-color-95">/gui</span>, <span class="omhms-text-console omhms-text-color-95">/profile</span>, <span class="omhms-text-console omhms-text-color-95">/menu</span></p>
                <p class="col-8 omhms-text omhms-text-caption">Гості проекту можуть відвідувати сервери виживання але не зможуть там грати, лише спостерігати за центральною  частиною світу. Подробиці про севрери <a target="_blank" href="season" class="omhms-text-link">тут, на сайті</a>, або в нашій <a target="_blank" href="https://omhms.notion.site/OMHMS-5-57d48a5ee5134a7fbd6123a08e0969e5" class="omhms-text-link">Базі Знань</a></p>
              </div>
            </div>
            <div class="row">
              <div class="col-10 offset-1 ocb-padding-top-50">
                <div class="omhms-image"  :data-pm="1.0">
                  <img
                  class="omhms-image-stroke"
                  alt="Подписка"
                  :src="require(`@/assets/images/homepage/fullhd/new01.png`)"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="offset-lg-1 col-lg-5">
            <div class="row">
              <div class="col-12 ocb-padding-top-100">
                <p class="omhms-text omhms-text-topcap">Безкоштовні сервери</p>
                <p class="omhms-text omhms-text-h2-regular">Міні-ігри та Пісочниця</p>
                <p class="omhms-text omhms-text-regular"><span class="omhms-text-caption">1. </span> Сервер <span class="omhms-text-bold">міні-ігр!</span> *</p>
                <p class="omhms-text omhms-text-regular"><span class="omhms-text-caption">2.</span> Сервер <span class="omhms-text-bold">"Пісочниця"!</span> **</p>
                <p class="col-7 omhms-text omhms-text-caption">* одразу на міні-ігри можна потрапити за допомогою команди <span class="omhms-text-console omhms-text-color-95">/lobby</span></p>
                <p class="col-7 omhms-text omhms-text-caption">** у пісочницю можуть потрапити всі, але треба "дещо"" зробити: <a target="_blank" href="https://omhms.notion.site/Sandbox-75df97b917a841b59c6499cb327448cc" class="omhms-text-link">інструкція тут</a>.</p>
              </div>
            </div>
            <div class="row">
              <div class="col-5 ocb-padding-top-100">
                <div class="omhms-image"  :data-pm="0.7">
                  <img
                  class="omhms-image-stroke"
                  alt="Подписка"
                  :src="require(`@/assets/images/homepage/fullhd/minigames.png`)"
                  />
                </div>
              </div>
              <div class="col-5 offset-1">
                <div class="omhms-image"  :data-pm="1.7">
                  <img
                  class="omhms-image-stroke"
                  alt="Подписка"
                  :src="require(`@/assets/images/homepage/fullhd/sandbox.png`)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <template v-if="!check">  
      <div class="omhms-content-container">
        <div class="row omhms-content-block">
          <div class="col-12 text-center">
            <div class="spacer-32"></div>
            <div class="spacer-32"></div>
            <div class="spacer-32"></div>
            <p class="omhms-text omhms-text-h2-regular">Вже готовий вриватися у проект?</p>
            <p class="omhms-text omhms-text-regular">Реєструйся, заповнюй просту форму та швидше приєднуйся до OMHMS!</p>
            <CustomButton value="ЗАПОВНИТИ АНКЕТУ" @clicked="$router.push('profile')"/>
            <div class="spacer-32"></div>
            <div class="spacer-32"></div>
            <div class="spacer-32"></div>
          </div>
        </div>
      </div>
    </template> -->

    <div id="alertCopied" class="omhms-alert alert" role="alert">
      IP сервера скопійовано в буфер обміну!
    </div>
  </div>

</template>
<script>
import CustomButton from "@/components/CustomButton.vue"
import { mapGetters } from 'vuex'

export default {
  name: 'Main',
  components: {
    CustomButton
  },
  data() {
    return {title: "Головна"
    }
  },
  mounted() {
    this.images = document.getElementsByClassName("omhms-image");
    this.customTopOffset = -50;
    this.$isMobile() ? this.mobileMultiplier = 0.5 : this.mobileMultiplier = 1
    window.addEventListener('scroll', this.parallaxTheMainImages);
  },
  unmounted() {
    window.removeEventListener('scroll', this.parallaxTheMainImages);
  },
  computed: {
    ...mapGetters({
      check: "auth/check"
    }),
    
    joinButtonTitle() {
      return this.check ? 'В особистий кабінет' : 'Приєднатися'
    }
  },
  methods: {
    scrollTo(itemId) {
      if (!document.getElementById(itemId)
        || !document.getElementById(itemId).offsetTop) { return }

      var top = document.getElementById(itemId).offsetTop
      window.scrollTo(0, top)
    },
    parallaxTheMainImages() {
      // console.log("i'm working")
      console.log(this.$isMobile() + " " + this.mobileMultiplier);
      for (var i = 0; i < this.images.length; i++) {
        var top = this.images[i].getBoundingClientRect().top;
        if (top > -500 & top < 1000) {
          this.images[i].style.top = (this.customTopOffset + top/10 * this.images[i].dataset.pm) * this.mobileMultiplier + "px";
        }
      }
    },
    async copyip() {

      var ip = document.getElementById("ipaddress").innerHTML;

      try {
        await navigator.clipboard.writeText(ip);
        console.log('IP скопійовано ;)');
      } catch($e) {
        console.log('Якась халепа. IP не скопійовано, доведеться писати руцями самому :С');
        console.log($e);
      }

      const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

      (async () => {
        document.getElementById("alertCopied").style.opacity = 1;
        await delay(2000);
        document.getElementById("alertCopied").style.opacity = 0;
      })();

    }
  }
}



</script>
