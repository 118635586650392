import axios from "axios"

import * as types from '../mutation_types'
import { apiUrl } from "@/config"

import Person from "@/models/Person"

const state = {
  players: [],
  error: null
}

const getters = {
  players: (state) => state.players,
  error: (state) => state.error
}

const actions = {
  async get({ commit }) {
    commit(types.UNSET_ERROR)
    try {
      const { data } = await axios.get(apiUrl(`profile/find`))
      commit(types.SET_PLAYERS, data.data.players.map(p => Person.create(p)))
    } catch (e) {
      commit(types.SET_ERROR, e)
    }
  },
}

const mutations = {
  [types.SET_PLAYERS](state, players) {
    state.players = players
  },

  [types.SET_ERROR](state, error) {
    state.error = error
  },

  [types.UNSET_ERROR](state) {
    state.error = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}