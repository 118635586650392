<template>
  <div class="page-header container-fluid">
    <div class="container">
      <div class="row">
        <div class="col-auto">
          <template v-for="(bc, index) in breadcrumbs.parents" :key="index">
            <router-link
              :to="{ name: bc.to }"
              class="breadcrumbs-link__element-secondary"
            >{{ bc.title }}</router-link>
            <span class="breadcrumbs-link__element-slash">/</span>
          </template>
          <span class="breadcrumbs-link__element-current">{{ breadcrumbs.title }}</span>
        </div>
        <div class="col page-header__actions-wrapper">
          <span
            v-if="isProfilePage"
            data-bs-toggle="modal"
            data-bs-target="#profileEditModal"
            class="page-header__action page-header__profile col-auto"
          >
            Редагувати профіль
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13" stroke="#5E6A87" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M18.5 2.49998C18.8978 2.10216 19.4374 1.87866 20 1.87866C20.5626 1.87866 21.1022 2.10216 21.5 2.49998C21.8978 2.89781 22.1213 3.43737 22.1213 3.99998C22.1213 4.56259 21.8978 5.10216 21.5 5.49998L12 15L8 16L9 12L18.5 2.49998Z" stroke="#5E6A87" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

          </span>

          <span
            v-if="canLogout && check"
            @click="logoutAction"
            class="page-header__action col-auto"
          >
            Вийти
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M4.29289 4.29289C4.48043 4.10536 4.73478 4 5 4H9C9.55228 4 10 3.55228 10 3C10 2.44772 9.55228 2 9 2H5C4.20435 2 3.44129 2.31607 2.87868 2.87868C2.31607 3.44129 2 4.20435 2 5V19C2 19.7957 2.31607 20.5587 2.87868 21.1213C3.44129 21.6839 4.20435 22 5 22H9C9.55228 22 10 21.5523 10 21C10 20.4477 9.55228 20 9 20H5C4.73478 20 4.48043 19.8946 4.29289 19.7071C4.10536 19.5196 4 19.2652 4 19V5C4 4.73478 4.10536 4.48043 4.29289 4.29289Z" fill="#5E6A87"/>
              <path d="M9 11C8.44772 11 8 11.4477 8 12C8 12.5523 8.44772 13 9 13H18.5858L15.2929 16.2929C14.9024 16.6834 14.9024 17.3166 15.2929 17.7071C15.6834 18.0976 16.3166 18.0976 16.7071 17.7071L21.4356 12.9786L21.6634 12.7482C21.8709 12.5408 22.0118 12.2237 21.9989 11.952C21.9935 11.839 21.9694 11.7309 21.9296 11.6307C21.8808 11.5077 21.8066 11.3924 21.7071 11.2929L16.7071 6.29289C16.3166 5.90237 15.6834 5.90237 15.2929 6.29289C14.9024 6.68342 14.9024 7.31658 15.2929 7.70711L18.5858 11H9Z" fill="#5E6A87"/>
            </svg>
          </span>
        </div>
    </div>
  </div>  
</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'PageHeader',
  props: {
    title: {
    type: String,
    required: true
    },
    hideLogout: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({
      check: "auth/check"
    }),

    hasMeta() {
      return this.$route && this.$route.meta
    },

    hasBreadcrumb() {
      return this.hasMeta && this.$route.meta.breadcrumb
    },

    isProfilePage() {
      return this.$route.name === "profile"
    },

    canLogout() {
      if (this.hideLogout) return false
      if (!this.hasMeta) return false
      return this.$route.meta.canLogout
    },
    breadcrumbs() {
      if (!this.hasBreadcrumb) return {title: this.title, parents: []}

      var currentRoute = this.$route
      var bcArray = [currentRoute]
      while (currentRoute.meta?.breadcrumb?.parent) {
        const parent = this.$router.resolve({name: currentRoute.meta.breadcrumb.parent})
        if (parent) {
          bcArray.unshift(parent)
          currentRoute = parent
        }
      }

      return {
        title: bcArray.pop().meta.breadcrumb.title,
        parents: bcArray.map(bc => {return {title: bc.meta.breadcrumb.title, to: bc.name}})
      }
    }
  },
  methods: {
    ...mapActions({
      logout: "auth/logout"
    }),

    editProfileAction() {

    },

    logoutAction() {
      this.logout()
      if (this.$route.meta && this.$route.meta.middleware === 'auth') {
        this.$router.push({ name: 'login', query: { redirect: this.$route.path } })
      }
    }
  }
}
</script>
