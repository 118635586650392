import store from "@/store/index"

const rconServer = {
  eventType: "rconServer",
  handle: function (event) {
    const data = JSON.parse(event.data)
    store.dispatch("rcon/statusUpdate", data)
  }
}

export default rconServer
