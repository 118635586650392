import * as types from '../mutation_types'

const state = {
  error: null
}

const getters = {
  error: (state) => state.error
}

const actions = {
  setError({ commit }, error) {
    commit(types.SET_ERROR, error)
  },

  unsetError({ commit }) {
    commit(types.UNSET_ERROR)
  }
}

const mutations = {
  [types.SET_ERROR](state, error) {
    state.error = error
  },

  [types.UNSET_ERROR](state) {
    state.error = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
