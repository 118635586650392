<template>
  <div class="calendar__year">
    <div class="profile-info-row__title profile-info-row__title__calendarYear"> {{year}}: </div>
    <div class="monthRow">
      <template v-for="(month, index) in monthsData" :key="index">
        <div
          :id="`month-${year}-${index}`"
          class="monthBlock"
          :class="[
            month.status,
            `tier${month.subscription}`,
            {'selected': selectedMonthIndex === index},
          ]"
          v-b-tooltip.hover
          :title="`${monthNames()[index]} ${year}`"
          @click="onMonthClick(month.id, index)"
        />
      </template>
    </div>
  </div>
</template>

<script>
import Payment from '@/models/Payment'
import { monthNames } from '@/helpers'

export default {
  name: 'PaymentCalendarYear',
  props: {
    active: {
      type: Boolean,
      required: true
    },
    year: {
      type: Number,
      required: true
    },
    selectedMonthIndex: {
      type: Number,
      default: null
    },
    payments: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      months: Array.from({length: 12}, () => {return {status: 'empty', id: ''}}),
      monthsData: []
    }
  },
  watch: {
    payments() {
      this.calculateMonths()
    }
  },
  created() {
    this.calculateMonths()
  },
  methods: {
    calculateMonths() {
      this.monthsData = this.months.map((m, index) => {
        const payment = this.payments.find(p => p.paidMonth === index && p.paidYear === this.year)
        m = (payment) ?
          {
            status: Payment.statuses[payment.status] ?? 'empty',
            id: payment.id ?? '',
            subscription: payment.subscriptionLevel ? payment.subscriptionLevel : 0,
          } :
          { status: 'empty', id: '', subscription: 0 }

        return m
      })
    },

    onMonthClick(id, monthIndex) {
      this.$emit('onMonthClick', {id: id, month: monthIndex, year: this.year})
    },

    monthNames
  }
}
</script>