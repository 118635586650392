<template>
  <div class="main-layout container">
    <div class="">
      <div class="row justify-content-center">
        <div class="login-content__header col-lg-8 col-11">
          <div class="header-title">Логін</div>
          <div class="header-subtitle">Заходь під своїм ніком та паролем із сервера!</div>
        </div>
        <div class="login-content__main col-xl-6 col-lg-8 col-10">
          <div class="login-subtitle">Логін</div>
          <div v-if="error" class="login-error">
            <span class="error-title">
              Такої зв'язки <span class="error-title_bold">гравець-пароль</span> немає у нашій базі даних.<br/>
            </span>
            <span class="error-text">
              Ти точно входиш під своїм акаунтом з сервера?
            </span>
          </div>
          <CustomInput
            :id="`question-0`"
            :is-optional="nicknameQuestion.optional"
            :subtitle="nicknameQuestion.description"
            :answer="nicknameQuestion.answer"
            :disabled="$wait.is(waitName)"
            :placeholder="nicknameQuestion.placeholder"
            @onUpdate="updateQuestion(nicknameQuestion, $event)"
            :class="[{'opacity-50' : $wait.is(waitName)}]"
          >
            {{ nicknameQuestion.text }}
          </CustomInput>
          <CustomInput
            :id="`question-1`"
            :type="passwordInputType"
            :is-optional="passwordQuestion.optional"
            :subtitle="passwordQuestion.description"
            :answer="passwordQuestion.answer"
            :disabled="$wait.is(waitName)"
            custom-class="custom-input_password"
            :placeholder="passwordQuestion.placeholder"
            :image="passwordInputImage"
            @onUpdate="updateQuestion(passwordQuestion, $event)"
            @onSubmit="loginAction"
            @iconClicked="passowordButtonClicked"
            :class="[{'opacity-50' : $wait.is(waitName)}]"
          >
            {{ passwordQuestion.text }}
          </CustomInput>
          <div class="login-button-wrapper">
            <CustomButton 
              isClear
              value="Увійти"
              :disabled="!nicknameQuestion.hasAnswer() || !passwordQuestion.hasAnswer()"
              :wait-name="waitName"
              @clicked="loginAction"
              :class="[{'opacity-50' : $wait.is(waitName)}]"
            />
          </div>
        </div>
      </div>
      <div class="row justify-content-center omhms-content-block">
        <div class="login-content__header col-lg-8 col-11">
          <p class="omhms-text omhms-text-h3-regular">Немає акаунту?</p>
          <p class="omhms-text omhms-text-regular">
            Брух... Заходь на сервер: <span class="omhms-text omhms-text-console" @click="copyip" id="ipaddress">play.omhms.com</span><br>
            реєструйся там в грі <span class="omhms-text-color-60">( команда <span class="omhms-text omhms-text-console">/register</span>)</span>,<br>
            і логінся в профіль під своїм акаунтом з сервера!
          </p>
        </div>        
      </div>
      <div class="spacer-4"></div>
    </div>
  </div>


  <div id="alertCopied" class="omhms-alert alert" role="alert">
    IP сервера скопійовано в буфер обміну!
  </div>

</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import { sha256 } from "js-sha256"

import CustomButton from "@/components/CustomButton"
import CustomInput from "@/components/CustomInput"

import TextQuestion from "@/models/questions/TextQuestion"

export default {
  name: 'Login',
  components: {
    CustomInput,
    CustomButton
  },
  data() {
    return {
      title: "Особистий кабінет",
      nicknameQuestion: TextQuestion,
      passwordQuestion: TextQuestion,
      waitName: 'auth.login',
      isPasswordVisible: false
    }
  },
  computed: {
    ...mapGetters({
      check: "auth/check",
      error: "auth/error"
    }),

    passwordInputImage() {
      return this.isPasswordVisible ? "visibility_off" : "visibility"
    },

    passwordInputType() {
      return this.isPasswordVisible ? "text" : "password"
    }
  },
  created() {
    this.nicknameQuestion = new TextQuestion({
      text: "Нікнейм на ігровому сервері",
      placeholder: "Твій ігровий username",
      answer: ""
    })
    this.passwordQuestion = new TextQuestion({
      text: "Пароль на ігровому сервері",
      placeholder: "Пароль",
      answer: ""
    })
  },
  methods: {
    ...mapActions({
      login: "auth/login",
      unsetError: "auth/unsetError",
    }),

    async loginAction() {
      this.$wait.start(this.waitName)
      await this.login({
        userName: this.nicknameQuestion.answer, 
        hash: sha256(this.passwordQuestion.answer)
      })
      this.$wait.end(this.waitName)
      if (!this.error) {
        this.$router.push(
          (this.$route.query && this.$route.query.redirect)
            ? this.$route.query.redirect
            : '/profile'
          )
      }
    },

    updateQuestion(question, value) {
      this.unsetError()
      question.update(value)
    },

    passowordButtonClicked() {
      this.isPasswordVisible = !this.isPasswordVisible
    }, 


    async copyip() {

      var ip = document.getElementById("ipaddress").innerHTML;

      try {
        await navigator.clipboard.writeText(ip);
        console.log('IP скопійовано ;)');
      } catch($e) {
        console.log('Якась халепа. IP не скопійовано, доведеться писати руцями самому :С');
        console.log($e);
      }

      const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

      (async () => {
        document.getElementById("alertCopied").style.opacity = 1;
        await delay(2000);
        document.getElementById("alertCopied").style.opacity = 0;
      })();

    }
  }
}
</script>