<template>
  <div
    class="main-menu-mobile"
    :class="{'main-menu-mobile_opened': shown}"
  >
    <div v-show="shown" class="main-menu-mobile__content" >
      <hr class="main-menu-mobile__hr main-hr">
      <template v-for="(link, index) in links" :key="index">
        <template v-if="link.dropdown">
          <div
            class="main-menu-mobile__item main-menu-mobile__item-with-icon"
            :class="[{'main-menu-mobile__active-item' : openedMenuItem === index}]"
            @click="onMenuItemClick(index)"
          >
            <span>{{ link.title }}</span>
            <SvgIcon icon="chevron_down_white"/>
          </div>
          <hr class="main-menu-mobile__hr">
          <div :class="{collapse : openedMenuItem !== index}">
            <template v-for="(linkItem, itemIndex) in link.dropdown" :key="itemIndex">
              <router-link
                v-if="linkItem.to"
                :to="{ name: linkItem.to }"
                class="main-menu-mobile__item main-menu-mobile__subitem"
              >
                <div @click="$emit('item-click')">
                  <span> {{  linkItem.title }} </span>
                </div>
              </router-link>
              <a
                v-else-if="linkItem.href"
                :href="linkItem.href"
                target="_blank"
                class="main-menu-mobile__item main-menu-mobile__subitem"
              >
                <span> {{ linkItem.title }} </span>
              </a>
              <hr v-if="linkItem.title !== 'PHP Rcon'" class="main-menu-mobile__hr subitem-hr">
            </template>
          </div>
        </template>
        <template v-else>
          <router-link
            v-if="link.to"
            :to="{ name: link.to }"
            class="main-menu-mobile__item"
          >
            <div @click="$emit('item-click')">
              <span> {{  link.title }} </span>
            </div>
          </router-link>
          <a
            v-else-if="link.href"
            :href="link.href"
            target="_blank"
            class="main-menu-mobile__item"
          >
            <span> {{ link.title }} </span>
          </a>
          <hr class="main-menu-mobile__hr">
        </template>
      </template>
      <div class="main-menu-mobile__dark-content">
        <router-link
          :to="{ name: 'profile' }"
          class="main-menu-mobile__item"
        >
          <div @click="$emit('item-click')">
            <span> {{ profileLinkMenuTitle }} </span>
          </div>
        </router-link>
        <template v-if="check">
          <hr class="main-menu-mobile__hr">
          <div class="main-menu-mobile__item logout-button" @click="logoutAction">
            <span>Вийти</span>
            <SvgIcon icon="logout_white"/>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

import SvgIcon from "@/components/SvgIcon"


export default {
  name: 'MainMenuDropdown',
  components: {
    SvgIcon
  },
  props: {
    links: {
      type: Array,
      default: () => []
    },
    shown: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      openedMenuItem: null
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      check: "auth/check"
    }),

    profileLinkMenuTitle() {
      return `Особистий кабінет${ (this.user && this.user.realName) ? ` (${this.user.realName})` : "" }`
    },
  },
  methods: {
    ...mapActions({
      logout: "auth/logout"
    }),

    onMenuItemClick(index) {
      this.openedMenuItem = (this.openedMenuItem === index) ? -1 : index
    },

    logoutAction() {
      this.$emit('item-click')
      this.logout()
      if (this.$route.meta && this.$route.meta.middleware === 'auth') {
        this.$router.push({ name: 'login', query: { redirect: this.$route.path } })
      }
    }
  }
}
</script>