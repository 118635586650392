<template>
  <div class="main-layout">
    <PageHeader :title="title"/>

    <div class="container omhms-content-container">
     <div class="row omhms-content-block justify-content-center">
      <div class="col-lg-8 col-md-10 col-12">


        <div class="col-2 omhms-content-block-decorline"></div>
        <span class="omhms-text omhms-text-h2-regular">Кілька основних моментів:</span>
        <div class="spacer-24"></div>

        <p class="omhms-text omhms-text-regular">За порушення правил неможливо встановити фіксовану кару. Кожен випадок є унікальним. Виганяти з сервера гравців можливе лише у зовсім запущених випадках.</p>

        <p class="omhms-text omhms-text-regular">Усі конфліктні ситуації здебільшого можуть вирішитися самими гравцями. Будьте доброзичливими один до одного. Ми – закрита спільнота, яка прагне комфортної гри для всіх, і конфліктів варто уникати.</p>
        <p class="omhms-text omhms-text-regular">Наш сервер – доброзичлива спільнота, і за порушення ідеології мирної спільноти вживатимуться відповідні заходи.</p>

        <div class="spacer-32"></div>
        <div class="col-lg-6 col-md-10 col-12 omhms-content-block-separatorline"></div>
        <div class="spacer-32"></div>

        <ol class="omhms-list">
          <li class="listElement_title">Загальні правила OMHMS
            <div class="spacer-8"></div>
            <ol class="omhms-list">
              <li>Цей документ (веб-сторінка) не є основним регламентом проекту OMHMS.</li>
              <li>Адміністрація та саппорт мають право відходити від правил якщо вважатимуть за потрібне, і якщо це не вплине на чесність ігрового процесу (якщо це внутрішньо-ігрові дії) або якщо це не зачепить права іншого гравця/члена спільноти.</li>
              <li>Адміністрація може частково відшкодовувати внутрішньо-ігрові збитки, завдані гравцям гриферами або лагами сервера, за наявності доказів про втрату речей.</li>
              <li>Гравці, реєструючись в проекті, автоматично погоджуються із вказаними на цій сторінці правилами, та беруть на себе добровільні зобов'язання беззаперечно дотримуватися наведених правил.</li>
              <li>Незнання правил не звільняє від відповідальності.</li>
              <li>Оплата передплати здійснюється будь-яким зручним способом узгодженим з адміністрацією.</li>
              <li>Повернення коштів можливе лише за наступні місяці. Оплата за поточний місяць не повертається.</li>
              <li>Заборонено продаж та купівлю товарів, послуг за реальні гроші.</li>
            </ol>
          </li>
          <div class="spacer-8"></div>
          <div class="spacer-32"></div>
          <li class="listElement_title">Правила поведінки у спільноті
            <div class="spacer-8"></div>
            <ol class="omhms-list">
              <li><strong>Ваші права та свободи закінчуються там, де починаються права та свободи іншого гравця.</strong> Поважайте себе – поважайте інших. Не заважайте грати іншим гравцям.</li>
              <li>Заборонено ксенофобію та расизм у будь-яких проявах, а також ворожі висловлювання проти громадян України, США та країн ЄС.</li>
              <li>Заборонено образу гравців та інших людей.</li>
              <li>Заборонено токсичну поведінку.</li>
              <li>Заборонено спам, флуд, а також не погоджена з адміністрацією реклама сторонніх ресурсів, не пов'язаних з проектом.</li>
            </ol>
          </li>
          <div class="spacer-32"></div>
          <li class="listElement_title">Правила гри на сервері
            <div class="spacer-8"></div>
            <ol class="omhms-list">
              <li>Заборонена нечесна гра.</li>
              <li>Гриферство та читерство у будь-якому вигляді заборонені на сервері.</li>
              <li>Заборонено використання читів, читерських ресурспаків, скриптів, обходів, а також спроби злому гравців чи адміністрації.</li>
              <p class="omhms-text omhms-text-caption">Список заборонених модифікацій і ресурспаків не оприлюднюється, щоб уникнути спокуси “все-таки спробувати”.</p>
              <li>Заборонено використання функцій/можливостей самої гри з метою отримання будь-якої істотної переваги (наприклад, експлойти, баги, дюп).</li>
              <li>Під заборону не потрапляють моди та ресурспаки, що покращують ігровий процес візуально (optifine, optifabric, та їх аналоги), моди що спрощують будівництво (schematica), міні-карти та моди, що спрощують роботу з крафтом та інвентарем тощо.</li>
              <p class="omhms-text omhms-text-caption">Рекомендована збірка мод та ресурспаків від Одмєна буде внизу сторінки.</p>
              <li>Категорично заборонено суперечки на політичну тематику.</li>
              <p class="omhms-text omhms-text-caption">На сервері присутні гравці з різних країн, тому на політичні теми накладено довічне вето. На сервері заборонено відстоювати, яро висловлювати і пропагувати будь-яку з політичних ідей. Проте слід зауважити ряд фактів: росія&nbsp;–&nbsp;агрессор; росіяни&nbsp;–&nbsp;теж люди; москаляку&nbsp;–&nbsp;на гіляку, путін&nbsp;–&nbsp;хуйло, азов&nbsp;–&nbsp;сталь, Слава&nbsp;Україні.</p>
              <li>Заборонено розпалювання міжрасової та міжнаціональної ворожнечі та ненависті, а також створення та використання фашистської, нацистської на рашистської символіки, використання фашистських, нацистських та рашистських вітань та гасел у чаті гри, заборонено дискримінацію за статевими, релігійними, сексуальними та фізіологічними ознаками.</li>
              <li>Заборонено постійне використання ненормативної лексики, лайки, брехні, образ, погроз, провокацій, як у чатах та Discord, так і на сервері в ігровому світі. Мат, нажаль, э частиною нашого життя і повністю викреслити його не вдасться. Але намагаймося говорити чистою мовою.</li>
              <li>Заборонено рекламувати інші проекти, ігри або сторонні ресурси (крім #offtop каналу і #NSFW категорії в Discord).</li>
              <p class="omhms-text omhms-text-caption">Не рекламувати ≠ не обговорювати (#тримаювкурсі).</p>
              <li>За будь-яку неадекватну поведінку на думку адміністрації можуть робитися попередження. У разі накопичення цих попереджень гравець може отримати більше покарання і навіть бан на проекті.</li>
            </ol>
          </li>
          <!-- <div class="spacer-32"></div>
          <li class="listElement_title">Правила та інформація про ігрові світи
            <div class="spacer-8"></div>
            <ol class="omhms-list">
              <li>Детально описано на сторінках <a class="omhms-text-link" href="./about" target="_blank">Про сервер</a> в підрозділі <a class="omhms-text-link" href="./about#Regions" target="_blank">Регіони ігрового сервера</a>.</li>
            </ol>
          </li> -->
          <div class="spacer-32"></div>
          <li class="listElement_title">Будівництво
            <div class="spacer-8"></div>
            <ol class="omhms-list">
              <li>Гравці мають право будувати все, що їм заманеться, проте ці будівлі не повинні суперечити правилам проекту.</li>
              <li>Гравці мають право займати будь-яку територію за винятком: території Основного регіону, території іншого гравця без згоди гравця-господаря, громадської території, а також території, призначеної для серверних споруд (така територія буде явно позначена).</li>
              <p class="omhms-text omhms-text-caption">Щоб зайняти якусь територію її потрібно чітко позначити. Таблички, багаття, стовпи - що завгодно, головне, щоб іншим гравцям було зрозуміло, що це вже зайнята місцевість.</p>
              <li>Територія, зайнята лісами, вважається громадським ресурсом, у зв'язку із чим заборонена безконтрольна вирубка дерев. Якщо територія не під забудову, ліс бажано посадити заново з урахуванням особливостей зростання дерев і біому.</li>
              <li>Заборонено ламати/пошкоджувати чужі споруди будь-яким способом. Також заборонено робити "підставні" конструкції, при активації яких (навіть не вами) буде пошкоджено чиюсь споруду.</li>
              <li>Забороняється будувати всіма нелюбимі одноблокові стовпи, коробки, залишати літаючі блоки, особливо недорубані крони дерев.</li>
              <li>Забороняється цілеспрямовано будувати будівлі, здатні сильно навантажити сервер (на це потрібно тестити вже під час гри).</li>
              <li>Якщо зайнята гравцем територія значних розмірів, але на ній нічого особливо не відбувається, а господар території кілька тижнів (без попередження) не з'являвся на проекті, його територія може бути визнана як вільна/занедбана. Забудова такої території погоджується з адміністрацією окремо.</li>
              <p class="omhms-text omhms-text-caption">Звісно ж, перед оголошенням такого рішення з гравцем намагатимуться зв'язатися та з'ясувати причини простою.</p>
            </ol>
          </li>
          <div class="spacer-32"></div>
          <li class="listElement_title">PVP
            <div class="spacer-8"></div>
            <p class="omhms-text omhms-text-regular">Обмеження щодо PvP базуються на правилі 2.1. – не заважати іншим гравцям грати.</p>
            <ol class="omhms-list">
              <li>Заборонено вбивати інших гравців, якщо це не було зроблено з метою самозахисту. Кожен випадок розглядається окремо.</li>
              <li>Дозволено вбивати небажаного гостя на території свого поселення (як от українські війскові вбивають непрошених москалів на своїй території), якщо межі поселення були чітко позначені і було як мінімум 3 прохання відійти, і якщо ці прохання були проігноровано, або "гість" відмовився залишати територію.</li>
              <p class="omhms-text omhms-text-caption">За відсутності доказів або вагомих аргументів до гравця будуть застосовуватися заходи залежно від ситуації.</p>
              <li>Дозволено вбивство кривдника з метою самооборони, за умови, що гравець зможе згодом довести свою правоту. В противному випадку – будуть вживатись відповідні міри.</li>
              <li>Дозволено вбивство грифера.</li>
              <p class="omhms-text omhms-text-caption">Якщо ви самі стали свідком або маєте вагомі аргументи на свій захист та докази причетності гравця до гриферства.</p>
              <li>Дозволено вбивство за згодою на зазначеній території (приклад – PvP арени, івенти).</li>
            </ol>
          </li>
        </ol>

        <div class="spacer-32"></div>
        <div class="col-lg-6 col-md-10 col-12 omhms-content-block-separatorline"></div>
        <div class="spacer-32"></div>

        <div class="spacer-32"></div>
        <p class="omhms-text omhms-text-h2-regular">Збірка від Одмєна:</p>
        <p class="omhms-text omhms-text-regular">Для тих, хто дочитав правила докінця, або хочаб доскролив сторінку бонус: збірка модів і доповнень від Одмєна, яку ви можете бачити на відосах, стрімах, скріншотах.</p>
        <p class="omhms-text omhms-text-caption">Також, дозволені аналоги вказаних модифікацій за наявності таких, моди/бібліотеки, необхідні для роботи зазначених, або будь-які інші косметичні моди для Miencraft.</p>
        <div class="row">
          <p><br></p>
          <p class="col-12 omhms-text omhms-text-regular"><span class="omhms-text-bold">Fabric</span>, та інші моди які зроблять гру на вашому калькуляторі трохи стібільніше та комфортніше:</p>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://curseforge.com/minecraft/mc-mods/fabric-api">Fabric API</a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/lithium">Lithium</a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/phosphor">Phosphor</a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/sodium">Sodium</a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/sodium-extra">Sodium Extra</a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/reeses-sodium-options">Reese's Sodium Options</a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/smooth-boot">Smooth Boot</a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/modmenu">Mod Menu</a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/tooltipfix">ToolTipFix</a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/irisshaders">Iris Shaders<span class="omhms-text-caption">(для роботи шейдерів)</span></a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/mixintrace">MixinTrace</a>
        </div>
        <div class="row">
          <p><br></p>
          <p class="col-12 omhms-text omhms-text-regular">Візуал та #покращення – <span class="omhms-text-bold">моди:</span></p>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/texture-packs/default-dark-mode">Default Dark Mode<span class="omhms-text-caption">Темна тема для GUI</span></a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://beta.curseforge.com/minecraft/mc-mods/visuality">Visuality</a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/fabrishot">Fabrishot</a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/better-third-person">Better Third Person</a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/cameraoverhaul">CameraOverhaul</a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/not-enough-animations">Not Enough Animations</a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/eating-animation-fabric">Eating Animation</a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/falling-leaves-fabric">Falling Leaves</a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/illuminations">Illuminations</a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/visuality">Visuality</a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/litematica">Litematica</a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/malilib">MaLiLib<span class="omhms-text-caption">для Litematica</span></a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/appleskin">AppleSkin<span class="omhms-text-caption">GUI для їжі</span></a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/armor-chroma-for-fabric">Armor Chroma<span class="omhms-text-caption">GUI для броні</span></a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://chocolateminecraft.com/minimap2.php">Xaero's Minimap</a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://chocolateminecraft.com/worldmap.php">Xaero's World Map</a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/advancements-enlarger/files/4104551">Advancements Enlarger</a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/cit-resewn">CIT Resewn<span class="omhms-text-caption">Для унік. текстур</span></a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/emotecraft">Emotecraft</a>
          <p><br></p>
          <p class="col-12 omhms-text omhms-text-regular">Візуал та #покращення – <span class="omhms-text-bold">ресурси:</span></p>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/customization/complementary-shaders"><span class="omhms-text-caption">Шейдери</span>Complementary Shaders</a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://drive.google.com/file/d/1DDEAwdVK90JUp2pZjSF4xtPYYWVXwOl4/view?usp=share_link"><span class="omhms-text-caption">Налаштування Complementary Shaders як в одмєна</span></a>
          <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/customization/complementary-reimagined"><span class="omhms-text-caption">Шейдери</span>Complementary Reimagined</a>
        </div>
        <div class="row">
          <p><br></p>
          <p class="col-12 omhms-text omhms-text-bold">Звук та "занурення":</p>
          <a class="col-6 col-sm-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/simple-voice-chat">Simple Voice Chat</a>
          <a class="col-6 col-sm-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/dynamic-sound-filters">Dynamic Sound Filters</a>
          <a class="col-6 col-sm-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/presence-footsteps">Presence Footsteps</a>
          <a class="col-6 col-sm-3 omhms-text-link usefull-links" target="_blank" href="https://www.curseforge.com/minecraft/mc-mods/extrasounds">ExtraSounds</a>
        </div>
        <div class="row">
          <p><br></p>
            <p class="col-12 omhms-text omhms-text-bold">Сам майнкрафт, лол:</p>
            <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://www.minecraft.net/ru-ru/download">Minecraft.net<span class="omhms-text-caption">офіційний лаунчер</span></a>
            <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://download.curseforge.com/">CurseForge<span class="omhms-text-caption">одмєн юзає цей</span></a>
            <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://multimc.org/#Download">MultiMC<span class="omhms-text-caption">залупа а не лаунчер</span></a>
            <a class="col-6 col-sm-4 col-md-3 omhms-text-link usefull-links" target="_blank" href="https://tlaun.ch/">TL<span class="omhms-text-caption">не-офф ланчер</span></a>
        </div>
        </div>
      </div>
   </div>
  </div>

</template>
<script>
  import PageHeader from "@/components/PageHeader"

  export default {
    name: 'Rules',
    components: {
      PageHeader
    },
    props: {
    },
    data() {
      return {
        title: "Правила"

      }
    }
  }
</script>

