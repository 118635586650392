import axios from "axios"

import { apiUrl } from '@/config'
import * as types from '../mutation_types'
import Notification from '@/models/Notification'

const state = {
  notifications: [],
  error: null
}

const getters = {
  notifications(state) { return state.notifications },
  error(state) { return state.error }
}

const actions = {
  async get({ commit }) {
    try {
      const { data } = await axios.get(apiUrl(`notifications`))
      commit(types.SET_NOTIFICATIONS, data.data.notifications.map(n => new Notification(n)))
    } catch (e) {
      commit(types.SET_ERROR, e)
    }
  },

  clear({ commit }) {
    commit(types.SET_NOTIFICATIONS, [])
  },
}

const mutations = {
  [types.SET_NOTIFICATIONS](state, notifications) {
    state.notifications = notifications
  },

  [types.SET_ERROR](state, error) {
    state.error = error
  },

  [types.UNSET_ERROR](state) {
    state.error = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}